import React, { useState } from "react";
import Index from "../../Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { ResetPasswordAPI, ResetPasswordResendOTPAPI } from "../../../redux/features/slices/Admin/AdminService";
import { MuiOtpInput } from "mui-one-time-password-input";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CircularProgress from '@mui/material/CircularProgress';


import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function ForgotPassword() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  let { userId } = useParams();
  console.log(userId,"admin ID");
  const [resendOTPInProgress, setResendOTPInProgress] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading,setIsLoading]=useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  let initialValues = {
    otp: "",
    password: "",
    confirmPassword: "",
  };

  // Validation schema
  const digitsOnly = (value: string) =>
    /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0;
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("OTP is required")
      .test("inputEntry", "Please enter numberic OTP", digitsOnly)
      .min(4, "Please enter 4 digits OTP")
      .max(4, "Please enter 4 digits OTP"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with password"
      ),
  });

  const resendOTP = async () => {
    setResendOTPInProgress(true);
    const data = {
      id: userId,
    };
    dispatch(ResetPasswordResendOTPAPI(data)).then((response) => {
      if (response?.payload?.data?._id) {
        toast.success("OTP resent successully.");
        setResendOTPInProgress(false);
      } else {
        setResendOTPInProgress(false);
      }
    });
  };

  const handleFormSubmit = async (values) => {
    console.log(values, "form submit otp");
    const data = {
      id: userId,
      eVerifyId: values?.otp,
      password: values?.password,
    };
    setIsLoading(true)
    dispatch(ResetPasswordAPI(data)).then((response) => {
      if(response.payload.status == 200){
        if (response?.payload?.data?._id) {
          setIsLoading(false)
          toast.success("Password reset successully.");
          navigate("/admin/login");
        } else {
        }
      }
    
    }).catch((error)=>{
      console.log(error,110)
      setIsLoading(false)
    })
  };

  return (
    <>
      <Index.Box className="page-bg">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="admin-login-inner">
              <Index.Box className="center-logo-login">
                <img
                  src={Index.Svg.logo}
                  alt="logo-admin"
                  className="logo-admin"
                ></img>
              </Index.Box>

              <Formik
                enableReinitialize
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {console.log(errors, "Form error")}
                    <Index.Box className="card-login-main">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Reset Password
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText
                          className="form-lable"
                          style={{ marginTop: 36 }}
                        >
                          Enter OTP
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.Box className="flex-top-input">
                            <Index.Box className="otp-input-mui">
                              <MuiOtpInput
                                className="otp-inner-input-list"
                                length={4}
                                value={values?.otp}
                                onChange={(value) =>
                                  setFieldValue("otp", value)
                                }
                                name="otp"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        {errors.otp && (
                          <Index.FormHelperText error>
                            {errors.otp}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>

                      <Index.Box className="input-box">
                        <Index.FormHelperText
                          className="form-lable"
                          style={{ marginTop: 15 }}
                        >
                          New Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.Box className="input-details-main">
                            <Index.Box className="form-group pass_group_main">
                              <OutlinedInput
                                className="form-control custom_form_control"
                                id="outlined-adornment-password"
                                name="password"
                                placeholder="Password"
                                onBlur={handleBlur}
                                value={values.password}
                                onChange={handleChange}
                                helperText={touched.password && errors.password}
                                error={Boolean(
                                  errors.password && touched.password
                                )}
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                  <InputAdornment
                                    position="end"
                                    className="pass_position"
                                  >
                                    <IconButton
                                      className="icon_end_icon"
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Password"
                              />
                              {touched.password && errors.password && (
                                <Index.FormHelperText error>
                                  {errors.password}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        {/* <ToastContainer /> */}
                      </Index.Box>

                      <Index.Box className="input-box">
                        <Index.FormHelperText
                          className="form-lable"
                          style={{ marginTop: 15 }}
                        >
                          Confirm Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.Box className="input-details-main">
                            <Index.Box className="form-group pass_group_main">
                              <OutlinedInput
                                className="form-control custom_form_control"
                                id="outlined-adornment-password"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                onChange={handleChange}
                                helperText={
                                  touched.confirmPassword &&
                                  errors.confirmPassword
                                }
                                error={Boolean(
                                  errors.confirmPassword &&
                                    touched.confirmPassword
                                )}
                                type={showConfirmPassword ? "text" : "password"}
                                endAdornment={
                                  <InputAdornment
                                    position="end"
                                    className="pass_position"
                                  >
                                    <IconButton
                                      className="icon_end_icon"
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Confirm Password"
                              />
                              {touched.confirmPassword &&
                                errors.confirmPassword && (
                                  <Index.FormHelperText error>
                                    {errors.confirmPassword}
                                  </Index.FormHelperText>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        {/* <ToastContainer /> */}
                      </Index.Box>

                      <Index.Box className="btn-main login-btn-main">
                      {isLoading ? <><Index.Box className="comman-loader-button"><CircularProgress /></Index.Box></>:
                        <Index.Button
                          className="btn login-btn"
                          type="submit"
                          disableRipple
                        >
                          Submit
                        </Index.Button>}
                      </Index.Box>
                      <Index.Box className="flex-reset-password comman-flex-justify">
                        <Index.Box className="check-remeber-main">
                          <Index.Box className="checkbox-main"></Index.Box>
                        </Index.Box>
                        <Index.Box className="create-account-text">
                          
                          {resendOTPInProgress? <CircularProgress />:<a className="resend-otp" onClick={() => resendOTP()}>Resend OTP</a>}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
