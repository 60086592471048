import authvideo from './video/auth-video.mp4';










const Video = {
      authvideo,
}

export default Video;