import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import Index from "../../../Index";

export default function FitrizerCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState();
  const token = useSelector((store) => store.admin.userToken);
  console.log(token, "cards page");

  const getSettingData = async () => {
    await DataService.get(API.User.GET_USER_PROFILE, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        console.log(response?.data, "cards page Data");
        setProfileData(response?.data?.data);
      })
      .catch((error) => {
        // setIsFetching(false);
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };
  useEffect(() => {
    getSettingData();
  }, []);

  const wallethistorybtn=()=>{
    navigate(`/user/wallet-history`)
  }

  return (
    <>
      <Index.Box className="card-dashbord-main">
        <Index.Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          className="card-dash-grid"
          sx={{ margin: 0, alignItems: "center" }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column card-dash-column"
          >
            <Index.Box className="card-dash-main">
              <Index.Box className="card-dash-body">
                <Index.Box className="flex-details-card">
                  <Index.Box className="logo-bg-card">
                    <img className="small-logo" src={Index.Png.smalllogo}></img>
                  </Index.Box>
                  <Index.Box className="details-card-main">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="card-dash-title"
                    >
                      Total Balance
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="card-dash-detail"
                    >
                      {profileData?.TotalTokens?profileData?.TotalTokens:0.00} FTZR
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="flex-price-lock">
                  <Index.Box className="locked-card-main">
                    {/* <Index.Typography component='p' variant='p' className="card-dash-title-locked">Total Balance</Index.Typography>
                                        <Index.Typography component='p' variant='p' className="card-dash-detail-locked">0.00</Index.Typography> */}
                  </Index.Box>
                  <Index.Box className="available-card-main">
                    {/* <Index.Typography component='p' variant='p' className="card-dash-title-available">Total Balance</Index.Typography>
                                        <Index.Typography component='p' variant='p' className="card-dash-detail-available">0.00</Index.Typography> */}
                  </Index.Box>
                  <Index.Box className="wallet-btn-main">
                    <Index.OutlineButton
                      btnLabel="Wallet"
                      className="outline-btn-list"
                      onClick={wallethistorybtn}
                      
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 4", }} className="grid-column card-dash-column">
                        <Index.Box className="card-dash-main">
                            <Index.Box className="card-dash-body">
                                <Index.Box className="flex-details-card">
                                    <Index.Box className="logo-bg-card">
                                        <img className='small-logo' src={Index.Png.smalllogo}></img>
                                    </Index.Box>
                                    <Index.Box className="details-card-main">
                                        <Index.Typography component='p' variant='p' className="card-dash-title">Total Balance</Index.Typography>
                                        <Index.Typography component='p' variant='p' className="card-dash-detail">2,015,000</Index.Typography>
                                    </Index.Box>
                                </Index.Box>

                                <Index.Box className="flex-price-lock">
                                    <Index.Box className="locked-card-main">
                                        <Index.Typography component='p' variant='p' className="card-dash-title-locked">Total Balance</Index.Typography>
                                        <Index.Typography component='p' variant='p' className="card-dash-detail-locked">2,015,000</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="available-card-main">
                                        <Index.Typography component='p' variant='p' className="card-dash-title-available">Total Balance</Index.Typography>
                                        <Index.Typography component='p' variant='p' className="card-dash-detail-available">2,015,000</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="wallet-btn-main">
                                        <Index.OutlineButton btnLabel="Wallet" className="outline-btn-list"/>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 4", }} className="grid-column card-dash-column">
                        <Index.Box className="card-dash-main">
                            <Index.Box className="card-dash-body">
                                <Index.Box className="flex-details-card">
                                    <Index.Box className="logo-bg-card">
                                        <img className='small-logo' src={Index.Png.smalllogo}></img>
                                    </Index.Box>
                                    <Index.Box className="details-card-main">
                                        <Index.Typography component='p' variant='p' className="card-dash-title">Total Balance</Index.Typography>
                                        <Index.Typography component='p' variant='p' className="card-dash-detail">2,015,000</Index.Typography>
                                    </Index.Box>
                                </Index.Box>

                                <Index.Box className="flex-price-lock">
                                    <Index.Box className="locked-card-main">
                                        <Index.Typography component='p' variant='p' className="card-dash-title-locked">Total Balance</Index.Typography>
                                        <Index.Typography component='p' variant='p' className="card-dash-detail-locked">2,015,000</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="available-card-main">
                                        <Index.Typography component='p' variant='p' className="card-dash-title-available">Total Balance</Index.Typography>
                                        <Index.Typography component='p' variant='p' className="card-dash-detail-available">2,015,000</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="wallet-btn-main">
                                        <Index.OutlineButton btnLabel="Wallet" className="outline-btn-list"/>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box> */}
        </Index.Box>
      </Index.Box>
    </>
  );
}
