import React, { useState } from "react";
import Index from "../../Index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { forgotPasswordAdmin } from "../../../redux/features/slices/Admin/AdminService";

import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading,setIsLoading]=useState(false)
  let initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is not valid")
      .matches(
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
        "Email is not valid"
      )
      .required("Email is required"),
  });

  // Register
  const handleFormSubmit = async (values) => {
    // console.log(values);
    setIsLoading(true)
    dispatch(forgotPasswordAdmin(values)).then((response) => {
      if(response.payload.status == 200){
        setIsLoading(false)
        if (response?.payload?.data?._id) {
          toast.success("Forgot password mail sent to your registered email");
          navigate(`/admin/reset-password/${response?.payload.data?._id}`);
        } else {
        }
      }
      else{
      }
      
    }).catch((error)=>{
      setIsLoading(false)

    })
  };

  return (
    <>
      <Index.Box className="page-bg">
        <Index.Box className="admin-login-box">
          <Index.Box className="admin-login-main">
            <Index.Box className="admin-login-inner">
              <Index.Box className="center-logo-login">
                <img
                  src={Index.Svg.logo}
                  alt="logo-admin"
                  className="logo-admin"
                ></img>
              </Index.Box>

              <Formik
                enableReinitialize
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="card-login-main">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Forgot Password
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText
                          className="form-lable"
                          style={{ marginTop: 36 }}
                        >
                          Enter your email
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            value={values?.email}
                            className="form-control"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            helperText={touched.email && errors.email}
                            error={Boolean(errors.email && touched.email)}
                          />
                        </Index.Box>
                        <ToastContainer />
                      </Index.Box>
                      <Index.Box className="btn-main login-btn-main">
                      {isLoading ? <><Index.Box className="comman-loader-button"><CircularProgress /></Index.Box></>:
                        <Index.Button
                          className="btn login-btn"
                          type="submit"
                          disableRipple
                        >
                          Submit
                        </Index.Button>}
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
