import React from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Index from "../../Index";

export default function UserSidebar() {
  const pathname = useLocation();

  const handleOnClick = () => {
    toast.success("Please complete profile");
  };
  return (
    <>
      <Index.Box className="sidebar-user-main">
        <Index.List className="list-user-sidebar">
          <Index.ListItem
            // className="listitem-user-sidebar"
            className={
              pathname?.pathname === "/user/dashboard"
                ? "listitem-user-sidebar active"
                : "listitem-user-sidebar"
            }
          >
            <Index.Link to="/user/dashboard" className="user-sidebar-link">
              User Dashboard
            </Index.Link>
          </Index.ListItem>

          <Index.ListItem
            //   className="listitem-user-sidebar active"
            className={
              pathname?.pathname === "/user/profile"
                ? "listitem-user-sidebar active"
                : "listitem-user-sidebar"
            }
          >
            <Index.Link to="/user/profile" className="user-sidebar-link">
              Account
            </Index.Link>
          </Index.ListItem>

          <Index.ListItem 
        //   className="listitem-user-sidebar active"
          className={
            pathname?.pathname === "/user/bmi-report"
              ? "listitem-user-sidebar active"
              : "listitem-user-sidebar"
          }>
            <Index.Link to="/user/bmi-report" className="user-sidebar-link">
              BMI Report
            </Index.Link>
          </Index.ListItem>
        </Index.List>
      </Index.Box>
    </>
  );
}
