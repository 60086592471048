import Routers from "./routes/Routes";


import "./assets/style/style.css";
import "./assets/style/responsive.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {
  return (
   
    <div className="App">
      <ToastContainer/>
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </div>
  );
}

export default App;
