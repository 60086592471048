import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Index from "../../Index";
import { userLogout } from "../../../redux/features/slices/Admin/AdminSlice";

export default function UserHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((store) => store.admin.userData);

  const userLogoutAction = () => {
    dispatch(userLogout());
    navigate("/user/login");
  };

  return (
    <>
      <Index.Box className="header-sec">
        <Index.Box className="container">
          <Index.Box className="row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 5",
                    sm: "span 2",
                    md: "span 1",
                    lg: "span 1",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="header-col">
                    <Index.Link className="link-user-logo">
                      {" "}
                      <img src={Index.Svg.logo} className="header-logo user-header-logo" />
                    </Index.Link>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 2",
                    sm: "span 6",
                    md: "span 9",
                    lg: "span 9",
                  }}
                  className="grid-column"
                ></Index.Box>
                
                <Index.Box
                  gridColumn={{
                    xs: "span 5",
                    sm: "span 4",
                    md: "span 2",
                    lg: "span 2",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="flex-btn user-btn-end">
                    <Index.Typography component='p' variant="p" className="welcome-person-name">Welcome {userData?.firstName}</Index.Typography>
                  {/* <Index.Typography
                  className="admin-page-title"
                  component="h5"
                  variant="h5"
                >
                  Welcome {userData?.firstName}
                </Index.Typography> */}
                    <Index.Box className="header-btn-main">
                      <Index.PrimaryButton
                        btnLabel="Logout"
                        className="comman-primary-btn"
                        onClick={() => userLogoutAction()}
                      ></Index.PrimaryButton>
                    </Index.Box>
                    <Index.Box className="burgur-icon-main">
                      <Index.OutlineButton
                    
                        imgSrc={Index.Png.menuburger}
                        className="outline-btn-list"
                      ></Index.OutlineButton>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
