import React, { useEffect, useState } from "react";
import Index from "../../Index";
import { useDispatch, useSelector } from "react-redux";
import {
  ForgotPasswordAPI,
} from "../../../redux/features/slices/User/UserService";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading,setIsLoading]=useState(false)
  let initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is not valid")
      .matches(
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
        "Email is not valid"
      )
      .required("Email is required"),
  });

  // Register
  const handleFormSubmit = async (values) => {
    setIsLoading(true)
    dispatch(ForgotPasswordAPI(values)).then((response) => {
      if(response.payload.status == 200){
        setIsLoading(false)
        if (response?.payload?.data?._id) {
          toast.success("Forgot password mail sent to your registered email");
          navigate(`/user/reset-password/${response?.payload.data?._id}`);
        } else {
          // toast.error("Something went wrong");
        }
      }
    }).catch((error)=>{
      console.log(error)
      setIsLoading(false)

    })
  };

  return (
    <>
      <Index.Box className="login-main-content">
        <Index.Box className="grid-row login-row">
          <Index.Box sx={{ width: 1 }} className="grid-main login-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              className="login-grid"
              sx={{ margin: 0, alignItems: "center" }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="signup-bg-content">
                  <Index.Box className="login-logo-details signup-logo-details">
                    <Index.Link to="/">
                      {" "}
                      <img
                        src={Index.Svg.logo}
                        className="logo-login"
                      ></img>{" "}
                    </Index.Link>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-form-details signup-form-details">
                  <Index.Box className="login-pd-main">
                    <Index.Box className="signup-max-details">
                      <Index.Box className="login-details-title">
                        <Index.Typography
                          className="login-title"
                          component="h5"
                          variant="h5"
                        >
                          Forgot Password
                        </Index.Typography>
                        <Index.Typography
                          className="login-desc"
                          component="p"
                          variant="p"
                        >
                          Enter registered email
                        </Index.Typography>
                      </Index.Box>
                      <Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Index.Box className="mr-hgt-scroll">
                              <Index.Box className="signup-scroll-form">
                                <Index.Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                                  className="login-grid-inner"
                                >
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column login-column mb-20px"
                                  >
                                    <Index.Box className="input-box custom-form-details login-input">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Email
                                        </Index.FormHelperText>
                                        <Index.TextField
                                          fullWidth
                                          id="fullWidth"
                                          className="form-control custom-control"
                                          name="email"
                                          onBlur={handleBlur}
                                          value={values.email}
                                          onChange={handleChange}
                                          helperText={
                                            touched.email && errors.email
                                          }
                                          error={Boolean(
                                            errors.email && touched.email
                                          )}
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              display="grid"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                              className="login-grid-inner"
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column"
                              >
                                <Index.Box className="signin-btn-main">
                                {isLoading ? <><Index.Box className="comman-loader-button"><CircularProgress /></Index.Box></>:
                                  <Index.PrimaryButton
                                    btnLabel="Submit"
                                    type="submit"
                                    className="comman-primary-btn"
                                  />}
                                </Index.Box>
                                {/* <Index.Box className="account-link-details">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="already-account-title"
                                  >
                                    Already have an account?
                                    <Index.Link
                                      to="/user/login"
                                      className="redirect-account-link"
                                    >
                                      Sign in
                                    </Index.Link>
                                  </Index.Typography>
                                </Index.Box> */}
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column"
                              >
                                     <Index.Box className="create-account-text back-login-forgot">
                                    <Index.Link
                                      className="signup-redirect"
                                      to="/user/login"
                                    >
                                      Back to login
                                    </Index.Link>
                                  </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </form>
                        )}
                      </Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
