import React from "react";
import Index from "../../../Index";

export default function Home() {
  return (
    <>
      <Index.Box className="header-main-component">
        <Index.Header />
      </Index.Box>

      <Index.Box className="home-main-component">
        <Index.Home />
      </Index.Box>

      <Index.Box className="joyful-main-component">
        <Index.JoyfulInvestment />
      </Index.Box>

      <Index.Box className="roadmap-main-component">
        <Index.Roadmap />
      </Index.Box>

      <Index.Box className="referral-main-component">
        <Index.Referral />
      </Index.Box>

      <Index.Box className="footer-main-component">
        <Index.Footer />
      </Index.Box>
    </>
  );
}
