import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LoginAdmin } from "./AdminService";
import { registerAdmin } from "./AdminService";
import { getUser } from "./AdminService";

export const AdminSlice = createSlice({
  name: "counter",
  initialState: {
    token: "",
    isAuthenticated: false,
    userToken: "",
    faType: "disabled",
    usersList: [],
    userData: {},
    adminData: {},
    roundData: [],
    isClaimAirDrop: false,
    upcomingCurrentRoundData:[],
    userBalanceData:[],
    countryList: [],
  },
  reducers: {
    getAdminData: (state, action) => {
      state.adminData = action.payload;
    },
    getUserData: (state, action) => {
      state.userData = action.payload;
    },
    getUserToken: (state, action) => {
      state.userToken = action.payload;
      state.isAuthenticated = true;
    },
    getAdminToken: (state, action) => {
      state.token = action.payload;
    },
    getAdminType: (state, action) => {
      state.adminType = action.payload;
    },
    adminLogout: (state, action) => {
      state.token = "";
      state.isAuthenticated = false;
      state.adminData = {};
    },
    userLogout: (state, action) => {
      state.userToken = "";
      state.isAuthenticated = false;
      state.userData = {};
    },
    enabledFa: (state, action) => {
      state.faType = "enabled";
    },
    disabledFa: (state, action) => {
      state.faType = "disabled";
    },
    getRoundData: (state, action) => {
      state.roundData = action.payload;
    },
    claimAirDrop: (state, action) => {
      state.isClaimAirDrop = true;
    },
    SimpleRegister: (state, action) => {
      state.isClaimAirDrop = false;
    },
    upcomingCurrentRoundData: (state, action) => {
      state.upcomingCurrentRoundData = action.payload;
    },
    userBalanceData: (state, action) => {
      state.userBalanceData = action.payload;
    },
    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LoginAdmin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      
    });
    builder.addCase(registerAdmin.fulfilled, (state, action) => {});
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.usersList.push(action.payload);
    });
    
  },
});

export const {
  getAdminData,
  getUserData,
  getAdminToken,
  getAdminType,
  enabledFa,
  disabledFa,
  userLogout,
  adminLogout,
  getUserToken,
  getRoundData,
  claimAirDrop,
  SimpleRegister,
  upcomingCurrentRoundData,
  userBalanceData,
  setCountryList
} = AdminSlice.actions;

export default AdminSlice.reducer;
