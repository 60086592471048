import React, { useRef, useState } from "react";
import Index from "../../Index";
import * as Yup from "yup";

import { ForgotPasswordAPI } from "../../../redux/features/slices/User/UserService";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';

import {
  ResetPasswordAPI,
  ResetPasswordResendOTPAPI,
} from "../../../redux/features/slices/User/UserService";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Formik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";


export default function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading,setIsLoading]=useState(false)
  let { userId } = useParams();

  const [resendOTPInProgress, setResendOTPInProgress] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  let initialValues = {
    otp: "",
    password: "",
    confirmPassword: "",
  };

  // Validation schema
  const digitsOnly = (value: string) =>
    /^\d*[\.{1}\d*]\d*$/.test(value) || value.length === 0;
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("OTP is required")
      .test("inputEntry", "Please enter numberic OTP", digitsOnly)
      .min(4, "Please enter 4 digits OTP")
      .max(4, "Please enter 4 digits OTP"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with password"
      ),
  });

  const resendOTP = async () => {
    setResendOTPInProgress(true);
    const data = {
      id: userId,
    };
    dispatch(ResetPasswordResendOTPAPI(data)).then((response) => {
      if (response?.payload?.status == 200) {
        toast.success("OTP resent successully.");
        setResendOTPInProgress(false);
      } else {
        toast.error("OTP not sent");
        setResendOTPInProgress(false);
      }
    });
  };

  const handleFormSubmit = async (values) => {
    console.log(values, "form submit otp");
    const data = {
      id: userId,
      eVerifyId: values?.otp,
      password: values?.password,
    };
    setIsLoading(true)
    dispatch(ResetPasswordAPI(data)).then((response) => {
      if(response.payload.status == 200){
        setIsLoading(false)
        if (response?.payload?.data?._id) {
          toast.success("Password reset successully.");
          navigate("/user/login");
        } else {
          // toast.error("Something went wrong");
        }
      }
    }).catch((error)=>{
      console.log(error,105)
      setIsLoading(false)
    })
  };

  return (
    <>
      <Index.Box className="login-main-content login-user-main">
        <Index.Box className="grid-row login-row">
          <Index.Box sx={{ width: 1 }} className="grid-main login-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              className="login-grid"
              sx={{ margin: 0, alignItems: "center" }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-bg-content">
                  <Index.Box className="login-logo-details">
                    <Index.Link to="/user/">
                      {" "}
                      <img src={Index.Svg.logo} className="logo-login"></img>
                    </Index.Link>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-form-details">
                  <Index.Box className="login-pd-main">
                    <Index.Box className="login-max-content">
                      <Index.Box className="login-details-title">
                        <Index.Typography
                          className="login-title"
                          component="h5"
                          variant="h5"
                        >
                          Reset Password
                        </Index.Typography>
                        <Index.Typography
                          className="login-desc"
                          component="p"
                          variant="p"
                        >
                          Enter OTP and new password
                        </Index.Typography>
                      </Index.Box>
                      <Formik
                        // enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          setFieldValue,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Index.Box
                              display="grid"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                              className="login-grid-inner"
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column mb-20px"
                              >
                                <Index.Box className="input-box custom-form-details login-input">
                                  <Index.Box className="form-group custom-group">
                                    <Index.FormHelperText className="form-label">
                                      Enter Otp
                                    </Index.FormHelperText>
                                    <Index.Box className="flex-top-input">
                                      <Index.Box className="otp-input-mui">
                                        <MuiOtpInput
                                          className="otp-inner-input-list"
                                          length={4}
                                          value={values?.otp}
                                          onChange={(value) =>
                                            setFieldValue("otp", value)
                                          }
                                          name="otp"
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                {errors.otp && (
                                  <Index.FormHelperText error>
                                    {errors.otp}
                                  </Index.FormHelperText>
                                )}
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column mb-20px"
                              >
                                <Index.Box className="input-box custom-form-details login-input">
                                  <Index.Box className="form-group custom-group">
                                    <Index.FormHelperText
                                      className="form-label"
                                      fullWidth
                                      id="fullWidth"
                                    >
                                      New Password
                                    </Index.FormHelperText>
                                    <Index.Box className="input-details-main">
                                      <Index.Box className="form-group pass_group_main">
                                        <OutlinedInput
                                          className="form-control custom_form_control"
                                          id="outlined-adornment-password"
                                          name="password"
                                          placeholder="Password"
                                          onBlur={handleBlur}
                                          value={values.password}
                                          onChange={handleChange}
                                          helperText={
                                            touched.password && errors.password
                                          }
                                          error={Boolean(
                                            errors.password && touched.password
                                          )}
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          endAdornment={
                                            <InputAdornment
                                              position="end"
                                              className="pass_position"
                                            >
                                              <IconButton
                                                className="icon_end_icon"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {showPassword ? (
                                                  <VisibilityOff />
                                                ) : (
                                                  <Visibility />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          label="Password"
                                        />
                                        {touched.password &&
                                          errors.password && (
                                            <Index.FormHelperText error>
                                              {errors.password}
                                            </Index.FormHelperText>
                                          )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>

                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column mb-20px"
                              >
                                <Index.Box className="input-box custom-form-details login-input">
                                  <Index.Box className="form-group custom-group">
                                    <Index.FormHelperText
                                      className="form-label"
                                      fullWidth
                                      id="fullWidth"
                                    >
                                      Confirm Password
                                    </Index.FormHelperText>
                                    <Index.Box className="input-details-main">
                                      <Index.Box className="form-group pass_group_main">
                                        <OutlinedInput
                                          className="form-control custom_form_control"
                                          id="outlined-adornment-password"
                                          name="confirmPassword"
                                          placeholder="confirmPassword"
                                          onBlur={handleBlur}
                                          value={values.confirmPassword}
                                          onChange={handleChange}
                                          helperText={
                                            touched.confirmPassword &&
                                            errors.confirmPassword
                                          }
                                          error={Boolean(
                                            errors.confirmPassword &&
                                              touched.confirmPassword
                                          )}
                                          type={
                                            showConfirmPassword
                                              ? "text"
                                              : "password"
                                          }
                                          endAdornment={
                                            <InputAdornment
                                              position="end"
                                              className="pass_position"
                                            >
                                              <IconButton
                                                className="icon_end_icon"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowConfirmPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownConfirmPassword
                                                }
                                                edge="end"
                                              >
                                                {showConfirmPassword ? (
                                                  <VisibilityOff />
                                                ) : (
                                                  <Visibility />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          label="Password"
                                        />
                                        {touched.confirmPassword &&
                                          errors.confirmPassword && (
                                            <Index.FormHelperText error>
                                              {errors.confirmPassword}
                                            </Index.FormHelperText>
                                          )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>

                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column mb-20px"
                              >
                                <Index.Box className="signin-btn-main">
                                {isLoading ? <><Index.Box className="comman-loader-button"><CircularProgress /></Index.Box></>:
                                  <Index.PrimaryButton
                                    btnLabel="Submit"
                                    type="submit"
                                    onCli
                                    className="comman-primary-btn"
                                  />}
                                </Index.Box>
                                <Index.Box className="flex-reset-password comman-flex-justify">
                                  <Index.Box className="check-remeber-main">
                                    <Index.Box className="checkbox-main"></Index.Box>
                                  </Index.Box>
                                  <Index.Box className="create-account-text">
                                    {resendOTPInProgress ? (
                                      <CircularProgress />
                                    ) : (
                                      <a
                                        className="resend-otp"
                                        onClick={() => resendOTP()}
                                      >
                                        Resend OTP
                                      </a>
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </form>
                        )}
                      </Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
