import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ITEM_HEIGHT = 48;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ mr: 5, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ContactRequest = () => {
  const [open, setOpen] = React.useState(false);

  const handleDescriptionModelOpen = (params) => {
    setDescriptionData(params?.row?.message);
    setOpen(true);
  };
  const handleDescriptionModelClose = () => {
    setOpen(false);
  };
  const token = useSelector((store) => store.admin.token);
  const [userContactUsRequests, setUserContactUsRequests] = useState([]);
  const [descriptionData, setDescriptionData] = useState();
  const getUserContactRequests = async () => {
    try {
      const res = await DataService.get(API.Admin.GET_CONTACT_REQUEST, {
        headers: {
          auth: token,
        },
      });
      const rows = res?.data?.data.map((items) => {
        return {
          id: items?._id ? items?._id : null,
          name: items?.name ? items?.name : null,
          email: items?.email ? items?.email : null,
          message: items?.message ? items?.message : null,
          createdAt: moment(items?.createdAt ? items?.createdAt : null).format(
            "DD/MM/YYYY,   h:mm:ss a"
          ),
        };
      });
      setUserContactUsRequests(rows);
    } catch (error) {
      console.log(error,86);
    }
  };
  useEffect(() => {
    getUserContactRequests();
  }, []);
  const columns = [
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "message",
      headerName: "Description",
      renderCell: (params) => (
        <>
          <Index.Typography
            onClick={() => handleDescriptionModelOpen (params)}
            className="user-data-head contact-request-list "
          >
            {params?.row?.message?.length > 35 ? (
              <>{params.row.message.slice(0, 25)}...</>
            ) : (
              params.row.message
            )}
          </Index.Typography>
        </>
      ),
    },
    {
      field: "createdAt",
      headerName: "Create Date & Time",
    },
  ];

  return (
    <Index.Box className="dashboard-containt-main">
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Typography
            className="admin-page-title setting-title"
            component="h4"
            variant="h4"
          >
            Contact Request
          </Index.Typography>
          <Index.Box sx={{ width: "100%" }} className="add-user-height">
            <Index.DataGrid
              className="user-data-head  contact-request-list "
              rows={userContactUsRequests}
              columns={columns}
              getRowId={(userContactUsRequests) => userContactUsRequests?.id}
              pageSize={10}
              autoHeight={true}
            />
          </Index.Box>

          {/* Dialog */}
          <BootstrapDialog
            onClose={handleDescriptionModelClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleDescriptionModelClose}
            >
              Description
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Index.Typography gutterBottom>
                {descriptionData}
              </Index.Typography>
            </DialogContent>
          </BootstrapDialog>
          {/* Dialog */}
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default ContactRequest;
