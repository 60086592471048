import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Index from "../../../Index";

import {
  getUserProfile,
} from "../../../../redux/features/slices/User/UserService";

import { getUserData } from "../../../../redux/features/slices/Admin/AdminSlice";
import Loader from "../../../../component/common/Loader";

export default function UserDashboard() {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(true);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const userData = useSelector((store) => store.admin.userData);
  console.log(userData, "userData");

  const [modalOpen, setModalOpen] = useState(
    userData?.isProfileUpdated ? false : true
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (userData?.isProfileUpdated != 0) {
      handleModalClose();
    }
  }, [userData?.isProfileUpdated]);

  useEffect(() => {
    setLoading(true);
    dispatch(getUserProfile()).then((response) => {
      if (response?.payload?.data) {
        setProfile(response?.payload?.data?.isProfileUpdated);
        console.log(response?.payload?.data?.isProfileUpdated);
        // setProfileData(response?.payload?.data);
        //Update in redux store
        dispatch(getUserData(response?.payload?.data));

        setLoading(false);
      } else {
        // toast.error("Something went wrong");
      }
    });
  }, []);

  const handleUpdateProfile = () => {
    navigate("/user/profile");
  };

  if (loading) {
    return <Loader></Loader>;
  } else {
    return (
      <>
        <Index.Box className="userdashbord-main">
          <Index.UserHeader />
        </Index.Box>

        <Index.Box className="main-userdashboard-content">
          <Index.Box className="container">
            <Index.Box className="user-dashboard-row">
              <Index.Box className="userdash-col1">
                <Index.Box className="sidebar-main-user-component">
                  <Index.UserSidebar />
                </Index.Box>
              </Index.Box>

              <Index.Box className="userdash-col2">
                <Index.Box className="dash-card-components">
                  <Index.FitrizerCard />
                </Index.Box>
                <Index.Box className="refferal-program-component">
                  <Index.RefferalProgram />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={modalOpen}
            // onClose={props.handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete modal"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner"
            >
              <Index.Box className="modal-circle-main"></Index.Box>
              <Index.Typography
                className="delete-modal-title"
                component="h2"
                variant="h2"
              >
                Please complete your profile and get 5 FTZR tokens
              </Index.Typography>
              <Index.Box className="delete-modal-btn-flex">
                <Index.BlueBorderButton
                  type="submit"
                  className="blue-outline-btn modal-cancel-btn"
                  btnLabel="Later"
                  onClick={handleModalClose}
                />
                <Index.Box className="modal-btn-ok ">
                  <Index.PrimaryButton
                    className="comman-primary-btn "
                    onClick={handleUpdateProfile}
                    btnLabel="Now"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        </Index.Box>
      </>
    );
  }
}
