import React, { useState } from "react";
import Index from "../../../Index";
import moment from "moment";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const UserDetails = ({ userInfo }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // countryCode && Mobile
  const countryCode = userInfo?.country?.dial_code
    ? userInfo?.country?.dial_code
    : "0";
  const countryMobile = userInfo?.phoneNumber ? userInfo?.phoneNumber : "0";
  const countryCodeMobile = countryCode + "   " + countryMobile;
  // height value && unit
  const heightFt = userInfo?.height?.fit ? userInfo?.height?.fit : "";
  const heightInch = userInfo?.height?.inch ? userInfo?.height?.inch : "";
  const heightCm = userInfo?.height?.cm ? userInfo?.height?.cm : "";
  const heightMm = userInfo?.height?.mm ? userInfo?.height?.mm : "";
  const heightUnit = userInfo?.height?.unit ? userInfo?.height?.unit : "";

  const heightValueCm = heightCm + "." + heightMm + " " + heightUnit;
  const heightValueFt = heightFt + "." + heightInch + " " + heightUnit;

  // weight value && unit
  const weightKilo = userInfo?.weight?.kilo ? userInfo?.weight?.kilo : "";
  const weightGram = userInfo?.weight?.gram ? userInfo?.weight?.gram : "";
  const weightLbs = userInfo?.weight?.lbs ? userInfo?.weight?.lbs : "";
  const weightOz = userInfo?.weight?.oz ? userInfo?.weight?.oz : "";
  const weightUnit = userInfo?.weight?.unit ? userInfo?.weight?.unit : "";
  const weightValueLbs = weightLbs + "." + weightOz + " " + weightUnit;
  const weightValueKg = weightKilo + "." + weightGram + " " + weightUnit;

  // date of birth
  const dateOfBirth = moment(
    userInfo?.dateOfBirth ? userInfo?.dateOfBirth : "12"
  ).format("DD/MM/YYYY");
  // Kyc
  const kycType = userInfo?.kyc?.kycType ? userInfo?.kyc?.kycType : "-";
  const kycDocument = userInfo?.kyc?.kycDocument
    ? userInfo?.kyc?.kycDocument
    : "-";
  const kyc = kycType + " " + kycDocument;
  const img = "http://35.177.56.74:3024/api/images/${kycDocument}";
  return (
    <>
      <Index.Box className="user-head-title">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          User Details{" "}
          <span>({userInfo?.userName ? userInfo?.userName : "-"})</span>
        </Index.Typography>
      </Index.Box>

      {/*  user details page start */}

      <Index.Box className="user-details-main-content">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="  user-row user-row-details"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column user-grid-column"
            >
              <Index.Box className="card-main-details-user">
                <Index.Box className="title-user-details-content">
                  <Index.Typography component="p" variant="p">
                    Basic Details
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="card-inner-details">
                  <Index.Box
                    display="grid"
                    className="  user-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column user-grid-column"
                    >
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Full Name
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.firstName ? userInfo?.firstName : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          User Name
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.userName ? userInfo?.userName : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Email
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.email ? userInfo?.email : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Mobile Number
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {countryCodeMobile}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Date of Birth
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {dateOfBirth}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Weight
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {weightUnit == "kg" ? weightValueKg : weightValueLbs}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column user-grid-column"
                    >
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Gender
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.gender ? userInfo?.gender : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Country Name
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.country?.name
                            ? userInfo?.country?.name
                            : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Age
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.age ? userInfo?.age : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Height
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {heightUnit == "ft" ? heightValueFt : heightValueCm}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column user-grid-column"
            >
              <Index.Box className="card-main-details-user">
                <Index.Box className="title-user-details-content">
                  <Index.Typography component="p" variant="p">
                    Fitness Details
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="card-inner-details">
                  <Index.Box
                    display="grid"
                    className="  user-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column user-grid-column"
                    >
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Routine
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.routine ? userInfo?.routine : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Health Challanges
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.healthChallanges
                            ? userInfo?.healthChallanges?.join(",")
                            : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Food Preference
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.foodPreference
                            ? userInfo?.foodPreference
                            : "-"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column user-grid-column"
                    >
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Diet
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.diet ? userInfo?.diet : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Fitness Goal
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.goal ? userInfo?.goal?.join(",") : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Referral Code
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.referCode ? userInfo?.referCode : "-"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column user-grid-column"
            >
              <Index.Box className="card-main-details-user">
                <Index.Box className="title-user-details-content">
                  <Index.Typography component="p" variant="p">
                    Other Details
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="card-inner-details">
                  <Index.Box
                    display="grid"
                    className="  user-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column user-grid-column"
                    >
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          kyc
                        </Index.Typography>
                        {userInfo?.kyc?.kycType ? (
                          <Index.Typography
                            className="col2-user-main-content"
                            component="p"
                            variant="p"
                          >
                            {userInfo?.kyc?.kycType}
                            <Index.Button
                              className="userDetail-btn-lowercase"
                              onClick={handleClickOpen}
                            >
                              View
                            </Index.Button>
                          </Index.Typography>
                        ) : (
                          ""
                        )}

                        {/* Dialog */}
                        <BootstrapDialog
                          onClose={handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={open}
                        >
                          <BootstrapDialogTitle
                            id="customized-dialog-title"
                            onClose={handleClose}
                          >
                            Document
                          </BootstrapDialogTitle>
                          <DialogContent dividers>
                            <Index.Typography gutterBottom>
                              <img
                                style={{ width: "100%" }}
                                src={
                                  process.env.REACT_APP_ASSET_URL + kycDocument
                                }
                              />
                            </Index.Typography>
                          </DialogContent>
                        </BootstrapDialog>
                        {/* Dialog */}
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column user-grid-column"
                    >
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Unique Id No.
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.uniqueIdNo ? userInfo?.uniqueIdNo : "-"}
                        </Index.Typography>

                        {/* Dialog */}
                        <BootstrapDialog
                          onClose={handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={open}
                        >
                          <BootstrapDialogTitle
                            id="customized-dialog-title"
                            onClose={handleClose}
                          >
                            Document
                          </BootstrapDialogTitle>
                          <DialogContent dividers>
                            <Index.Typography gutterBottom>
                              <img
                                style={{ width: "100%" }}
                                src={
                                  process.env.REACT_APP_ASSET_URL + kycDocument
                                }
                              />
                            </Index.Typography>
                          </DialogContent>
                        </BootstrapDialog>
                        {/* Dialog */}
                      </Index.Box>
                    </Index.Box>

                    {/* <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column user-grid-column"
                    >
                      <Index.Box className="details-flex-main-user">
                        <Index.Typography
                          className="col1-user-main-content"
                          component="p"
                          variant="p"
                        >
                          Unique Id No.
                        </Index.Typography>
                        <Index.Typography
                          className="col2-user-main-content"
                          component="p"
                          variant="p"
                        >
                          {userInfo?.uniqueIdNo
                            ? userInfo?.uniqueIdNo
                            : "-"}
                        </Index.Typography>

                      
                            
                      </Index.Box>
                    </Index.Box> */}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default UserDetails;
