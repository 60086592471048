import React from 'react'
import Index from '../Index'

export default function OutlineButton(props) {
    return (
        <>

            <Index.Box className='outline-btn-main'>
                <Index.Button className={props.className} onClick={props.onClick}><img src={props.imgSrc} className="store-img-home"></img>{props.btnLabel}</Index.Button>
            </Index.Box>

            {/* use this button like below demo */}
            {/* <Index.OutlineButton btnLabel="View Orders"/> */}

        </>
    )
}
