import React, { useRef, useState } from "react";
import Index from "../../Index";
import { verifyEmail } from "../../../redux/features/slices/User/UserService";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getUserData,
  getUserToken,
} from "../../../redux/features/slices/Admin/AdminSlice";
import CircularProgress from '@mui/material/CircularProgress';
import { MuiOtpInput } from "mui-one-time-password-input";
import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";

export default function Otp() {
  const dispatch = useDispatch();
  const [otpError, setOtpError] = useState("");
const [resendOtpData,setResendOtpData]=useState(false)
  const navigate = useNavigate();
  let { userId } = useParams();
  const [otp, setOtp] = useState();
  const [isLoading,setIsLoading]=useState(false)

  // OTP verify API call
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const data = { eVerifyId: otp, id: userId };
    if (!isNaN(otp) && otp.length === 4) {
      // if (otp == 1111) {
      setOtpError("");
      setIsLoading(true)
      dispatch(verifyEmail(data)).then((response) => {
        console.log(response, "OTP response");
        // console.log(
        //   response?.payload?.data?.accessToken,
        //   "OTP response",
        //   "token"
        // );
        if(response.payload.status == 200){
          setIsLoading(false)
          if (response?.payload?.data?.accessToken) {
            toast.success("Email verified successfully");
            dispatch(getUserToken(response?.payload?.data?.accessToken));
            dispatch(getUserData(response?.payload?.data));
            navigate("/user/dashboard");
          } else {
            // console.log(response, "OTP response", 1);
            // toast.error(response?.payload?.message);
            toast.error("OTP is not valid");
          }
        }
        else{
          setIsLoading(false)
        }
      
      });
    } else {
      setOtpError("Please provide OTP in numbers only");
    }
  };

  // OTP change function
  const handleChange = (newValue) => {
    setOtp(newValue);
    if (!isNaN(newValue) && newValue.length === 4) {
      setOtpError();
    }
  };
  const resendOtp = async () => {
    let data = new URLSearchParams();
    data.append("id", userId);
    setResendOtpData(true)
    let resendOtpApi = DataService.post(API.User.RESEND_OTP, data)
      .then((res) => {
        setResendOtpData(false)
        toast.success(res.data.message)
      })
      .catch((error) => {
        // console.log(error, "error");
      });
  };

  return (
    <>
      <Index.Box className="login-main-content login-user-main">
        <Index.Box className="grid-row login-row">
          <Index.Box sx={{ width: 1 }} className="grid-main login-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              className="login-grid"
              sx={{ margin: 0, alignItems: "center" }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-bg-content">
                  <Index.Box className="login-logo-details">
                    <Index.Link to="/user/">
                      {" "}
                      <img src={Index.Svg.logo} className="logo-login"></img>
                    </Index.Link>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-form-details">
                  <Index.Box className="login-pd-main">
                    <Index.Box className="login-max-content">
                      <Index.Box className="login-details-title">
                        <Index.Typography
                          className="login-title"
                          component="h5"
                          variant="h5"
                        >
                          OTP
                        </Index.Typography>
                        <Index.Typography
                          className="login-desc"
                          component="p"
                          variant="p"
                        >
                          Enter your OTP
                        </Index.Typography>
                      </Index.Box>

                      <form onSubmit={onSubmitHandler}>
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                          className="login-grid-inner"
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column login-column mb-20px"
                          >
                            <Index.Box className="input-box custom-form-details login-input">
                              <Index.Box className="form-group custom-group">
                                <Index.FormHelperText className="form-label">
                                  Enter Otp
                                </Index.FormHelperText>
                                <Index.Box className="flex-top-input">
                                  {/* <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control custom-control otp-controller"
                                    type="number"
                                    maxLength={1}
                                    onChange={(e) => setFirst(e.target.value)}
                                  />
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control custom-control otp-controller"
                                    onChange={(e) => setTwo(e.target.value)}
                                  />
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control custom-control otp-controller"
                                    onChange={(e) => setThree(e.target.value)}
                                  />
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control custom-control otp-controller"
                                    onChange={(e) => setFour(e.target.value)}
                                  /> */}
                                  <Index.Box className="otp-input-mui">
                                    <MuiOtpInput
                                      className="otp-inner-input-list"
                                      length={4}
                                      value={otp}
                                      onChange={handleChange}
                                      name="otp"
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            {otpError && (
                              <Index.FormHelperText error>
                                {otpError}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column login-column mb-20px"
                          >
                            <Index.Box className="signin-btn-main">
                            {isLoading ? <><Index.Box className="comman-loader-button"><CircularProgress /></Index.Box></>:
                              <Index.PrimaryButton
                                btnLabel="Submit"
                                type="submit"
                                onCli
                                className="comman-primary-btn"
                              />}
                            </Index.Box>
                          </Index.Box>

                  
                          
                         
                        </Index.Box>
                      </form>
                      <Index.Box sx={{textAlign:'right'}}>
                          <Index.Button 
                          type="button" 
                          btnLabel="Resent Otp"
                          onClick={resendOtp}
                          sx={{color:'#ff9800'}}
                          >
                            {resendOtpData? <CircularProgress />:'Resent Otp'}
                          </Index.Button>
                          </Index.Box>
                    </Index.Box>
                    
                  </Index.Box>
                  
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        
      </Index.Box>
    </>
  );
}
