import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import AddUser from "../container/admin/pages/user/AddUser";
import Generalsetting from "../container/admin/pages/generalSetting/Setting";
import ViewUser from "../container/admin/pages/user/View";
import Dashboard from "../container/admin/pages/dashboard/Dashboard";
import Login from "../container/admin/auth/Login";
import EditProfile from "../container/admin/pages/editProfile/EditProfile";
import ForgotPassword from "../container/admin/auth/ForgotPassword";

import ResetPassword from "../container/admin/auth/ResetPassword";
import UserList from "../container/admin/pages/user/UserList";
import UserLogin from "../container/user/auth/Login";
import Home from "../container/user/pages/home/Home";
import SignUp from "../container/user/auth/SignUp";
import UserForgotPassword from "../container/user/auth/ForgotPassword";
import UserResetPassword from "../container/user/auth/ResetPassword";
import UserPrivateRoutes from "./UserPrivateRoutes";
import AdminPrivaRoutes from "./AdminPrivateRoutes";
import UserDashboard from "../container/user/pages/userDashboard/UserDashboard";
import Otp from "../container/user/auth/Otp";
import Profile from "../container/user/pages/userDashboard/Profile";
import RoadMap from "../container/admin/pages/roadMap/RoadMap";
import AddRoadMap from "../container/admin/pages/roadMap/AddRoadMap";
import BMIReport from "../container/user/pages/bmireport/BMIReport";
import Wallet from "../container/admin/pages/wallet/Wallet";
import WalletHistory from "../component/user/pages/userDashboard/WalletHistory";
import ContactRequest from "../container/admin/pages/contactRequest/ContactRequest";

const Routers = () => {
  const navigate = useNavigate();
  const path = useLocation();
  const auth = useSelector((store) => store.admin.isAuthenticated);

  // useEffect(() => {
  //   if (
  //     path.pathname !== "/admin/login" &&
  //     path.pathname.includes("/users") &&
  //     !auth
  //   ) {
  //     navigate("/users");
  //   } else if (path.pathname.includes("/admin") && !auth) {
  //     navigate("/admin/login");
  //   }
  // }, [path]);

  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="/admin/login" element={<Login />} />
      <Route path="admin/forgot-password" element={<ForgotPassword />} />
      <Route path="admin/reset-password/:userId" element={<ResetPassword />} />
      <Route element={<AdminPrivaRoutes />}>
        <Route path="admin" element={<Dashboard />}>
          <Route path="road-map" element={<RoadMap />} />
          <Route path="add-roadmap" element={<AddRoadMap />} />
          <Route path="general-setting" element={<Generalsetting />} />
          <Route path="edit-roadmap/:editId" element={<AddRoadMap />} />
          <Route path="edit-profile" element={<EditProfile />} />
          <Route path="user" element={<UserList />} />
          <Route path="add-user" element={<AddUser />} />
          <Route path="edit-user/:editId" element={<AddUser />} />
          <Route path="view/:editId" element={<ViewUser />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="contact-request" element={<ContactRequest />} />
        </Route>
      </Route>

      <Route path="/user">
        <Route path="login" element={<UserLogin />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="forgot-password" element={<UserForgotPassword />} />
        <Route path="reset-password/:userId" element={<UserResetPassword />} />
        <Route path="signup/:referCode=referCode" element={<SignUp />} />
        <Route path="otp-verify/:userId" element={<Otp />} />
        <Route element={<UserPrivateRoutes />}>
          <Route path="" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="dashboard" element={<UserDashboard />} />
          <Route path="wallet-history" element={<WalletHistory />} />
          <Route path="profile" element={<Profile />} />
          <Route path="bmi-report" element={<BMIReport />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Routers;
