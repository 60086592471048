import React from "react";
import Index from "../../container/Index";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Countdown, { zeroPad } from "react-countdown";
import dayjs from "dayjs";

export default function (props) {
  const { data, purchased } = props;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const renderer = ({ days, hours, minutes, seconds }) => {
    // Render a countdown
    return (
      <Index.Box className="dash-token-value-main">
        <Index.Box className="dash-token-value-inner">
          <Index.Box className="dash-token-value-box">
            <Index.Typography
              className="dash-token-value-text"
              component="p"
              variant="p"
            >
              {zeroPad(days)}
            </Index.Typography>
          </Index.Box>
          <Index.Typography
            className="dash-token-time-text"
            component="p"
            variant="p"
          >
            DAYS
          </Index.Typography>
        </Index.Box>

        <Index.Box className="dash-token-value-inner">
          <Index.Box className="dash-token-value-box">
            <Index.Typography
              className="dash-token-value-text"
              component="p"
              variant="p"
            >
              {zeroPad(hours)}
            </Index.Typography>
          </Index.Box>
          <Index.Typography
            className="dash-token-time-text"
            component="p"
            variant="p"
          >
            HOURS
          </Index.Typography>
        </Index.Box>

        <Index.Box className="dash-token-value-inner">
          <Index.Box className="dash-token-value-box">
            <Index.Typography
              className="dash-token-value-text"
              component="p"
              variant="p"
            >
              {zeroPad(minutes)}
            </Index.Typography>
          </Index.Box>
          <Index.Typography
            className="dash-token-time-text"
            component="p"
            variant="p"
          >
            MINUTES
          </Index.Typography>
        </Index.Box>

        <Index.Box className="dash-token-value-inner">
          <Index.Box className="dash-token-value-box">
            <Index.Typography
              className="dash-token-value-text"
              component="p"
              variant="p"
            >
              {zeroPad(seconds)}
            </Index.Typography>
          </Index.Box>
          <Index.Typography
            className="dash-token-time-text"
            component="p"
            variant="p"
          >
            SECONDS
          </Index.Typography>
        </Index.Box>
      </Index.Box>
    );
  };

  return (
    <>
      <Index.Box className="price-card-main">
        <Index.Box className="price-card-inner">
          <Index.Box
            className={"price-flex display-flex private-sale-space-between"}
          >
            <Index.Typography
              className="card-price"
              component="h3"
              variant="h3"
            >
              {data?.phaseName} -{" "}
              <span className="price-span">${data?.tokenPrice}</span>
            </Index.Typography>
            <Index.GreenButton
              disabled={true}
              className={`${
                dayjs(data?.endDate).diff(dayjs(), "h") < 0
                  ? "purple-btn"
                  : dayjs(data?.endDate).diff(dayjs(), "h") > 0 &&
                    dayjs(data?.startDate).diff(dayjs(), "h") < 0
                  ? "green-btn"
                  : "grey-btn"
              } status-btn`}
              btnLabel={`${
                dayjs(data?.endDate).diff(dayjs(), "h") < 0
                  ? "COMPLETED"
                  : dayjs(data?.endDate).diff(dayjs(), "h") >= 0 &&
                    dayjs(data?.startDate).diff(dayjs(), "h") <= 0
                  ? "RUNNING"
                  : "UPCOMING"
              }`}
            />
          </Index.Box>
          <Countdown date={data?.startDate} renderer={renderer} />
          <Index.Box className="dash-token-flex">
            <Index.Box className="dash-token-data-content">
              <Index.Typography
                className="dash-token-data-text"
                component="p"
                variant="p"
              >
                TOKEN PRICE:
              </Index.Typography>
              <Index.Typography
                className="dash-token-data-price"
                component="h4"
                variant="h5"
              >
                1 NCXT = ${data?.tokenPrice}
              </Index.Typography>
            </Index.Box>

            <Index.Box className="dash-token-data-content">
              <Index.Typography
                className="dash-token-data-text"
                component="p"
                variant="p"
              >
                PAYMENT METHOD
              </Index.Typography>
              <Index.Typography
                className="dash-token-data-price"
                component="h4"
                variant="h5"
              >
                {data?.paymentMethod}
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <Index.Box className="token-price-para">
            Vesting Period: {data?.vestingPeriod} months After{" "}
            {data?.vestingPeriod} months 10% monthly release
          </Index.Box>

          <Index.Box className="progress-bar-main dash-progressbar">
            <BorderLinearProgress
              variant="determinate"
              value={data?.roundPercent}
              className="progress-bar"
            />
            <span className="progress-bar-text">{data?.roundPercent}%</span>
          </Index.Box>

          <Index.Box className="dash-token-flex">
            {dayjs(data?.endDate).diff(dayjs(), "h") < 0 ? (
              <Index.GreenButton
                className="grey-btn buy-token-btn"
                btnLabel="Sold Out"
              />
            ) : dayjs(data?.endDate).diff(dayjs(), "h") > 0 &&
              dayjs(data?.startDate).diff(dayjs(), "h") < 0 ? (
              <Index.Link to="/users/privatesale" state={{ roundData: data }}>
                <Index.GreenButton
                  className="green-btn buy-token-btn"
                  btnLabel="BUY TOKENS"
                />
              </Index.Link>
            ) : (
              <Index.GreenButton
                className="purple-btn buy-token-btn"
                btnLabel="Upcoming"
              />
            )}
            <Index.Box className="dash-token-content">
              {dayjs(data?.endDate).diff(dayjs(), "h") < 0 ? (
                <Index.Typography
                  className="dash-token-text"
                  component="p"
                  variant="p"
                >
                  YOU PURCHASED:
                </Index.Typography>
              ) : (
                <Index.Typography
                  className="dash-token-text"
                  component="p"
                  variant="p"
                >
                  MINIMUM PURCHASE:
                </Index.Typography>
              )}
              {dayjs(data?.endDate).diff(dayjs(), "h") < 0 ? (
                <Index.Typography
                  className="dash-token-price"
                  component="h4"
                  variant="h5"
                >
                  {purchased?.purchasedTokens
                    ? purchased?.purchasedTokens
                        ?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                        })
                        .split("₹")[1]
                        .split(".")[0]
                    : "0.00"}{" "}
                  USDT
                </Index.Typography>
              ) : (
                <Index.Typography
                  className="dash-token-price"
                  component="h4"
                  variant="h5"
                >
                  {data?.minimumPurchase
                    ? data?.minimumPurchase
                        ?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                        })
                        .split("₹")[1]
                        .split(".")[0]
                    : "0.00"}{" "}
                  USDT
                </Index.Typography>
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
