import React, { useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const token = useSelector((store) => store.admin.token);

  let initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Confirm password must match with password"
      ),
  });

  const handleFormSubmit = async (values) => {
    const formData = new URLSearchParams();
    formData.append("oldPassword", values.oldPassword);
    formData.append("newPassword", values.newPassword);
    await DataService.post(API.Admin.CHANGE_PASSWORD, formData, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          toast.success("Password changes successfully");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfrimPassword, setShowConfrimPassword] = useState(false);
  const handleMouseDownConfrimPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfrimPassword = () =>
    setShowConfrimPassword((show) => !show);

  const handleMouseDownOldPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Index.Box className="tabpanel-main">
      <Formik
        enableReinitialize
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Index.Box className="change-pass-main">
              <Index.Typography
                className="common-para edit-highlight-text"
                component="p"
                variant="p"
              >
                Password
              </Index.Typography>
              <Index.Typography
                className="common-para edit-para-text"
                component="p"
                variant="p"
              >
                Enter your current & new password to reset your password
              </Index.Typography>
              <Index.Box className="input-box profile-input-box input-mb-20px">
                <Index.FormHelperText className="form-lable">
                  Old Password
                </Index.FormHelperText>
                <Index.Box className="form-group pass_group_main">
                  <Index.OutlinedInput
                    className="form-control-eye form-control custom_form_control"
                    // value={passwordFields.oldPassword}
                    placeholder="Old Password"
                    name="oldPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={touched.oldPassword && errors.oldPassword}
                    error={Boolean(errors.oldPassword && touched.oldPassword)}
                    id="outlined-adornment-password"
                    type={showOldPassword ? "text" : "password"}
                    endAdornment={
                      <Index.InputAdornment
                        position="end"
                        className="pass_position"
                      >
                        <Index.IconButton
                          className="icon_end_icon"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                          onMouseDown={handleMouseDownOldPassword}
                          edge="end"
                        >
                          {showOldPassword ? (
                            <Index.VisibilityOff />
                          ) : (
                            <Index.Visibility />
                          )}
                        </Index.IconButton>
                      </Index.InputAdornment>
                    }
                  />
                </Index.Box>
                {touched.oldPassword && errors.oldPassword && (
                  <Index.FormHelperText error>
                    {errors.oldPassword}
                  </Index.FormHelperText>
                )}
              </Index.Box>
              <Index.Box className="input-box profile-input-box input-mb-20px">
                <Index.FormHelperText className="form-lable">
                  New Password
                </Index.FormHelperText>
                <Index.Box className="form-group pass_group_main">
                  <Index.OutlinedInput
                    className="form-control-eye form-control custom_form_control"
                    // value={passwordFields.newPassword}
                    placeholder="New Password"
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={touched.newPassword && errors.newPassword}
                    error={Boolean(errors.newPassword && touched.newPassword)}
                    id="outlined-adornment-password"
                    type={showNewPassword ? "text" : "password"}
                    endAdornment={
                      <Index.InputAdornment
                        position="end"
                        className="pass_position"
                      >
                        <Index.IconButton
                          className="icon_end_icon"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? (
                            <Index.VisibilityOff />
                          ) : (
                            <Index.Visibility />
                          )}
                        </Index.IconButton>
                      </Index.InputAdornment>
                    }
                  />
                </Index.Box>
                {touched.newPassword && errors.newPassword && (
                  <Index.FormHelperText error>
                    {errors.newPassword}
                  </Index.FormHelperText>
                )}
              </Index.Box>
              <Index.Box className="input-box profile-input-box input-mb-20px">
                <Index.FormHelperText className="form-lable">
                  Confirm New Password
                </Index.FormHelperText>
                <Index.Box className="form-group pass_group_main">
                  <Index.OutlinedInput
                    className="form-control-eye form-control custom_form_control"
                    // value={passwordFields.confirmPassword}
                    placeholder="Confirm New Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    error={Boolean(
                      errors.confirmPassword && touched.confirmPassword
                    )}
                    id="outlined-adornment-password"
                    type={showConfrimPassword ? "text" : "password"}
                    endAdornment={
                      <Index.InputAdornment
                        position="end"
                        className="pass_position"
                      >
                        <Index.IconButton
                          className="icon_end_icon"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfrimPassword}
                          onMouseDown={handleMouseDownConfrimPassword}
                          edge="end"
                        >
                          {showConfrimPassword ? (
                            <Index.VisibilityOff />
                          ) : (
                            <Index.Visibility />
                          )}
                        </Index.IconButton>
                      </Index.InputAdornment>
                    }
                  />
                </Index.Box>
                {touched.confirmPassword && errors.confirmPassword && (
                  <Index.FormHelperText error>
                    {errors.confirmPassword}
                  </Index.FormHelperText>
                )}
              </Index.Box>

              <Index.Box className="user-btn-flex comman-btn-main change-pass-flex">
                <Index.Box className="save-btn-main ">
                  <Index.Button
                    className="save-user-btn primary-btn"
                    type="submit"
                  >
                    <img
                      src={Index.Svg.save}
                      alt="save"
                      className="user-save-icon"
                    ></img>
                    Save
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </form>
        )}
      </Formik>
    </Index.Box>
  );
};

export default ChangePassword;
