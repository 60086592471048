import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Refferals from "./Refferals";
import User from "./User";
import ListTab from "../../../../component/common/ListTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function UserList() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/"></Navigate>;
    }
  });

  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Box className="user-head-title user-title-row">
                <Index.Typography
                  className="admin-page-title admin-sub-titles"
                  component="h2"
                  variant="h2"
                >
                  User List
                </Index.Typography>
                <ToastContainer />

                {/* <Index.Link to="/admin/add-user" className="add-user-btn">
                  <Index.AddIcon /> Add
                </Index.Link> */}
              </Index.Box>

              <Index.Box className="tabs-main-box">
                <Index.Box sx={{ width: "100%" }}>
                  <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <ListTab
                      value={value}
                      onChange={handleChange}
                      label1={"Users"}
                      label2={"Refferals"}
                      children1={<User />}
                      children2={<Refferals />}
                      tabVal={value}
                    ></ListTab>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
