import React from "react";
import Index from "../../../../component/Index";
import { useState } from "react";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
} from "../../../../redux/features/slices/Admin/AdminSlice";

const ITEM_HEIGHT = 48;

const User = () => {
  const token = useSelector((store) => store.admin.token);
  const adminType = useSelector((store) => store.admin.adminType);
  const [isFetching, setIsFetching] = useState(false);
  const [users, setUsers] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = async () => {
    setIsFetching(true);
    await DataService.get(API.Admin.GET_REFERRED_SIGNUP_USERS, {
      headers: {
        auth: token,
      },
    })
      .then((res) => {
        setIsFetching(false);

        const rows = res.data.data.map((item) => {
          return {
            userName: item?.userName ? item?.userName : "-",
            id: item?._id,
            email: item?.email,
            countryId: item?.country?.name,
            referredBy: item?.refferalId?.firstName,
            purchasedOnNcx:
              item?.purchasedOnNcx == true ? "Purchase" : "Not Purchase",
          };
        });

        setUsers(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "countryId",
      headerName: "country",
    },
    {
      field: "referredBy",
      headerName: "Referred By",
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ width: "100%" }} className="add-user-height">
          <Index.DataGrid
            className="user-data-head width-admin-main"
            //className refferals-admin-main
            rows={users}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row?.id}
            rowsPerPageOptions={[5]}
            experimentalFeatures={{ newEditingApi: true }}
            loading={isFetching}
            autoHeight={true}
          />
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default User;
