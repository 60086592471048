import React from "react";
import Index from "../../container/Index";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function PrivateSaleWallet({
  walletData,
  buttonName,
}) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
    },
  }));

  const walletDate = new Date(walletData.vestingCompleteDate);
  const currentDate = new Date();
  return (
    <>
      <Index.Box className="private-card-main">
        <Index.Box className="price-flex display-flex">
          <Index.Typography className="card-price" component="h3" variant="h3">
            {walletData?.phaseName} <span className="price-span">Tokens</span>
          </Index.Typography>
        </Index.Box>
        <Index.Box className="private-token-value-main">
          <Index.Box className="private-token-value-inner">
            <Index.Box className="private-token-value-box">
              <Index.Typography
                className="private-token-value-text"
                component="p"
                variant="p"
              >
                {walletData?.purchasedTokens == 0
                  ? `0.00`
                  : walletData?.purchasedTokens
                      ?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })
                      .split("₹")[1]
                      .split(".")[0]}
              </Index.Typography>
            </Index.Box>
            <Index.Typography
              className="private-token-time-text"
              component="p"
              variant="p"
            >
              PURCHASED
            </Index.Typography>
          </Index.Box>

          <Index.Box className="private-token-value-inner">
            <Index.Box className="private-token-value-box">
              <Index.Typography
                className="private-token-value-text"
                component="p"
                variant="p"
              >
                {walletData?.locked == 0
                  ? `0.00`
                  : walletData?.locked
                      ?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })
                      .split("₹")[1]
                      .split(".")[0]}
              </Index.Typography>
            </Index.Box>
            <Index.Typography
              className="private-token-time-text"
              component="p"
              variant="p"
            >
              LOCKED
            </Index.Typography>
          </Index.Box>

          <Index.Box className="private-token-value-inner">
            <Index.Box className="private-token-value-box">
              <Index.Typography
                className="private-token-value-text"
                component="p"
                variant="p"
              >
               
                {!walletData?.monthlyReleaseAmount
                  ? "0.00"
                  : walletData?.monthlyReleaseAmount
                      ?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })
                      .split("₹")[1]
                      .split(".")[0]}
              </Index.Typography>
            </Index.Box>
            <Index.Typography
              className="private-token-time-text"
              component="p"
              variant="p"
            >
              MONTHLY RELEASE
            </Index.Typography>
          </Index.Box>

          <Index.Box className="private-token-value-inner">
            <Index.Box className="private-token-value-box">
              <Index.Typography
                className="private-token-value-text"
                component="p"
                variant="p"
              >
                {walletData?.withdrawn == 0
                  ? `0.00`
                  : walletData?.withdrawn
                      ?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                      })
                      .split("₹")[1]
                      .split(".")[0]}
              </Index.Typography>
            </Index.Box>
            <Index.Typography
              className="private-token-time-text"
              component="p"
              variant="p"
            >
              WITHDRAWN
            </Index.Typography>
          </Index.Box>
        </Index.Box>

        <Index.Box className="token-price-para">
          Vesting Period: {walletData?.vestingPeriod} months After{" "}
          {walletData?.vestingPeriod} months {walletData?.monthlyRelease}%
          monthly release
        </Index.Box>

        <Index.Box className="progress-bar-main private-progressbar">
          <BorderLinearProgress
            variant="determinate"
            value={walletData?.percentRemainingWithdraw || 0}
            className="progress-bar"
          />
          <span className="progress-bar-text">
            Locked {walletData?.percentRemainingWithdraw || 0}%
          </span>
        </Index.Box>

        <Index.Box className="private-token-flex">
          <Index.GreyButton
            className="grey-btn withdrow-btn"
            btnLabel={
              walletData?.vestingCompleteDate
                ? walletDate < currentDate
                  ? "UNLOCKED"
                  : "LOCKED"
                : buttonName
            }
          />
          <Index.Box className="private-token-content">
            <Index.Typography
              className="private-token-text"
              component="p"
              variant="p"
            >
              UNLOCK DATE
            </Index.Typography>
            <Index.Typography
              className="private-token-price"
              component="h4"
              variant="h5"
            >
              {`${walletData?.vestingPeriod} months after TGE`}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
