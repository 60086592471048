import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { DataService } from "../config/DataService";

const PrivateRoutes = () => {
  // Check token expiry
  const isValidToken = (userToken) => {
    if (!userToken) return false;
    DataService.defaults.headers.common.auth = userToken;
    return true;
  };

  const userToken = useSelector((store) => store.admin.userToken);
  console.log(userToken, "userToken");
  return isValidToken(userToken) ? <Outlet /> : <Navigate to="/user/login" />;
};

export default PrivateRoutes;
