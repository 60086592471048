import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API } from "../../../../config/Api";
import { DataService } from "../../../../config/DataService";

export const registerUserData = createAsyncThunk(
  "users/registerUser",

  // async (userData) => {
  //   const response = await DataService.post(API.User.REGISTER_USER, userData);
  //   return response.data;
  // }

  async (userData) => {
    try {
      const response = await DataService.post(API.User.REGISTER_USER, userData);
      if(response.data.message == "User already exists with this email address")
      {
        toast.error(response.data.message);
      }else{
        return response.data;
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }

  // async (userData) => {
  //   const response = await DataService.post(API.User.REGISTER_USER, userData);
  //   return response;
  // }
);

export const loginUserData = createAsyncThunk(
  "users/loginUser",

  // async (userData) => {
  //   const response = await DataService.post(API.User.USER_LOGIN, userData);
  //   return response;
  // }

   async (userData) => {
    try {
      const response = await DataService.post(API.User.USER_LOGIN, userData);
      // toast.success(response.data.message);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const ForgotPasswordAPI = createAsyncThunk(
  "users/forgotPassword",


   async (userData) => {
    try {
      const response = await DataService.post(API.User.FORGOT_PASSWORD, userData);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const ResetPasswordAPI = createAsyncThunk(
  "users/resetPassword",


   async (userData) => {
    try {
      const response = await DataService.post(API.User.RESET_PASSWORD, userData);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const ResetPasswordResendOTPAPI = createAsyncThunk(
  "users/resetPasswordResendOTP",

   async (userData) => {
    try {
      const response = await DataService.post(API.User.RESEND_OTP_RESET_PASSWORD, userData);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);

export const getRoadMapList = createAsyncThunk(
  "users/getRoadMapList",

  async () => {
    const response = await DataService.post(API.Admin.ROADMAP_LIST);
    return response.data;
  }
);

export const getCountryList = createAsyncThunk(
  "users/getCountryList",

  async () => {
    const response = await DataService.get(API.User.COUNTRY_LIST);
    return response.data;
  }
);

export const getMyReferredUser = createAsyncThunk(
  "users/getMyReferredUser",

  async (data) => {
    const response = await DataService.post(API.User.MY_REFERRED_USERS);
    return response.data;
  }
);

export const verifyEmail = createAsyncThunk(
  "users/verifyEmail",

  async (data) => {
    const response = await DataService.post(API.User.EMAIL_VERIFICATION, data);
    return response.data;
  }
);

export const getUserProfile = createAsyncThunk(
  "users/getUserProfile",

  async () => {
    const response = await DataService.get(API.User.GET_USER_PROFILE);
    return response.data;
  }
);

export const updateUserProfile = createAsyncThunk(
  "users/updateUserProfile",

  async (data) => {
    const response = await DataService.post(API.User.UPDATE_USER_PROFILE,data,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
);