import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import { useNavigate, useParams } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import UseFetch from "../../../../UseFetch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../../config/Api";
import message from "../../../../ValidationMessage.json";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
  setCountryList,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import { Formik } from "formik";
import { getCountryList } from "../../../../redux/features/slices/User/UserService";
import { InputAdornment, OutlinedInput, TextField } from "@mui/material";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Loader from "../../../../component/common/Loader";
import * as Yup from "yup";
export default function AddUser() {
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const countryList = useSelector((store) => store.admin.countryList);
  const [singleData, setSingleData] = React.useState({});
  console.log(singleData, "singleData");

  const { editId } = useParams();
  const [initialData, setInitialData] = useState({});
  console.log(initialData, "initialData");
  const [fields, setFieldValue] = useState({});
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (singleData) {
  //     console.log(singleData?.gender , ":singleData?.gender")
  //     const modifyData = {
  //       firstName: singleData ? singleData.firstName : null,
  //       userName: singleData ? singleData.userName : null,
  //       email: singleData ? singleData.email : null,
  //       // country:singleData ? singleData.country.name : null,
  //       country: singleData ? singleData?.country?._id : "",
  //       phoneNumber: singleData ? singleData?.phoneNumber : null,
  //       dateOfBirth: singleData ? singleData?.refferalId?.dateOfBirth : null,
  //       gender: singleData ? singleData?.gender : null,
  //       age: singleData ? singleData?.refferalId?.age : null,
  //       height: {
  //         unit: singleData?.height?.unit
  //           ? singleData?.height?.unit
  //           : heightUnit,
  //         // value: singleData?.height?.value,
  //         cm: singleData?.height?.cm,
  //         mm: singleData?.height?.mm,
  //         fit: singleData?.height?.fit,
  //         inch: singleData?.height?.inch,
  //       },
  //     };

  //     setInitialValues(modifyData);
  //   }
  // }, [singleData]);

  const token = useSelector((state) => state.admin.token);
  const fitOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10];
  const inchOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const gramOption = [0, 100, 200, 300, 400, 500, 600, 700, 800, 900];
  // weight kg
  const kgOption = [];

  for (let i = 10; i <= 500; i++) {
    kgOption.push(i);
  }

  let cmOptions = [...new Array(351)].map((each, index) => index);
  cmOptions = cmOptions?.filter((item) => {
    return item > 74;
  });
  // lbs
  const lbsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const ozOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const healthChallangesOptions = [
    "Underweight",
    "Overweight",
    "Diabetes",
    "Heart Issues",
    "Blood Pressure",
    "Asthma",
    "Joint Pain",
    "Low Energy",
  ];
  const [heightUnit, setHeightUnit] = useState(
    singleData?.height?.unit ? singleData?.height?.unit : "ft"
  );

  const [weightUnit, setWeightUnit] = useState(
    singleData?.weight?.unit ? singleData?.weight?.unit : "lbs"
  );

  const onKeyDown = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    try {
      dispatch(getCountryList()).then((response) => {
        console.log(response, "response92");
        if (response.payload) {
          dispatch(setCountryList(response?.payload?.data));
        } else {
          // toast.error("Something went wrong");
        }
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }, []);

  useEffect(() => {
    if (editId) {
      let data = DataService.get(API.Admin.GET_USER_LIST, {
        headers: {
          auth: token,
        },
      }).then((res) => {
        let user = res.data.data.filter((user) => {
          return user._id === editId;
        });
        console.log(res, "himanshu", user);
        setSingleData(user[0]);
        let singleData = user[0];

        const initialValues = {
          firstName: singleData ? singleData.firstName : null,
          userName: singleData ? singleData.userName : null,
          email: singleData ? singleData.email : null,
          country: singleData ? singleData?.country?._id : "",
          countryCode: singleData ? singleData?.countryCode : "",
          phoneNumber: singleData ? singleData?.phoneNumber : null,
          dateOfBirth: singleData?.dateOfBirth ? moment(singleData?.dateOfBirth).format(
            "MM/DD/YYYY"
          ) : null,
          gender: singleData ? singleData?.gender : null,
          age: singleData ? singleData?.age : null,
          // routine: singleData ? singleData?.refferalId?.routine : null,
          routine: singleData ? singleData?.routine : null,
          foodPreference: singleData ? singleData?.foodPreference : null,
          diet: singleData ? singleData?.diet : null,
          goal: singleData ? singleData?.goal : [],
          healthChallanges: singleData ? singleData?.healthChallanges : [],
          uniqueIdNo: singleData?.uniqueIdNo,
          kycType: singleData?.kyc?.kycType,
          kycDocument: singleData?.kyc?.kycDocument,
          sendKycDocument : 0,
          height: {
            unit: singleData?.height?.unit
              ? singleData?.height?.unit
              : heightUnit,
            cm: singleData?.height?.cm,
            mm: singleData?.height?.mm,
            fit: singleData?.height?.fit,
            inch: singleData?.height?.inch,
          },
          weight: {
            unit: singleData?.weight?.unit
              ? singleData?.weight?.unit
              : weightUnit,
            value: singleData?.weight?.value,
            lbs: singleData?.weight?.lbs,
            oz: singleData?.weight?.oz,
            kilo: singleData?.weight?.kilo,
            gram: singleData?.weight?.gram,
          },
        };

        console.log(initialValues, ":modifyData");
        setInitialData(initialValues);
      });
    }
  }, [editId]);

  const handleFormSubmit = async (values) => {
    console.log(values, "Edit data");
    const data = new FormData();

    data.append("id", editId);
    data.append("firstName", values?.firstName);
    data.append("userName", values?.userName);

    data.append("email", values?.email);
    data.append("country", values?.country);
    data.append("countryCode", values?.countryCode);
    if (values?.phoneNumber) {
      data.append("phoneNumber", values?.phoneNumber);
    }
    data.append("dateOfBirth", values?.dateOfBirth);
    data.append("gender", values?.gender);
    data.append("age", values?.age);
    data.append(
      "height[unit]",
      values?.height?.unit ? values?.height?.unit : ""
    );
    data.append("height[cm]", values?.height?.cm ? values?.height?.cm : "");
    data.append("height[fit]", values?.height?.fit ? values?.height?.fit : "");
    data.append(
      "height[inch]",
      values?.height?.inch ? values?.height?.inch : ""
    );
    data.append("height[mm]", values?.height?.mm ? values?.height?.mm : 0);
    data.append(
      "weight[unit]",
      values?.weight?.unit ? values?.weight?.unit : ""
    );
    data.append(
      "weight[gram]",
      values?.weight?.gram ? values?.weight?.gram : ""
    );
    data.append(
      "weight[kilo]",
      values?.weight?.kilo ? values?.weight?.kilo : ""
    );
    data.append("weight[lbs]", values?.weight?.lbs ? values?.weight?.lbs : "");
    data.append("weight[oz]", values?.weight?.oz ? values?.weight?.oz : "");
    data.append("diet", values?.diet);
    data.append("routine", values?.routine);
    data.append("foodPreference", values?.foodPreference);

    // data.append("goal", values?.goal);

    values?.goal?.forEach((item) => data.append("goal[]", item));
    // data.append("healthChallanges", values?.healthChallanges?.[0]);
    values?.healthChallanges.forEach((item) =>
      data.append("healthChallanges[]", item)
    );

    if (values?.uniqueIdNo) {
      data.append("uniqueIdNo", values?.uniqueIdNo);
    }
    if (values?.kycType) {
      data.append("kycType", values?.kycType);
    }

    if (values?.sendKycDocument) {
      data.append("image", values?.kycDocument);
    }
    let updateData = DataService.post(API.Admin.GET_USER_EDIT_DATA, data, {
      headers: {
        auth: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log(res, 184);
        if (res?.data?.status == 200) {
          toast.success("User updated successfully");
          navigate("/admin/user");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const textAndSpaceOnly = (value) =>
    /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value);
  const charSpaceNumber = (value) => /^[a-zA-Z0-9_.-]*$/.test(value);

  const validationSchemaStep1 = Yup.object().shape({
    firstName: Yup.string()
      .nullable()
      .test(
        "firstName",
        "Fullname allows only characters and space between",
        textAndSpaceOnly
      )
      .max(20, "Fullname allows maximum length 20"),
    userName: Yup.string()
      .required("Username is required")
      .test(
        "userName",
        "Username allows only characters and number",
        charSpaceNumber
      )
      .max(20, "Username allows maximum length 20"),
    phoneNumber: Yup.string()
      .nullable()
      .min(10, "Mobile number minimum length must be 10 digits")
      .max(10, "Mobile number maximum length must be 10 digits"),

    //   firstName: Yup.string()
    //   .required("Fullname is required")
    //   .test("firstName", "Fullname allows only characters", textAndSpaceOnly)
    //   .min(5, "Please enter 5 character at least")
    //   .max(20, "Fullname allows maximum length 20"),
    // userName: Yup.string()
    //   .required("Username is required")
    //   .test(
    //     "userName",
    //     "Username allows only characters and number",
    //     charSpaceNumber
    //   )
    //   .min(5, "Please enter 5 character at least")
    //   .max(20, "Username allows maximum length 20"),
    // phoneNumber: Yup.string()
    //   .required("Mobile number is required")
    //   .min(10, "Mobile number should be 10 digits")
    //   .max(10, "Mobile number should be 10 digits"),
    // country: Yup.string().required("Country is required"),
    // dateOfBirth: Yup.string().required("Date of birth is required"),
    // gender: Yup.string().required("Gender is required"),
    // age: Yup.string()
    //   .required("Age is required")
    //   .max(3, "Age allows only up to 3 digits"),
    // height: Yup.object().shape({
    //   unit: Yup.string().required("Unit is required"),
    //   cm: Yup.string().test("cmRequired", "CM is required", function (value) {
    //     const { unit } = this.parent;
    //     if (unit === "cms") {
    //       return value && value.trim() !== "";
    //     }
    //     return true;
    //   }),
    //   mm: Yup.string().test("cmRequired", "MM is required", function (value) {
    //     const { unit } = this.parent;
    //     if (unit === "cms") {
    //       return value && value.trim() !== "";
    //     }
    //     return true;
    //   }),
    //   fit: Yup.string().test("cmRequired", "Fit is required", function (value) {
    //     const { unit } = this.parent;
    //     if (unit === "ft") {
    //       return value && value.trim() !== "";
    //     }
    //     return true;
    //   }),
    //   inch: Yup.string().test(
    //     "cmRequired",
    //     "Inches is required",
    //     function (value) {
    //       const { unit } = this.parent;
    //       if (unit === "ft") {
    //         return value && value.trim() !== "";
    //       }
    //       return true;
    //     }
    //   ),
    // }),
    // weight: Yup.object().shape({
    //   unit: Yup.string().required("Unit is required"),
    //   lbs: Yup.string().test(
    //     "lbsRequired",
    //     "LBS is required",
    //     function (value) {
    //       const { unit } = this.parent;
    //       if (unit === "lbs") {
    //         return value && value.trim() !== "";
    //       }
    //       return true;
    //     }
    //   ),
    //   oz: Yup.string().test("ozRequired", "OZ is required", function (value) {
    //     const { unit } = this.parent;
    //     if (unit === "lbs") {
    //       return value && value.trim() !== "";
    //     }
    //     return true;
    //   }),
    //   kilo: Yup.string().test("kgRequired", "KG is required", function (value) {
    //     const { unit } = this.parent;
    //     if (unit === "kg") {
    //       return value && value.trim() !== "";
    //     }
    //     return true;
    //   }),
    //   gram: Yup.string().test(
    //     "gramRequired",
    //     "Gram is required",
    //     function (value) {
    //       const { unit } = this.parent;
    //       if (unit === "kg") {
    //         return value && value.trim() !== "";
    //       }
    //       return true;
    //     }
    //   ),
    // }),
    // routine: Yup.string().required("Routine is required"),
    // foodPreference: Yup.string().required("Food preference is required"),
    // diet: Yup.string().required("Diet is required"),
    // goal: Yup.array().min(1, "Select at least one goal"),
    // healthChallanges: Yup.array().min(
    //   1,
    //   "Select at least one Health Challanges"
    // ),
    // kycType: Yup.string().required(
    //   "Identification document type is required"
    // ),
    // uniqueIdNo: Yup.string()
    //   .required("Unique identification number is required")
    //   .max(15, "Unique identification number allows maximum 15 digits"),
    kycDocument: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "FILE_FORMAT",
        "allow only jpg,jpeg,png,pdf file",
        (value) =>
          !value ||
          (["jpg", "jpeg", "png", "pdf"].includes(
            value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
          ) &&
            [
              "image/jpg",
              "image/jpeg",
              "image/png",
              "application/pdf",
            ].includes(value?.type))
      )
      .test(
        "fileSize",
        "File size too large, max file size is 2.048 Mb",
        (file) => {
          if (file) {
            return file.size <= 2048000;
          } else {
            return true;
          }
        }
      ),
    
  });

  if (!singleData?._id) {
    return <Loader></Loader>;
  } else {
    return (
      <div>
        <Index.Box className="dashboard-main">
          <Index.Box className="dashboard-left-main">
            <Index.Sidebar />
          </Index.Box>
          <Index.Box className="dashboard-right-main">
            <Index.Header />
            <Index.Box className="dashboard-containt-main">
              <Index.Box className="dashboard-content add-user-containt">
                <Index.Typography
                  className="admin-page-title  add-user-title add-user-page-title"
                  component="h2"
                  variant="h2"
                >
                  Edit User
                </Index.Typography>
                <ToastContainer />
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Formik
                      enableReinitialize
                      initialValues={initialData}
                      onSubmit={handleFormSubmit}
                      validationSchema={validationSchemaStep1}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Index.Box
                            display="grid"
                            className="display-row user-row"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Full Name
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control custom-control"
                                    name="firstName"
                                    value={values?.firstName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    helperText={
                                      touched.firstName
                                        ? errors.firstName
                                        : undefined
                                    }
                                    error={Boolean(
                                      errors.firstName
                                        ? touched.firstName
                                        : undefined
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    User Name
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control custom-control"
                                    name="userName"
                                    value={values?.userName}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    helperText={
                                      touched.userName
                                        ? errors.userName
                                        : undefined
                                    }
                                    error={Boolean(
                                      errors.userName
                                        ? touched.userName
                                        : undefined
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Email
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    disabled
                                    className="form-control custom-control"
                                    name="email"
                                    value={values?.email}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.Box className="input-box custom-form-details login-input">
                                    <Index.Box className="form-group custom-group">
                                      <Index.FormHelperText className="form-label">
                                        Country
                                      </Index.FormHelperText>

                                      <Index.Box className="dropdown-box">
                                        {values?.country != "" ? (
                                          <Index.FormControl className="form-control">
                                            <Index.Select
                                              className="dropdown-select "
                                              name="country"
                                              defaultValue={values?.country}
                                              value={values?.country}
                                              onChange={(e) => {
                                                console.log(
                                                  e.target.value,
                                                  "country"
                                                );
                                                setFieldValue(
                                                  "country",
                                                  e.target.value
                                                );

                                                const filteredCountry =
                                                  countryList?.filter(
                                                    (ele) =>
                                                      ele?._id == e.target.value
                                                  );
                                                if (filteredCountry[0]) {
                                                  setFieldValue(
                                                    "countryCode",
                                                    filteredCountry[0]
                                                      ?.dial_code
                                                  );
                                                }
                                              }}
                                              displayEmpty
                                              inputProps={{
                                                "aria-label": "Without label",
                                              }}
                                            >
                                              {countryList?.map(() => {
                                                <Index.MenuItem value={10}>
                                                  Ten
                                                </Index.MenuItem>;
                                              })}
                                              {countryList?.map(
                                                (item, index) => (
                                                  <Index.MenuItem
                                                    value={item?._id}
                                                    key={item?._id}
                                                  >
                                                    {item?.name}
                                                  </Index.MenuItem>
                                                )
                                              )}
                                            </Index.Select>
                                          </Index.FormControl>
                                        ) : (
                                          <Index.FormControl className="form-control">
                                            <Index.Select
                                              className="dropdown-select "
                                              name="country"
                                              value={values?.country}
                                              onChange={(e) => {
                                                console.log(
                                                  e.target.value,
                                                  "country"
                                                );
                                                setFieldValue(
                                                  "country",
                                                  e.target.value
                                                );
                                                setSelectedCountry(
                                                  e.target.value
                                                );
                                              }}
                                              displayEmpty
                                              inputProps={{
                                                "aria-label": "Without label",
                                              }}
                                            >
                                              {countryList?.map(() => {
                                                <Index.MenuItem value={10}>
                                                  Ten
                                                </Index.MenuItem>;
                                              })}
                                              {countryList?.map(
                                                (item, index) => (
                                                  <Index.MenuItem
                                                    value={item?._id}
                                                    key={item?._id}
                                                  >
                                                    {item?.name}
                                                  </Index.MenuItem>
                                                )
                                              )}
                                            </Index.Select>
                                          </Index.FormControl>
                                        )}
                                      </Index.Box>
                                    </Index.Box>
                                    {touched.country && errors.country && (
                                      <Index.FormHelperText error>
                                        {errors.country}
                                      </Index.FormHelperText>
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Mobile Number
                                  </Index.FormHelperText>

                                  <Index.Box className="input-details-main num-main-input">
                                    <Index.Box className="form-group pass_group_main pass-num-main">
                                      <OutlinedInput
                                        fullWidth
                                        id="fullWidth"
                                        type="number"
                                        className="form-control custom-control"
                                        name="phoneNumber"
                                        onBlur={handleBlur}
                                        value={values?.phoneNumber}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                          if (
                                            e.target.value.toString().length <= 10
                                          ) {
                                            setFieldValue("phoneNumber", e.target.value);
                                          }
                                        }}
                                        startAdornment={
                                          <InputAdornment
                                            position="start"
                                            className="pass_position num-position"
                                          >
                                            {values?.countryCode}
                                          </InputAdornment>
                                        }
                                        helperText={
                                          touched.phoneNumber &&
                                          errors.phoneNumber
                                        }
                                        error={Boolean(
                                          errors.phoneNumber &&
                                            touched.phoneNumber
                                        )}
                                      />

                                      {touched.phoneNumber &&
                                        errors.phoneNumber && (
                                          <Index.FormHelperText error>
                                            {errors.phoneNumber}
                                          </Index.FormHelperText>
                                        )}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Date of Birth
                                  </Index.FormHelperText>

                                  {/* <StyledEngineProvider injectFirst> */}
                                  <Index.Box className="datepicker-wrap">
                                    <LocalizationProvider
                                      dateAdapter={AdapterMoment}
                                      className="datepicker-wrap-inner"
                                    >
                                      <DatePicker
                                        maxDate={moment(new Date())}
                                        value={
                                          values?.dateOfBirth
                                            ? values?.dateOfBirth
                                            : ""
                                        }
                                        onChange={(value) => {
                                          console.log(
                                            moment(value._d).format(
                                              "MM/DD/YYYY"
                                            )
                                          );
                                          setFieldValue(
                                            "dateOfBirth",
                                            moment(value._d).format(
                                              "MM/DD/YYYY"
                                            )
                                          );

                                          const currentDate = moment(
                                            new Date()
                                          );
                                          const selectedDate = moment(value);
                                          var yearDifference = currentDate.diff(
                                            selectedDate,
                                            "year"
                                          );
                                          console.log(
                                            yearDifference,
                                            "yearDifference"
                                          );
                                          setFieldValue("age", yearDifference);
                                        }}
                                        renderInput={(props) => (
                                          <TextField
                                            {...props}
                                            size="small"
                                            onKeyDown={onKeyDown}
                                            helperText={null}
                                            className="date-text-list"
                                          />
                                        )}
                                        className="mob-date-picker date-picker-details"
                                      />
                                    </LocalizationProvider>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Gender
                                  </Index.FormHelperText>
                                  <Index.Box className="gender-user-account">
                                    <Index.FormControl className="form-gender-control">
                                      
                                        <Index.RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="gender"
                                          className="form-gendergroup-control"
                                          defaultValue={
                                            values && values?.gender
                                          }
                                          onChange={(e, value) => {
                                            setFieldValue("gender", value);
                                          }}
                                        >
                                          <Index.FormControlLabel
                                            value="female"
                                            control={<Index.Radio />}
                                            label="Female"
                                            className="form-genderlabel-control"
                                          />
                                          <Index.FormControlLabel
                                            value="male"
                                            control={<Index.Radio />}
                                            label="Male"
                                            className="form-genderlabel-control"
                                          />
                                          <Index.FormControlLabel
                                            value="other"
                                            control={<Index.Radio />}
                                            label="Other"
                                            className="form-genderlabel-control"
                                          />
                                        </Index.RadioGroup>
                                      
                                    </Index.FormControl>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Age
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    type="number"
                                    id="fullWidth"
                                    className="form-control custom-control"
                                    name="age"
                                    value={values?.age}
                                    inputProps={{ maxLength: 3 }}
                                    onBlur={handleBlur}
                                    // onChange={handleChange}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.toString().length <= 3
                                      ) {
                                        setFieldValue("age", e.target.value);
                                      }
                                    }}
                                    helperText={
                                      touched.age ? errors.age : undefined
                                    }
                                    error={Boolean(
                                      errors.age ? touched.age : undefined
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            {/* height start */}
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list step-height-text">
                                <Index.Box className="form-group custom-group">
                                  <Index.Box className="flex-center-radio-hgt">
                                    <Index.FormHelperText className="form-label">
                                      Height
                                    </Index.FormHelperText>
                                    <Index.Box className="height-radio-main">
                                      <Index.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        className="form-heightgroup-control-main"
                                        defaultValue={values?.height?.unit}
                                        // value={values?.height?.unit}
                                        onChange={(e, value) => {
                                          {
                                            console.log(value, 620);
                                          }
                                          // setHeightUnit(value);
                                          const formatedData = {
                                            unit: value,
                                            value: values?.height?.value,
                                          };
                                          setFieldValue("height", formatedData);
                                        }}
                                      >
                                        <Index.FormControlLabel
                                          value="fit"
                                          // value={values?.height?.ft}
                                          control={<Index.Radio />}
                                          label="Fit/Inches"
                                          className="form-heightgroup-control"
                                        />
                                        <Index.FormControlLabel
                                          value="cms"
                                          //  value={values?.height?.ft}
                                          control={<Index.Radio />}
                                          label="Centimeters"
                                          className="form-heightgroup-control"
                                        />
                                      </Index.RadioGroup>
                                    </Index.Box>
                                  </Index.Box>
                                  {values?.height?.unit != "cms" ? (
                                    <Index.Box
                                      display="grid"
                                      gridTemplateColumns="repeat(12, 1fr)"
                                      gap={{
                                        xs: 0,
                                        sm: 0,
                                        md: 0,
                                        lg: 0,
                                      }}
                                      className="stepper-signup-grid"
                                      sx={{
                                        margin: 0,
                                        alignItems: "center",
                                      }}
                                    >
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 6",
                                          md: "span 6",
                                          lg: "span 6",
                                        }}
                                        className="grid-column stepper-signup-column "
                                      >
                                        <Index.Box className="edit_profile_field height-stepper-main">
                                          <Index.Box className="form-group from_login_details">
                                            <Index.Box className="form-flex-input">
                                              <Index.Box className="dropdown-box">
                                                <Index.FormControl className="form-control">
                                                  {console.log(
                                                    values?.height?.fit,
                                                    684
                                                  )}
                                                  <Index.Select
                                                    className="dropdown-select "
                                                    // name="country"
                                                    // value={edit.levelId}
                                                    value={values?.height?.fit}
                                                    defaultValue={
                                                      values?.height?.fit
                                                    }
                                                    onChange={(e) => {
                                                      const formatedData = {
                                                        unit: values?.height
                                                          ?.unit
                                                          ? values?.height?.unit
                                                          : heightUnit,
                                                        value:
                                                          values?.height?.value,
                                                        fit: e.target.value,
                                                        inch: values?.height
                                                          ?.inch,
                                                        cm: values?.height?.cm,
                                                        mm: values?.height?.mm,
                                                      };
                                                      console.log(
                                                        formatedData,
                                                        "heightformatedData"
                                                      );
                                                      setFieldValue(
                                                        "height",
                                                        formatedData
                                                      );
                                                    }}
                                                    displayEmpty
                                                    inputProps={{
                                                      "aria-label":
                                                        "Without label",
                                                    }}
                                                  >
                                                    {fitOptions.map((item) => (
                                                      <Index.MenuItem
                                                        value={item}
                                                        className="menuitem"
                                                      >
                                                        {item}
                                                      </Index.MenuItem>
                                                    ))}
                                                  </Index.Select>
                                                </Index.FormControl>
                                              </Index.Box>
                                              <Index.Box className="text-height-drop">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="text-drop-list"
                                                >
                                                  ft
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 6",
                                          md: "span 6",
                                          lg: "span 6",
                                        }}
                                        className="grid-column stepper-signup-column "
                                      >
                                        <Index.Box className="edit_profile_field height-stepper-main">
                                          <Index.Box className="form-group from_login_details">
                                            <Index.Box className="form-flex-input">
                                              <Index.Box className="dropdown-box">
                                                <Index.FormControl className="form-control">
                                                  <Index.Select
                                                    className="dropdown-select "
                                                    value={values?.height?.inch}
                                                    defaultValue={
                                                      values?.height?.inch
                                                    }
                                                    onChange={(e) => {
                                                      const formatedData = {
                                                        unit: values?.height
                                                          ?.unit
                                                          ? values?.height?.unit
                                                          : heightUnit,
                                                        value:
                                                          values?.height?.value,
                                                        fit: values?.height
                                                          ?.fit,
                                                        inch: e.target.value,
                                                        cm: values?.height?.cm,
                                                        mm: values?.height?.mm,
                                                      };
                                                      console.log(
                                                        formatedData,
                                                        "heightformatedData"
                                                      );
                                                      setFieldValue(
                                                        "height",
                                                        formatedData
                                                      );
                                                    }}
                                                    displayEmpty
                                                    inputProps={{
                                                      "aria-label":
                                                        "Without label",
                                                    }}
                                                  >
                                                    {inchOptions.map((item) => (
                                                      <Index.MenuItem
                                                        value={item}
                                                        className="menuitem"
                                                      >
                                                        {item}
                                                      </Index.MenuItem>
                                                    ))}
                                                  </Index.Select>
                                                </Index.FormControl>
                                              </Index.Box>
                                              <Index.Box className="text-height-drop">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="text-drop-list"
                                                >
                                                  Inches
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  ) : (
                                    <Index.Box
                                      display="grid"
                                      gridTemplateColumns="repeat(12, 1fr)"
                                      gap={{
                                        xs: 0,
                                        sm: 0,
                                        md: 0,
                                        lg: 0,
                                      }}
                                      className="stepper-signup-grid"
                                      sx={{
                                        margin: 0,
                                        alignItems: "center",
                                      }}
                                    >
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 6",
                                          md: "span 6",
                                          lg: "span 6",
                                        }}
                                        className="grid-column stepper-signup-column "
                                      >
                                        <Index.Box className="edit_profile_field  height-stepper-main">
                                          <Index.Box className="form-group from_login_details">
                                            <Index.Box className="form-flex-input">
                                              <Index.Box className="dropdown-box">
                                                <Index.FormControl className="form-control">
                                                  <Index.Select
                                                    className="dropdown-select "
                                                    name="height"
                                                    value={values?.height?.cm}
                                                    defaultValue={values?.height?.cm}
                                                    onChange={(e) => {
                                                      const formatedData = {
                                                        unit: values?.height
                                                          ?.unit
                                                          ? values?.height?.unit
                                                          : heightUnit,
                                                        value:
                                                          values?.height?.value,
                                                        fit: values?.height
                                                          ?.fit,
                                                        inch: values?.height
                                                          ?.inch,
                                                        cm: e.target.value,
                                                        mm: values?.height?.mm,
                                                      };
                                                      console.log(
                                                        formatedData,
                                                        "heightformatedData"
                                                      );
                                                      setFieldValue(
                                                        "height",
                                                        formatedData
                                                      );
                                                    }}
                                                    displayEmpty
                                                    inputProps={{
                                                      "aria-label":
                                                        "Without label",
                                                    }}
                                                  >
                                                    {cmOptions.map((item) => (
                                                      <Index.MenuItem
                                                        value={item}
                                                        className="menuitem"
                                                      >
                                                        {item}
                                                      </Index.MenuItem>
                                                    ))}
                                                  </Index.Select>
                                                </Index.FormControl>
                                              </Index.Box>
                                              <Index.Box className="text-height-drop">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="text-drop-list"
                                                >
                                                  cm
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 6",
                                          md: "span 6",
                                          lg: "span 6",
                                        }}
                                        className="grid-column stepper-signup-column "
                                      >
                                        <Index.Box className="edit_profile_field height-stepper-main">
                                          <Index.Box className="form-group from_login_details">
                                            <Index.Box className="form-flex-input">
                                              <Index.Box className="dropdown-box">
                                                <Index.FormControl className="form-control">
                                                  <Index.Select
                                                    className="dropdown-select "
                                                    name="height"
                                                    value={values?.height?.mm}
                                                    defaultValue={
                                                      values?.height?.mm
                                                    }
                                                    onChange={(e) => {
                                                      const formatedData = {
                                                        unit: values?.height
                                                          ?.unit
                                                          ? values?.height?.unit
                                                          : heightUnit,
                                                        value:
                                                          values?.height?.value,
                                                        fit: values?.height
                                                          ?.fit,
                                                        inch: values?.height
                                                          ?.inch,
                                                        cm: values?.height?.cm,
                                                        mm: e.target.value,
                                                      };
                                                      console.log(
                                                        formatedData,
                                                        "heightformatedData"
                                                      );
                                                      setFieldValue(
                                                        "height",
                                                        formatedData
                                                      );
                                                    }}
                                                    displayEmpty
                                                    inputProps={{
                                                      "aria-label":
                                                        "Without label",
                                                    }}
                                                  >
                                                    {/* {inchOptions.map(
                                                              (item) => ( */}
                                                    <Index.MenuItem
                                                      value={0}
                                                      className="menuitem"
                                                    >
                                                      00
                                                    </Index.MenuItem>
                                                    {/* )
                                                            )} */}
                                                  </Index.Select>
                                                </Index.FormControl>
                                              </Index.Box>
                                              <Index.Box className="text-height-drop">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="text-drop-list"
                                                >
                                                  mm
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            {/* height end */}
                            {/* weight start */}
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list step-height-text">
                                <Index.Box className="form-group custom-group">
                                  <Index.Box className="flex-center-radio-hgt">
                                    <Index.FormHelperText className="form-label">
                                      Weight
                                    </Index.FormHelperText>
                                    <Index.Box className="height-radio-main">
                                      <Index.RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        className="form-heightgroup-control-main"
                                        defaultValue={
                                          // values?.height?.unit
                                          values?.weight?.unit
                                        }
                                        onChange={(e, value) => {
                                          // setWeightUnit(value);
                                          const formatedData = {
                                            unit: value,
                                            value: values?.weight?.value,
                                            lbs: values?.weight?.lbs,
                                            oz: values?.weight?.oz,
                                            kilo: values?.weight?.kilo,
                                            gram: values?.weight?.gram,
                                          };
                                          setFieldValue("weight", formatedData);
                                        }}
                                      >
                                        <Index.FormControlLabel
                                          value="lbs"
                                          control={<Index.Radio />}
                                          label="Lbs"
                                          className="form-heightgroup-control"
                                        />
                                        <Index.FormControlLabel
                                          value="kg"
                                          control={<Index.Radio />}
                                          label="Kgs"
                                          className="form-heightgroup-control"
                                        />
                                      </Index.RadioGroup>
                                    </Index.Box>
                                  </Index.Box>
                                  {values?.weight?.unit != "lbs" ? (
                                    <Index.Box
                                      display="grid"
                                      gridTemplateColumns="repeat(12, 1fr)"
                                      gap={{
                                        xs: 0,
                                        sm: 0,
                                        md: 0,
                                        lg: 0,
                                      }}
                                      className="stepper-signup-grid"
                                      sx={{
                                        margin: 0,
                                        alignItems: "center",
                                      }}
                                    >
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 6",
                                          md: "span 6",
                                          lg: "span 6",
                                        }}
                                        className="grid-column stepper-signup-column "
                                      >
                                        <Index.Box className="edit_profile_field height-stepper-main">
                                          <Index.Box className="form-group from_login_details">
                                            <Index.Box className="form-flex-input">
                                              <Index.Box className="dropdown-box">
                                                <Index.FormControl className="form-control">
                                                  <Index.Select
                                                    className="dropdown-select "
                                                    // value={values?.weight?.fit}
                                                    value={values?.weight?.kilo}
                                                    defaultValue={
                                                      values?.weight?.kilo
                                                    }
                                                    onChange={(e) => {
                                                      const formatedData = {
                                                        unit: values?.weight
                                                          ?.unit
                                                          ? values?.weight?.unit
                                                          : weightUnit,
                                                        value:
                                                          values?.weight?.value,
                                                        kilo: e.target.value,
                                                        gram: values?.weight
                                                          ?.gram,
                                                        lbs: values?.weight
                                                          ?.lbs,
                                                        oz: values?.weight?.oz,
                                                      };
                                                      console.log(
                                                        formatedData,
                                                        "weightformatedData1457"
                                                      );
                                                      setFieldValue(
                                                        "weight",
                                                        formatedData
                                                      );
                                                    }}
                                                    displayEmpty
                                                    inputProps={{
                                                      "aria-label":
                                                        "Without label",
                                                    }}
                                                  >
                                                    {kgOption.map((item) => (
                                                      <Index.MenuItem
                                                        value={item}
                                                        className="menuitem"
                                                      >
                                                        {item}
                                                      </Index.MenuItem>
                                                    ))}
                                                  </Index.Select>
                                                </Index.FormControl>
                                              </Index.Box>
                                              <Index.Box className="text-height-drop">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="text-drop-list"
                                                >
                                                  Kilo
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 6",
                                          md: "span 6",
                                          lg: "span 6",
                                        }}
                                        className="grid-column stepper-signup-column "
                                      >
                                        <Index.Box className="edit_profile_field height-stepper-main">
                                          <Index.Box className="form-group from_login_details">
                                            <Index.Box className="form-flex-input">
                                              <Index.Box className="dropdown-box">
                                                <Index.FormControl className="form-control">
                                                  <Index.Select
                                                    className="dropdown-select "
                                                    value={values?.weight?.gram}
                                                    defaultValue={
                                                      values?.weight?.gram
                                                    }
                                                    onChange={(e) => {
                                                      const formatedData = {
                                                        unit: values?.weight
                                                          ?.unit
                                                          ? values?.weight?.unit
                                                          : weightUnit,
                                                        value:
                                                          values?.weight?.value,
                                                        kilo: values?.weight
                                                          ?.kilo,
                                                        gram: e.target.value,
                                                        lbs: values?.weight
                                                          ?.lbs,
                                                        oz: values?.weight?.oz,
                                                      };
                                                      console.log(
                                                        formatedData,
                                                        "weightformatedData1516"
                                                      );
                                                      setFieldValue(
                                                        "weight",
                                                        formatedData
                                                      );
                                                    }}
                                                    displayEmpty
                                                    inputProps={{
                                                      "aria-label":
                                                        "Without label",
                                                    }}
                                                  >
                                                    {gramOption.map((item) => (
                                                      <Index.MenuItem
                                                        value={item}
                                                        className="menuitem"
                                                      >
                                                        {item}
                                                      </Index.MenuItem>
                                                    ))}
                                                  </Index.Select>
                                                </Index.FormControl>
                                              </Index.Box>
                                              <Index.Box className="text-height-drop">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="text-drop-list"
                                                >
                                                  Gram
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  ) : (
                                    <Index.Box
                                      display="grid"
                                      gridTemplateColumns="repeat(12, 1fr)"
                                      gap={{
                                        xs: 0,
                                        sm: 0,
                                        md: 0,
                                        lg: 0,
                                      }}
                                      className="stepper-signup-grid"
                                      sx={{
                                        margin: 0,
                                        alignItems: "center",
                                      }}
                                    >
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 6",
                                          md: "span 6",
                                          lg: "span 6",
                                        }}
                                        className="grid-column stepper-signup-column "
                                      >
                                        <Index.Box className="edit_profile_field  height-stepper-main">
                                          <Index.Box className="form-group from_login_details">
                                            <Index.Box className="form-flex-input">
                                              <Index.Box className="dropdown-box">
                                                <Index.FormControl className="form-control">
                                                  <Index.Select
                                                    className="dropdown-select "
                                                    value={values?.weight?.lbs}
                                                    onChange={(e) => {
                                                      const formatedData = {
                                                        unit: values?.weight
                                                          ?.unit
                                                          ? values?.weight?.unit
                                                          : weightUnit,
                                                        value:
                                                          values?.weight?.value,
                                                        kilo: values?.weight
                                                          ?.kilo,
                                                        gram: values?.weight
                                                          ?.gram,
                                                        lbs: e.target.value,
                                                        oz: values?.weight?.oz,
                                                      };
                                                      console.log(
                                                        formatedData,
                                                        "weightformatedData1592"
                                                      );
                                                      setFieldValue(
                                                        "weight",
                                                        formatedData
                                                      );
                                                    }}
                                                    displayEmpty
                                                    inputProps={{
                                                      "aria-label":
                                                        "Without label",
                                                    }}
                                                  >
                                                    {lbsOptions.map((item) => (
                                                      <Index.MenuItem
                                                        value={item}
                                                        className="menuitem"
                                                      >
                                                        {item}
                                                      </Index.MenuItem>
                                                    ))}
                                                  </Index.Select>
                                                </Index.FormControl>
                                              </Index.Box>
                                              <Index.Box className="text-height-drop">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="text-drop-list"
                                                >
                                                  Lbs
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box
                                        gridColumn={{
                                          xs: "span 12",
                                          sm: "span 6",
                                          md: "span 6",
                                          lg: "span 6",
                                        }}
                                        className="grid-column stepper-signup-column "
                                      >
                                        <Index.Box className="edit_profile_field height-stepper-main">
                                          <Index.Box className="form-group from_login_details">
                                            <Index.Box className="form-flex-input">
                                              <Index.Box className="dropdown-box">
                                                <Index.FormControl className="form-control">
                                                  <Index.Select
                                                    className="dropdown-select "
                                                    name="routine"
                                                    value={values?.weight?.oz}
                                                    defaultValue={
                                                      values?.weight?.oz
                                                    }
                                                    onChange={(e) => {
                                                      const formatedData = {
                                                        unit: values?.weight
                                                          ?.unit
                                                          ? values?.weight?.unit
                                                          : weightUnit,
                                                        value:
                                                          values?.weight?.value,
                                                        kilo: values?.weight
                                                          ?.kilo,
                                                        gram: values?.weight
                                                          ?.gram,
                                                        lbs: values?.weight
                                                          ?.lbs,
                                                        oz: e.target.value,
                                                      };
                                                      console.log(
                                                        formatedData,
                                                        "heightformatedData"
                                                      );
                                                      setFieldValue(
                                                        "weight",
                                                        formatedData
                                                      );
                                                    }}
                                                    displayEmpty
                                                    inputProps={{
                                                      "aria-label":
                                                        "Without label",
                                                    }}
                                                  >
                                                    {/* <Index.MenuItem
                                                                  value={0}
                                                                  className="menuitem"
                                                                >
                                                                  00
                                                                </Index.MenuItem> */}
                                                    {ozOptions.map((item) => (
                                                      <Index.MenuItem
                                                        value={item}
                                                        className="menuitem"
                                                      >
                                                        {item}
                                                      </Index.MenuItem>
                                                    ))}
                                                  </Index.Select>
                                                </Index.FormControl>
                                              </Index.Box>
                                              <Index.Box className="text-height-drop">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="text-drop-list"
                                                >
                                                  OZ
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            {/* weight end */}

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Routine
                                  </Index.FormHelperText>
                                  <Index.Box className="edit_profile_field">
                                    <Index.Box className="form-group from_login_details">
                                      <Index.Box className="dropdown-box">
                                        {values?.routine != "" ? (
                                          <Index.FormControl className="form-control">
                                            {console.log(
                                              values && values?.routine,
                                              1436
                                            )}
                                            <Index.Select
                                              className="dropdown-select "
                                              name="routine"
                                              defaultValue={values?.routine}
                                              value={values?.routine}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "routine",
                                                  e.target.value
                                                );
                                              }}
                                              displayEmpty
                                              inputProps={{
                                                "aria-label": "Without label",
                                              }}
                                            >
                                              <Index.MenuItem
                                                value="active"
                                                className="menuitem"
                                              >
                                                Active
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="normal"
                                                className="menuitem"
                                              >
                                                Normal
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="inactive"
                                                className="menuitem"
                                              >
                                                Inactive
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="sedentary"
                                                className="menuitem"
                                              >
                                                Sedentary
                                              </Index.MenuItem>
                                            </Index.Select>
                                          </Index.FormControl>
                                        ) : (
                                          <Index.FormControl className="form-control">
                                            <Index.Select
                                              className="dropdown-select "
                                              name="routine"
                                              value={values?.routine}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "routine",
                                                  e.target.value
                                                );
                                              }}
                                              displayEmpty
                                              inputProps={{
                                                "aria-label": "Without label",
                                              }}
                                            >
                                              <Index.MenuItem
                                                value="active"
                                                className="menuitem"
                                              >
                                                Active
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="inactive"
                                                className="menuitem"
                                              >
                                                Inactive
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="sedentary"
                                                className="menuitem"
                                              >
                                                Sedentary
                                              </Index.MenuItem>
                                            </Index.Select>
                                          </Index.FormControl>
                                        )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Food Preference
                                  </Index.FormHelperText>
                                  <Index.Box className="edit_profile_field">
                                    <Index.Box className="form-group from_login_details">
                                      <Index.Box className="dropdown-box">
                                        <Index.FormControl className="form-control">
                                          <Index.Select
                                            className="dropdown-select"
                                            // onChange={
                                            //   handleChangedropdown
                                            // }
                                            name="foodPreference"
                                            defaultValue={
                                              values
                                                ? values?.foodPreference
                                                : ""
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                "foodPreference",
                                                e.target.value
                                              );
                                            }}
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                          >
                                            <Index.MenuItem
                                              value="Vegan"
                                              className="menuitem"
                                            >
                                              Vegan
                                            </Index.MenuItem>
                                            <Index.MenuItem
                                              value="Vegetarian"
                                              className="menuitem"
                                            >
                                              Vegetarian
                                            </Index.MenuItem>
                                            <Index.MenuItem
                                              value="Non-Vegetarian"
                                              className="menuitem"
                                            >
                                              Non-Vegetarian
                                            </Index.MenuItem>
                                            <Index.MenuItem
                                              value="Veg&Nonveg"
                                              className="menuitem"
                                            >
                                              Veg&Nonveg
                                            </Index.MenuItem>
                                          </Index.Select>
                                        </Index.FormControl>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Diet
                                  </Index.FormHelperText>
                                  <Index.Box className="edit_profile_field">
                                    <Index.Box className="form-group from_login_details">
                                      <Index.Box className="dropdown-box">
                                        <Index.FormControl className="form-control">
                                          <Index.Select
                                            className="dropdown-select "
                                            name="diet"
                                            defaultValue={
                                              values ? values?.diet : ""
                                            }
                                            // onChange={
                                            //   handleChangedropdown
                                            // }
                                            onChange={(e) => {
                                              setFieldValue(
                                                "diet",
                                                e.target.value
                                              );
                                            }}
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                          >
                                            <Index.MenuItem
                                              value="Healthy"
                                              className="menuitem"
                                            >
                                              Healthy
                                            </Index.MenuItem>
                                            <Index.MenuItem
                                              value="Normal"
                                              className="menuitem"
                                            >
                                              Normal
                                            </Index.MenuItem>
                                            <Index.MenuItem
                                              value="Junky"
                                              className="menuitem"
                                            >
                                              Junky
                                            </Index.MenuItem>
                                          </Index.Select>
                                        </Index.FormControl>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Select your fitness goal
                                  </Index.FormHelperText>
                                  <Index.Box className="edit_profile_field">
                                    <Index.Box className="form-group from_login_details">
                                      <Index.Box className="dropdown-box">
                                        <Index.FormControl className="form-control">
                                          {console.log(values?.goal, 1712)}
                                          {values?.goal && (
                                            <Index.Select
                                              className="dropdown-select "
                                              name="goal"
                                              multiple
                                              defaultValue={
                                                values ? values?.goal : ""
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "goal",
                                                  e.target.value
                                                );
                                              }}
                                              displayEmpty
                                              inputProps={{
                                                "aria-label": "Without label",
                                              }}
                                            >
                                              <Index.MenuItem
                                                value="Lose Weight"
                                                className="menuitem"
                                              >
                                                Lose Weight
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="Maintain Weight"
                                                className="menuitem"
                                              >
                                                Maintain Weight
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="Gain Weight"
                                                className="menuitem"
                                              >
                                                Gain Weight
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="Gain Muscle"
                                                className="menuitem"
                                              >
                                                Gain Muscle
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="Increase Energy"
                                                className="menuitem"
                                              >
                                                Increase Energy
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="Fit & Active"
                                                className="menuitem"
                                              >
                                                Fit & Active
                                              </Index.MenuItem>
                                              <Index.MenuItem
                                                value="Reduce Stress"
                                                className="menuitem"
                                              >
                                                Reduce Stress
                                              </Index.MenuItem>
                                            </Index.Select>
                                          )}
                                        </Index.FormControl>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Select your health challenges
                                  </Index.FormHelperText>
                                  <Index.Box className="edit_profile_field">
                                    <Index.Box className="form-group from_login_details">
                                      <Index.Box className="dropdown-box">
                                        <Index.FormControl className="form-control">
                                          <Index.Select
                                            className="dropdown-select "
                                            multiple
                                            value={values?.healthChallanges}
                                            defaultValue={
                                              values?.healthChallanges
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                "healthChallanges",
                                                e.target.value
                                              );
                                            }}
                                          >
                                            {healthChallangesOptions.map(
                                              (item) => (
                                                <Index.MenuItem
                                                  value={item}
                                                  className="menuitem"
                                                >
                                                  {item}
                                                </Index.MenuItem>
                                              )
                                            )}
                                          </Index.Select>
                                          {/* <Index.Select
                                                                className="goal"
                                                                value={
                                                                  values?.goal
                                                                }
                                                                defaultValue={
                                                                  values?.goal
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const formatedData =
                                                                    {
                                                                     goal:values?.goal
                                                                    };
                                                                    console.log(
                                                                      formatedData,
                                                                      "goalData"
                                                                    );
                                                                  setFieldValue(
                                                                    "goal",
                                                                    formatedData
                                                                  );
                                                                }}
                                                                
                                                              >
                                                                { goalData.map(
                                                                  (item) => (
                                                                    <Index.MenuItem
                                                                      value={
                                                                        item
                                                                      }
                                                                      className="menuitem"
                                                                    >
                                                                      {item}
                                                                    </Index.MenuItem>
                                                                  )
                                                                )}
                                                              </Index.Select> */}
                                        </Index.FormControl>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Select one of the following identification
                                    documents:
                                  </Index.FormHelperText>
                                  <Index.Box className="edit_profile_field">
                                    <Index.Box className="form-group from_login_details">
                                      <Index.Box className="dropdown-box">
                                        <Index.FormControl className="form-control">
                                          <Index.Select
                                            className="dropdown-select "
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            defaultValue={
                                              values ? values?.kycType : ""
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                "kycType",
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <Index.MenuItem
                                              value="Passport"
                                              className="menuitem"
                                            >
                                              Passport
                                            </Index.MenuItem>
                                            <Index.MenuItem
                                              value="Driving License"
                                              className="menuitem"
                                            >
                                              Driving License
                                            </Index.MenuItem>
                                            <Index.MenuItem
                                              value="Government ID"
                                              className="menuitem"
                                            >
                                              Government ID
                                            </Index.MenuItem>
                                          </Index.Select>
                                        </Index.FormControl>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Upload Documnet
                                  </Index.FormHelperText>
                                  <Index.Box className="file-upload-btn-main-user">
                                    <Index.Button
                                      variant="contained"
                                      component="label"
                                      className="file-upload-btn-user"
                                    >
                                      <input
                                        // hidden
                                        accept="image/*"
                                        multiple
                                        type="file"
                                        name="kycDocument"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "kycDocument",
                                            e.target.files[0]
                                          );

                                      // setFieldValue("sendKycDocument",1)
                                          setSelectedImage(e.target.files[0]);
                                        }}
                                      />
                                    </Index.Button>
                                    <Index.Box className="button-text-main">
                                      <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="title-upload-main"
                                      >
                                        <img
                                          src={Index.Png.uploadfile}
                                          className="upload-img"
                                        />{" "}
                                        Upload Document
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              {errors.kycDocument && (
                                <Index.FormHelperText error>
                                  {errors.kycDocument}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                              {console.log(values?.kycDocument,"selectedImage",1)}
                            {/* {values?.kycDocument && (
                              <img
                                style={{ width: 100, height: 100 }}
                                src={`http://35.177.56.74:3024/api/images/${values?.kycDocument}`}
                              />
                            )} */}
                            {console.log(process.env.REACT_APP_ASSET_URL+values?.kycDocument, "url",2)}
                            {selectedImage ? (
                              <div className="user-edit-show-document">
                                <img
                                  src={URL.createObjectURL(selectedImage)}
                                />{" "}
                                <Index.Box className="refresh-btn-main close-share-btn">
                                  <Index.Button
                                    className="refresh-btn"
                                    disableRipple
                                    onClick={() => {
                                      setSelectedImage("")
                                      setFieldValue("kycDocument", "");
                                    }}
                                  >
                                    <img
                                      src={Index.Png.close}
                                      className="close-img"
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </div>
                            ) : values?.kycDocument ? (
                              <div className="user-edit-show-document">
                                <img
                                  src={process.env.REACT_APP_ASSET_URL+values?.kycDocument}
                                />
                                <Index.Box className="refresh-btn-main close-share-btn">
                                  <Index.Button
                                    className="refresh-btn"
                                    disableRipple
                                    onClick={() => {
                                      setSelectedImage("")
                                      setFieldValue("kycDocument", "")
                                      setFieldValue("sendKycDocument",1)
                                    }}
                                  >
                                    <img
                                      src={Index.Png.close}
                                      className="close-img"
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </div>
                            ) : (
                              <></>
                            )}

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column stepper-signup-column mb-10px"
                            >
                              <Index.Box className="step-signup-list">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Unique Id No.
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    type="number"
                                    className="form-control custom-control"
                                    name="uniqueIdNo"
                                    onBlur={handleBlur}
                                    value={values?.uniqueIdNo}
                                    onChange={handleChange}
                                    helperText={
                                      touched.uniqueIdNo && errors.uniqueIdNo
                                    }
                                    error={Boolean(
                                      errors.uniqueIdNo && touched.uniqueIdNo
                                    )}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                              className="grid-column  user-grid-column"
                            >
                              <Index.Box className="submit-btn-main border-btn-main">
                                <Index.Button
                                  className="submit-user-btn border-btn"
                                  disableRipple
                                  type="submit"
                                >
                                  Update
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </form>
                      )}
                    </Formik>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    );
  }
}
