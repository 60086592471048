import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Menu,
  Grid,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import Svg from "../assets/Svg";
import Pdf from "../assets/Pdf";
import Png from "../assets/Png";
import Video from "../assets/Video";
import Sidebar from "./defaulLayout/admin/Sidebar";
import Header from "./defaulLayout/admin/Header";
import PaidLable from "./common/PaidLable";
import FailedLable from "./common/FailedLable";
import PendingLable from "./common/PendingLable";
import PersonIcon from "@mui/icons-material/Person";
import PrimaryButton from "./common/PrimaryButton";
import AuthVideo from "./common/AuthVideo";
import GreyButton from "./common/GreyButton";
import TextButton from "./common/TextButton";
import PrimaryBorderButton from "./common/PrimaryBorderButton";
import OutlineButton from "./common/OutlineButton";
import Dialog from "@mui/material/Dialog";
import {
  validateName,
  validatePassword,
  validateEmail,
  validateConfirmPassword,
} from "../component/common/AuthValidation";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SettingsIcon from '@mui/icons-material/Settings';
import PercentIcon from '@mui/icons-material/Percent';
import UserHeader from "./user/defaulLayout/UserHeader";
import RefferalProgram from "./user/pages/userDashboard/RefferalProgram";
import BlueBorderButton from "./common/BlueBorderButton";
import UserSidebar from "./user/defaulLayout/UserSidebar";
import FitrizerCard from "./user/pages/userDashboard/FitrizerCard";
import DataPicker from "./user/pages/userDashboard/DataPicker";
import Loader from "./common/Loader";
import AuthFooter from "./defaulLayout/user/AuthFooter";
import WalletIcon from '@mui/icons-material/Wallet';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
export default {
  AddToHomeScreenIcon,
  WalletIcon,
  PercentIcon,
  SettingsIcon,
  AdapterDayjs,
  LocalizationProvider,
  DesktopDatePicker,
  Stack,
  DialogTitle,
  DialogActions,
  MoreVertIcon,
  AddIcon,
  DataGrid,
  Dialog,
  ExpandMoreIcon,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  PersonIcon,
  validateName,
  OutlineButton,
  validatePassword,
  validateEmail,
  validateConfirmPassword,
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  Link,
  List,
  ListItem,
  Svg,
  Png,
  Video,
  Sidebar,
  Header,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  PaidLable,
  FailedLable,
  PendingLable,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Menu,
  AuthVideo,
  PrimaryButton,
  GreyButton,
  TextButton,
  PrimaryBorderButton,
  Grid,
  UserHeader,
  RefferalProgram,
  BlueBorderButton,
  UserSidebar,
  FitrizerCard,
  DataPicker,
  Loader,
  AuthFooter,
  Pdf
};
