import axios from "axios";


// const API_ENDPOINT = "http://localhost:3024/api/";
// const API_ENDPOINT = "http://35.177.56.74:3024/api/";
const API_ENDPOINT = process.env.REACT_APP_API_URL;
// const API_ENDPOINT = "https://backend.fitrizer.com/api";
// const API_ENDPOINT = "http://localhost:3024/api"

const DataService = axios.create({
baseURL: API_ENDPOINT,
});

export { DataService, API_ENDPOINT };
