import React from "react";
import { useLocation } from "react-router-dom";
import Index from "../../Index";

export default function Sidebar() {
  const pathname = useLocation();
  const removeClass = () => {
    var element = document.getElementById("admin-sidebar");
    element.classList.remove("active");
    var body = document.getElementsByTagName("body");
    body[0].classList.remove("body-overflow");
  };
  return (
    <>
      <Index.Box className="admin-sidebar-main scrollbar" id={"admin-sidebar"}>
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <img
              src={Index.Png.lightLogo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            
            <Index.Button onClick={removeClass} className="cancel-mobile-menu-icon">
              <img
                src={Index.Png.cancelicon}
                className="admin-sidebar-close"
                alt="logo"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/user"
                  className={
                    pathname?.pathname === "/admin/user" 
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.usericon}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  User List
                </Index.Link>
              </Index.ListItem>
              {/* roadMap */}
              {/* <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/road-map"
                  className={
                    pathname?.pathname === "/admin/road-map" 
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons">
                    <img
                      src={Index.Svg.track}
                      className="admin-sidebar-icon"
                    />
                  </Index.Box>
                  RoadMap List
                </Index.Link>
              </Index.ListItem> */}

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/general-setting"
                  className={
                    pathname?.pathname === "/admin/general-setting" 
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons">
                    {/* <img
                      src={Index.Svg.settings}
                      className="admin-sidebar-icon"
                    /> */}
                    <Index.SettingsIcon/>
                  </Index.Box>
                  General Settings
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/wallet"
                  className={
                    pathname?.pathname === "/admin/wallet" 
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons">
                   
                    <Index.WalletIcon/>
                  </Index.Box>
                  Transaction History
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/contact-request"
                  className={
                    pathname?.pathname === "/admin/contact-request" 
                      ? "admin-sidebar-link active"
                      : "dashboard-header"
                  }
                  onClick={removeClass}
                >
                  <Index.Box className="admin-sidebar-icons">
                   
                    <Index.AddToHomeScreenIcon/>
                  </Index.Box>
                  Contact Request
                </Index.Link>
              </Index.ListItem>
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
