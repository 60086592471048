import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import * as Yup from "yup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { InputAdornment, OutlinedInput, TextField } from "@mui/material";
import { getCountryList } from "../../../../redux/features/slices/User/UserService";
import {
  getUserProfile,
  updateUserProfile,
} from "../../../../redux/features/slices/User/UserService";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { toast } from "react-toastify";
import moment from "moment";
import { getUserData } from "../../../../redux/features/slices/Admin/AdminSlice";

import { setCountryList } from "../../../../redux/features/slices/Admin/AdminSlice";

import Loader from "../../../../component/common/Loader";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fitOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10];
  const inchOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  const gramOption = [0, 100, 200, 300, 400, 500, 600, 700, 800, 900];

  // weight kg
  const kgOption = [];

  for (let i = 10; i <= 500; i++) {
    kgOption.push(i);
  }

  let cmOptions = [...new Array(351)].map((each, index) => index);
  cmOptions = cmOptions?.filter((item) => {
    return item > 74;
  });

  // lbs
  const lbsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const ozOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [modalOpenProfileCompletion, setModalOpenProfileCompletion] =
    useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => {
    navigate("/user/dashboard");
    setModalOpen(false);
  };

  const [countryCode, setCountryCode] = useState("+91");
  const [selectedCountry, setSelectedCountry] = useState();

  const [profileData, setProfileData] = useState({});
  const [age, setAge] = useState("");
  const [activeStep, setActiveStep] = useState(1);

  const [heightUnit, setHeightUnit] = useState(
    profileData?.height?.unit ? profileData?.height?.unit : "ft"
  );

  const [weightUnit, setWeightUnit] = useState(
    profileData?.weight?.unit ? profileData?.weight?.unit : "lbs"
  );

  const countryList = useSelector((store) => store.admin.countryList);

  useEffect(() => {
    const filteredCountry = countryList?.filter(
      (ele) => ele?._id == selectedCountry
    );
    if (filteredCountry[0]) {
      setCountryCode(filteredCountry?.[0]?.dial_code);
    }
  }, [selectedCountry]);

  const getCountryOptionsList = () => {
    try {
      dispatch(getCountryList()).then((response) => {
        if (response.payload) {
          dispatch(setCountryList(response?.payload?.data));
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getUserProfileData = () => {
    dispatch(getUserProfile()).then((response) => {
      if (response?.payload?.data) {
        setProfileData(response?.payload?.data);
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const getUserProfileAPICall = () => {
    dispatch(getUserProfile()).then((response) => {
      if (response?.payload?.data) {
        setProfileData(response?.payload?.data);
        //Update in redux store
        dispatch(getUserData(response?.payload?.data));

        if (
          response?.payload?.data?.isProfileUpdated != 1 &&
          response?.payload?.data?.stepCompleted
        ) {
          setModalOpenProfileCompletion(false);
          if (response?.payload?.data?.stepCompleted < 5) {
            setActiveStep(response?.payload?.data?.stepCompleted + 1);
          } else {
            setActiveStep(1);
          }
        }
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  useEffect(() => {
    getUserProfileAPICall();
    getCountryOptionsList();
  }, []);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const handleBackClick = (previousStep) => {
    setActiveStep(previousStep);
  };

  /* Step 1 process start */
  let initialValuesStep1 = {
    country: profileData?.country
      ? profileData?.country
      : "647ed3f7fca806287ecc7037",
    countryCode: profileData?.countryCode
      ? profileData?.countryCode
      : countryCode,
    firstName: profileData?.firstName,
    userName: profileData?.userName,
    email: profileData?.email,
    phoneNumber: profileData?.phoneNumber,
  };

  //Validation schema
  const textAndSpaceOnly = (value) =>
    /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value);
  const charSpaceNumber = (value) => /^[a-zA-Z0-9_.-]*$/.test(value);

  let validationSchemaStep1 = "";
  if (profileData?.isProfileUpdated == 0) {
    validationSchemaStep1 = Yup.object().shape({
      firstName: Yup.string()
        .required("Fullname is required")
        .test("firstName", "Fullname allows only characters", textAndSpaceOnly)
        .min(5, "Please enter 5 character at least")
        .max(20, "Fullname allows maximum length 20"),
      userName: Yup.string()
        .required("Username is required")
        .test(
          "userName",
          "Username allows only characters and number",
          charSpaceNumber
        )
        .min(5, "Please enter 5 character at least")
        .max(20, "Username allows maximum length 20"),
      phoneNumber: Yup.string()
        .required("Mobile number is required")
        .min(10, "Mobile number should be 10 digits")
        .max(10, "Mobile number should be 10 digits"),
      country: Yup.string().required("Country is required"),
    });
  } else {
    validationSchemaStep1 = Yup.object().shape({
      firstName: Yup.string()
        .required("Fullname is required")
        .test(
          "firstName",
          "Fullname allows only characters and space between",
          textAndSpaceOnly
        )
        .max(20, "Fullname allows maximum length 20"),
      userName: Yup.string()
        .required("Username is required")
        .test(
          "userName",
          "Username allows only characters and number",
          charSpaceNumber
        )
        .max(20, "Username allows maximum length 20"),
      phoneNumber: Yup.string()
        .required("Mobile number is required")
        .min(10, "Mobile Number should be 10 digits")
        .max(10, "Mobile Number should be 10 digits"),
      country: Yup.string().required("Country is required"),
    });
  }

  // Step1 submit
  const handleFormSubmitStep1 = async (values) => {
    try {
      const data = new FormData();
      data.append("firstName", values?.firstName);
      data.append("userName", values?.userName);
      data.append("country", values?.country);
      if (values?.phoneNumber) {
        data.append("phoneNumber", values?.phoneNumber);
      }
      data.append("countryCode", values?.countryCode);

      if (
        values.firstName != "" &&
        values.userName != "" &&
        values.phoneNumber != "" &&
        values.phoneNumber != undefined &&
        values.country != "" &&
        profileData?.isProfileUpdated == 0 &&
        profileData?.stepCompleted == 0
      ) {
        data.append("stepCompleted", 1);
      }

      dispatch(updateUserProfile(data)).then((response) => {
        if (response?.payload?.data) {
          setActiveStep(2);
          getUserProfileData();
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  /* Step 1 process end*/

  /* Step 2 process start */
  let initialValuesStep2 = {
    dateOfBirth: profileData?.dateOfBirth
      ? moment(profileData?.dateOfBirth).format("MM/DD/YYYY")
      : profileData?.dateOfBirth,
    gender: profileData?.gender,
    age: profileData?.age,
    height: {
      unit: profileData?.height?.unit ? profileData?.height?.unit : heightUnit,
      value: profileData?.height?.value,
      cm: profileData?.height?.cm,
      mm: profileData?.height?.mm,
      fit: profileData?.height?.fit,
      inch: profileData?.height?.inch,
    },
    // weight change
    weight: {
      unit: profileData?.weight?.unit ? profileData?.weight?.unit : weightUnit,
      value: profileData?.weight?.value,
      lbs: profileData?.weight?.lbs,
      oz: profileData?.weight?.oz,
      kilo: profileData?.weight?.kilo,
      gram: profileData?.weight?.gram,
    },
    routine: profileData?.routine,
    foodPreference: profileData?.foodPreference,
    diet: profileData?.diet,
  };

  //Validation schema
  // let validationSchemaStep2 = "";
  // if (profileData?.isProfileUpdated == 0) {
  //   validationSchemaStep2 = Yup.object().shape({
  //     age: Yup.string().nullable().max(3, "Age allows only up to 3 digits"),
  //   });
  // } else {
  let validationSchemaStep2 = Yup.object().shape({
    dateOfBirth: Yup.string().required("Date of birth is required"),
    gender: Yup.string().required("Gender is required"),
    age: Yup.string()
      .required("Age is required")
      .max(3, "Age allows only up to 3 digits"),
    height: Yup.object().shape({
      unit: Yup.string().required("Unit is required"),
      cm: Yup.string().test("cmRequired", "CM is required", function (value) {
        const { unit } = this.parent;
        if (unit === "cms") {
          return value && value.trim() !== "";
        }
        return true;
      }),
      mm: Yup.string().test("cmRequired", "MM is required", function (value) {
        const { unit } = this.parent;
        if (unit === "cms") {
          return value && value.trim() !== "";
        }
        return true;
      }),
      fit: Yup.string().test("cmRequired", "Fit is required", function (value) {
        const { unit } = this.parent;
        if (unit === "ft") {
          return value && value.trim() !== "";
        }
        return true;
      }),
      inch: Yup.string().test(
        "cmRequired",
        "Inches is required",
        function (value) {
          const { unit } = this.parent;
          if (unit === "ft") {
            return value && value.trim() !== "";
          }
          return true;
        }
      ),
    }),
    weight: Yup.object().shape({
      unit: Yup.string().required("Unit is required"),
      lbs: Yup.string().test(
        "lbsRequired",
        "LBS is required",
        function (value) {
          const { unit } = this.parent;
          if (unit === "lbs") {
            return value && value.trim() !== "";
          }
          return true;
        }
      ),
      oz: Yup.string().test("ozRequired", "OZ is required", function (value) {
        const { unit } = this.parent;
        if (unit === "lbs") {
          return value && value.trim() !== "";
        }
        return true;
      }),
      kilo: Yup.string().test("kgRequired", "KG is required", function (value) {
        const { unit } = this.parent;
        if (unit === "kg") {
          return value && value.trim() !== "";
        }
        return true;
      }),
      gram: Yup.string().test(
        "gramRequired",
        "Gram is required",
        function (value) {
          const { unit } = this.parent;
          if (unit === "kg") {
            return value && value.trim() !== "";
          }
          return true;
        }
      ),
    }),
    routine: Yup.string().required("Routine is required"),
    foodPreference: Yup.string().required("Food preference is required"),
    diet: Yup.string().required("Diet is required"),
  });
  // }

  const handleFormSubmitStep2 = async (values) => {

    try {
      if (profileData?.isProfileUpdated == 0) {
        // if (
        //   values.dateOfBirth &&
        //   values.gender &&
        //   values.age != "" &&
        //   values.routine &&
        //   values.foodPreference &&
        //   values.diet &&
        //   ((values?.height?.unit == "ft" && values?.height?.fit) ||
        //     (values?.height?.unit == "cms" && values?.height?.cm)) &&
        //   ((values?.weight?.unit == "lbs" &&
        //     values?.weight?.lbs != "" &&
        //     values?.weight?.oz != "") ||
        //     (values?.weight?.unit == "kg" &&
        //       values?.weight?.kilo != "" &&
        //       values?.weight?.gram != ""))
        // ) {
          if (profileData?.stepCompleted == 1) {
            values.stepCompleted = 2;
          }
        // } else {
        //   if (profileData?.stepCompleted < 1) {
        //     values.stepCompleted = profileData?.stepCompleted;
        //   } else {
        //     values.stepCompleted = 1;
        //   }
        // }
      }

      dispatch(updateUserProfile(values)).then((response) => {
        if (response?.payload?.data) {
          setActiveStep(3);
          getUserProfileData();
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  /* Step 2 process end */

  /* Step 3 process start */
  let initialValuesStep3 = {
    goal: profileData?.goal,
    // stepCompleted : 3
  };

  // let validationSchemaStep3 = "";
  // if (profileData?.isProfileUpdated == 0) {
  //   validationSchemaStep3 = Yup.object().shape({
  //     goaltest: Yup.string().nullable(),
  //   });
  // } else {
  let validationSchemaStep3 = Yup.object().shape({
    goal: Yup.array().min(1, "Select at least one goal"),
  });
  // }

  const handleFormSubmitStep3 = async (values) => {
    try {
      const data = new FormData();
      if (values?.goal?.length == 1 && values?.goal?.[0] == undefined) {
        data.append("goal", "");
      } else {
        if (values?.goal?.length == 0) {
          data.append("goal", "");
        } else {
          values?.goal?.forEach((item) =>
            item != "" && item != undefined ? data.append("goal[]", item) : ""
          );
        }
      }

      if (profileData?.isProfileUpdated == 0) {
        if (values?.goal?.length == 1 && values?.goal?.[0] == undefined) {
          if (profileData?.stepCompleted < 2) {
            data.append("stepCompleted", profileData?.stepCompleted);
          } else {
            data.append("stepCompleted", 2);
          }
        } else {
          if (values?.goal?.length == 0) {
            if (profileData?.stepCompleted < 2) {
              data.append("stepCompleted", profileData?.stepCompleted);
            } else {
              data.append("stepCompleted", 2);
            }
          } else {
            if (profileData?.stepCompleted >= 2) {
              data.append("stepCompleted", 3);
            }
          }
        }
      }

      dispatch(updateUserProfile(data)).then((response) => {
        if (response?.payload?.data) {
          setActiveStep(4);
          getUserProfileData();
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  /* Step 3 process end */

  /* Step 4 process start */
  let initialValuesStep4 = {
    healthChallanges: profileData?.healthChallanges,
    // stepCompleted : 4
  };

  // let validationSchemaStep4 = "";
  // if (profileData?.isProfileUpdated == 0) {
  //   validationSchemaStep4 = Yup.object().shape({
  //     healthChallangestest: Yup.string().nullable(),
  //   });
  // } else {
  let validationSchemaStep4 = Yup.object().shape({
    healthChallanges: Yup.array().min(
      1,
      "Select at least one Health Challanges"
    ),
  });
  // }

  const handleFormSubmitStep4 = async (values) => {
    try {
      const data = new FormData();
      if (
        values?.healthChallanges?.length == 1 &&
        values?.healthChallanges?.[0] == undefined
      ) {
        data.append("healthChallanges", "");
      } else {
        if (values?.healthChallanges?.length == 0) {
          data.append("healthChallanges", "");
        } else {
          values?.healthChallanges?.forEach((item) =>
            //   data.append("healthChallanges[]", item)
            // );
            item != "" && item != undefined
              ? data.append("healthChallanges[]", item)
              : ""
          );
        }
      }
      if (profileData?.isProfileUpdated == 0) {
        /* */
        if (
          values?.healthChallanges?.length == 1 &&
          values?.healthChallanges?.[0] == undefined
        ) {
          if (profileData?.stepCompleted < 3) {
            data.append("stepCompleted", profileData?.stepCompleted);
          } else {
            data.append("stepCompleted", 3);
          }
        } else {
          if (values?.healthChallanges?.length == 0) {
            if (profileData?.stepCompleted < 3) {
              data.append("stepCompleted", profileData?.stepCompleted);
            } else {
              data.append("stepCompleted", 3);
            }
          } else {
            if (profileData?.stepCompleted >= 3) {
              data.append("stepCompleted", 4);
            }
          }
        }
      }

      dispatch(updateUserProfile(data)).then((response) => {
        if (response?.payload?.data) {
          setActiveStep(5);
          getUserProfileData();
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  /* Step 4 process end */

  /* Step 5 process start */
  let initialValuesStep5 = {
    uniqueIdNo: profileData?.uniqueIdNo,
    kycType: profileData?.kyc?.kycType,
    kycDocument: "",
    // stepCompleted : 5
  };

  let validationSchemaStep5 = "";
  if (profileData?.isProfileUpdated == 0) {
    validationSchemaStep5 = Yup.object().shape({
      kycType: Yup.string().required(
        "Identification document type is required"
      ),
      kycDocument: Yup.mixed()
        .required("Document is required")
        .test(
          "FILE_FORMAT",
          "allow only jpg,jpeg,png,pdf file",
          (value) =>
            !value ||
            (["jpg", "jpeg", "png", "pdf"].includes(
              value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
            ) &&
              [
                "image/jpg",
                "image/jpeg",
                "image/png",
                "application/pdf",
              ].includes(value?.type))
        )
        .test(
          "fileSize",
          "File size too large, max file size is 2.048 Mb",
          (file) => {
            if (file) {
              return file.size <= 2048000;
            } else {
              return true;
            }
          }
        ),
      uniqueIdNo: Yup.string()
        .required("Unique identification number is required")
        .max(15, "Unique identification number allows maximum 15 digits"),
    });
  } else {
    validationSchemaStep5 = Yup.object().shape({
      kycType: Yup.string().required(
        "Identification document type is required"
      ),
      uniqueIdNo: Yup.string()
        .required("Unique identification number is required")
        .max(15, "Unique identification number allows maximum 15 digits"),
      kycDocument: Yup.mixed()
        .nullable()
        .notRequired()
        .test(
          "FILE_FORMAT",
          "allow only jpg,jpeg,png,pdf file",
          (value) =>
            !value ||
            (["jpg", "jpeg", "png", "pdf"].includes(
              value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
            ) &&
              [
                "image/jpg",
                "image/jpeg",
                "image/png",
                "application/pdf",
              ].includes(value?.type))
        )
        .test(
          "fileSize",
          "File size too large, max file size is 2.048 Mb",
          (file) => {
            if (file) {
              return file.size <= 2048000;
            } else {
              return true;
            }
          }
        ),
    });
  }

  const handleFormSubmitStep5 = async (values) => {
    try {
      const data = new FormData();
      if (values?.uniqueIdNo) {
        data.append("uniqueIdNo", values?.uniqueIdNo);
      }
      if (values?.kycType) {
        data.append("kycType", values?.kycType);
      }
      if (values?.kycDocument) {
        data.append("image", values?.kycDocument);
      }

      if (profileData?.isProfileUpdated == 0) {
        if (
          values?.uniqueIdNo != undefined &&
          values?.uniqueIdNo != "" &&
          values?.kycType != undefined &&
          values?.kycType != "" &&
          ((values?.kycDocument != undefined && values?.kycDocument != "") ||
            (profileData?.kyc?.kycDocument != "" &&
              profileData?.kyc?.kycDocument != undefined))
        ) {
          if (profileData?.stepCompleted >= 4) {
            data.append("stepCompleted", 5);
          }
        } else {
          if (profileData?.stepCompleted < 4) {
            data.append("stepCompleted", profileData?.stepCompleted);
          } else {
            data.append("stepCompleted", 4);
          }
        }
      }

      dispatch(updateUserProfile(data)).then((response) => {
        if (response?.payload?.data) {
          if (response?.payload?.data?.isProfileUpdated == 0) {
            setModalOpenProfileCompletion(true);
          } else {
            setActiveStep(1);
            getUserProfileData();
            navigate("/user/profile");
            setModalOpen(true);
          }
        } else {
          toast.error("Something went wrong");
        }
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  /* Step 5 process end */

  if (!profileData._id) {
    return <Loader></Loader>;
  } else {
    return (
      <>
        <Index.Box className="userdashbord-main">
          <Index.UserHeader />
        </Index.Box>

        <Index.Box className="profile-main-content">
          <Index.Box className="main-userdashboard-content">
            <Index.Box className="container">
              <Index.Box className="user-dashboard-row">
                <Index.Box className="userdash-col1">
                  <Index.Box className="sidebar-main-user-component">
                    <Index.UserSidebar />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="userdash-col2">
                  <Index.FitrizerCard />

                  <Index.Box className="profile-tab-list-main">
                    <Index.Box className="profile-tab-contnet">
                      <Index.Box className="profile-tab-box">
                        <Index.Box className="profile-stepper-amin">
                          <Index.Box
                            className={`profile-step-details ${
                              activeStep >= 1 ? "active" : ""
                            }`}
                          >
                            <Index.Box className="steper-box-profile">
                              1
                            </Index.Box>
                            <Index.Box className="profile-steplabel-main">
                              Sign Up
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            className={`profile-step-details ${
                              activeStep >= 2 ? "active" : ""
                            }`}
                          >
                            <Index.Box className="steper-box-profile">
                              2
                            </Index.Box>
                            <Index.Box className="profile-steplabel-main">
                              Account Completion Details
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            className={`profile-step-details ${
                              activeStep >= 3 ? "active" : ""
                            }`}
                          >
                            <Index.Box className="steper-box-profile">
                              3
                            </Index.Box>
                            <Index.Box className="profile-steplabel-main">
                              Fitness Goal
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            className={`profile-step-details ${
                              activeStep >= 4 ? "active" : ""
                            }`}
                          >
                            <Index.Box className="steper-box-profile">
                              4
                            </Index.Box>
                            <Index.Box className="profile-steplabel-main">
                              Health Challenges
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            className={`profile-step-details ${
                              activeStep >= 5 ? "active" : ""
                            }`}
                          >
                            <Index.Box className="steper-box-profile">
                              5
                            </Index.Box>
                            <Index.Box className="profile-steplabel-main">
                              KYC Verification
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="step-details-content">
                          {/* step1 start */}

                          <Index.Box
                            className={`step-one-details-content ${
                              activeStep == 1
                                ? "step-block"
                                : "display-none-step"
                            }`}
                          >
                            <Formik
                              enableReinitialize
                              onSubmit={handleFormSubmitStep1}
                              initialValues={initialValuesStep1}
                              validationSchema={validationSchemaStep1}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  <Index.Box className="step-one-signup-details">
                                    <Index.Box className="signup-list-content">
                                      <Index.Box
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                                        className="stepper-signup-grid"
                                        sx={{ margin: 0, alignItems: "center" }}
                                      >
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Full Name
                                              </Index.FormHelperText>
                                              <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control custom-control"
                                                name="firstName"
                                                value={values?.firstName}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                helperText={
                                                  touched.firstName
                                                    ? errors.firstName
                                                    : undefined
                                                }
                                                error={Boolean(
                                                  errors.firstName
                                                    ? touched.firstName
                                                    : undefined
                                                )}
                                              />
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                User Name
                                              </Index.FormHelperText>
                                              <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control custom-control"
                                                name="userName"
                                                value={values?.userName}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                helperText={
                                                  touched.userName
                                                    ? errors.userName
                                                    : undefined
                                                }
                                                error={Boolean(
                                                  errors.userName
                                                    ? touched.userName
                                                    : undefined
                                                )}
                                              />
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Email
                                              </Index.FormHelperText>
                                              <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control custom-control"
                                                name="email"
                                                disabled
                                                value={values?.email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                              />
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>

                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.Box className="input-box custom-form-details login-input">
                                                <Index.Box className="form-group custom-group">
                                                  <Index.FormHelperText className="form-label">
                                                    Country
                                                  </Index.FormHelperText>

                                                  <Index.Box className="dropdown-box">
                                                    {values?.country != "" ? (
                                                      <Index.FormControl className="form-control">
                                                        <Index.Select
                                                          className="dropdown-select "
                                                          name="country"
                                                          defaultValue={
                                                            values?.country
                                                          }
                                                          value={
                                                            values?.country
                                                          }
                                                          onChange={(e) => {
                                                            setFieldValue(
                                                              "country",
                                                              e.target.value
                                                            );
                                                            const filteredCountry =
                                                              countryList?.filter(
                                                                (ele) =>
                                                                  ele?._id ==
                                                                  e.target.value
                                                              );
                                                            if (
                                                              filteredCountry[0]
                                                            ) {
                                                              setFieldValue(
                                                                "countryCode",
                                                                filteredCountry[0]
                                                                  ?.dial_code
                                                              );
                                                            }
                                                          }}
                                                          displayEmpty
                                                          inputProps={{
                                                            "aria-label":
                                                              "Without label",
                                                          }}
                                                        >
                                                          {countryList?.map(
                                                            () => {
                                                              <Index.MenuItem
                                                                value={10}
                                                              >
                                                                Ten
                                                              </Index.MenuItem>;
                                                            }
                                                          )}
                                                          {countryList?.map(
                                                            (item, index) => (
                                                              <Index.MenuItem
                                                                value={
                                                                  item?._id
                                                                }
                                                                key={item?._id}
                                                              >
                                                                {item?.name}
                                                              </Index.MenuItem>
                                                            )
                                                          )}
                                                        </Index.Select>
                                                      </Index.FormControl>
                                                    ) : (
                                                      <Index.FormControl className="form-control">
                                                        <Index.Select
                                                          className="dropdown-select "
                                                          name="country"
                                                          value={
                                                            values?.country
                                                          }
                                                          onChange={(e) => {
                                                            setFieldValue(
                                                              "country",
                                                              e.target.value
                                                            );
                                                            setSelectedCountry(
                                                              e.target.value
                                                            );
                                                          }}
                                                          displayEmpty
                                                          inputProps={{
                                                            "aria-label":
                                                              "Without label",
                                                          }}
                                                        >
                                                          {countryList?.map(
                                                            () => {
                                                              <Index.MenuItem
                                                                value={10}
                                                              >
                                                                Ten
                                                              </Index.MenuItem>;
                                                            }
                                                          )}
                                                          {countryList?.map(
                                                            (item, index) => (
                                                              <Index.MenuItem
                                                                value={
                                                                  item?._id
                                                                }
                                                                key={item?._id}
                                                              >
                                                                {item?.name}
                                                              </Index.MenuItem>
                                                            )
                                                          )}
                                                        </Index.Select>
                                                      </Index.FormControl>
                                                    )}
                                                  </Index.Box>
                                                </Index.Box>
                                                {touched.country &&
                                                  errors.country && (
                                                    <Index.FormHelperText error>
                                                      {errors.country}
                                                    </Index.FormHelperText>
                                                  )}
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>

                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Mobile Number
                                              </Index.FormHelperText>
                                              <Index.Box className="input-details-main num-main-input">
                                                <Index.Box className="form-group pass_group_main pass-num-main">
                                                  <OutlinedInput
                                                    fullWidth
                                                    id="fullWidth"
                                                    type="number"
                                                    className="form-control custom-control"
                                                    name="phoneNumber"
                                                    onBlur={handleBlur}
                                                    value={values?.phoneNumber}
                                                    // onChange={handleChange}
                                                    onChange={(e) => {
                                                      if (
                                                        e.target.value.toString()
                                                          .length <= 10
                                                      ) {
                                                        setFieldValue(
                                                          "phoneNumber",
                                                          e.target.value
                                                        );
                                                      }
                                                    }}
                                                    startAdornment={
                                                      <InputAdornment
                                                        position="start"
                                                        className="pass_position num-position"
                                                      >
                                                        {values?.countryCode}
                                                      </InputAdornment>
                                                    }
                                                    helperText={
                                                      touched.phoneNumber &&
                                                      errors.phoneNumber
                                                    }
                                                    error={Boolean(
                                                      errors.phoneNumber &&
                                                        touched.phoneNumber
                                                    )}
                                                  />

                                                  {touched.phoneNumber &&
                                                    errors.phoneNumber && (
                                                      <Index.FormHelperText
                                                        error
                                                      >
                                                        {errors.phoneNumber}
                                                      </Index.FormHelperText>
                                                    )}
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box className="step-one-next-prev-btn">
                                    <Index.Box className="next-btn-step">
                                      <Index.PrimaryButton
                                        btnLabel="Next"
                                        className="comman-primary-btn"
                                        type="submit"
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </form>
                              )}
                            </Formik>
                          </Index.Box>

                          {/* step1 end */}

                          {/* step2 start */}

                          <Index.Box
                            className={`step-two-details-content ${
                              activeStep == 2
                                ? "step-block"
                                : "display-none-step"
                            }`}
                          >
                            <Formik
                              enableReinitialize
                              onSubmit={handleFormSubmitStep2}
                              initialValues={initialValuesStep2}
                              validationSchema={validationSchemaStep2}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                setFieldValue,
                                handleBlur,
                                handleSubmit,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  <Index.Box className="step-two-account-details">
                                    <Index.Box className="account-list-content">
                                      <Index.Box
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                                        className="stepper-signup-grid"
                                        sx={{ margin: 0, alignItems: "center" }}
                                      >
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Date of Birth
                                              </Index.FormHelperText>

                                              {/* <StyledEngineProvider injectFirst> */}
                                              <Index.Box className="datepicker-wrap">
                                                <LocalizationProvider
                                                  dateAdapter={AdapterMoment}
                                                  className="datepicker-wrap-inner"
                                                >
                                                  <DatePicker
                                                    maxDate={moment(new Date())}
                                                    value={
                                                      values?.dateOfBirth
                                                        ? values?.dateOfBirth
                                                        : ""
                                                    }
                                                    onChange={(value) => {
                                                      setFieldValue(
                                                        "dateOfBirth",
                                                        moment(value._d).format(
                                                          "MM/DD/YYYY"
                                                        )
                                                      );

                                                      const currentDate =
                                                        moment(new Date());
                                                      const selectedDate =
                                                        moment(value);
                                                      var yearDifference =
                                                        currentDate.diff(
                                                          selectedDate,
                                                          "year"
                                                        );
                                                      setFieldValue(
                                                        "age",
                                                        yearDifference
                                                      );
                                                    }}
                                                    renderInput={(props) => (
                                                      <TextField
                                                        {...props}
                                                        size="small"
                                                        onKeyDown={onKeyDown}
                                                        helperText={null}
                                                        className="date-text-list"
                                                      />
                                                    )}
                                                    className="mob-date-picker date-picker-details"
                                                  />
                                                </LocalizationProvider>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                          {touched.dateOfBirth &&
                                            errors.dateOfBirth && (
                                              <Index.FormHelperText error>
                                                {errors.dateOfBirth}
                                              </Index.FormHelperText>
                                            )}
                                        </Index.Box>

                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Gender
                                              </Index.FormHelperText>
                                              <Index.Box className="gender-user-account">
                                                <Index.FormControl className="form-gender-control">
                                                  <Index.RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="gender"
                                                    className="form-gendergroup-control"
                                                    defaultValue={
                                                      values?.gender
                                                    }
                                                    onChange={(e, value) => {
                                                      setFieldValue(
                                                        "gender",
                                                        value
                                                      );
                                                    }}
                                                  >
                                                    <Index.FormControlLabel
                                                      value="female"
                                                      control={<Index.Radio />}
                                                      label="Female"
                                                      className="form-genderlabel-control"
                                                    />
                                                    <Index.FormControlLabel
                                                      value="male"
                                                      control={<Index.Radio />}
                                                      label="Male"
                                                      className="form-genderlabel-control"
                                                    />
                                                    <Index.FormControlLabel
                                                      value="other"
                                                      control={<Index.Radio />}
                                                      label="Other"
                                                      className="form-genderlabel-control"
                                                    />
                                                  </Index.RadioGroup>
                                                </Index.FormControl>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                          {touched.gender && errors.gender && (
                                            <Index.FormHelperText error>
                                              {errors.gender}
                                            </Index.FormHelperText>
                                          )}
                                        </Index.Box>

                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 2",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Age
                                              </Index.FormHelperText>
                                              <Index.TextField
                                                fullWidth
                                                type="number"
                                                id="fullWidth"
                                                className="form-control custom-control"
                                                name="age"
                                                value={values?.age}
                                                inputProps={{ maxLength: 3 }}
                                                onBlur={handleBlur}
                                                InputProps={{
                                                  readOnly: true,
                                                }}
                                                // onChange={handleChange}
                                                onChange={(e) => {
                                                  if (
                                                    e.target.value.toString()
                                                      .length <= 3
                                                  ) {
                                                    setFieldValue(
                                                      "age",
                                                      e.target.value
                                                    );
                                                  }
                                                }}
                                                helperText={
                                                  touched.age
                                                    ? errors.age
                                                    : undefined
                                                }
                                                error={Boolean(
                                                  errors.age
                                                    ? touched.age
                                                    : undefined
                                                )}
                                              />
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>

                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 5",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list step-height-text">
                                            <Index.Box className="form-group custom-group">
                                              <Index.Box className="flex-center-radio-hgt">
                                                <Index.FormHelperText className="form-label">
                                                  Height
                                                </Index.FormHelperText>
                                                <Index.Box className="height-radio-main">
                                                  <Index.RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    className="form-heightgroup-control-main"
                                                    defaultValue={
                                                      values?.height?.unit
                                                    }
                                                    onChange={(e, value) => {
                                                      const formatedData = {
                                                        unit: value,
                                                        value:
                                                          values?.height?.value,
                                                        fit: values?.height
                                                        ?.fit,
                                                        inch: values?.height
                                                          ?.inch,
                                                        cm: values?.height?.cm,
                                                        mm: values?.height?.mm,
                                                      };
                                                      setFieldValue(
                                                        "height",
                                                        formatedData
                                                      );
                                                    }}
                                                  >
                                                    <Index.FormControlLabel
                                                      value="ft"
                                                      control={<Index.Radio />}
                                                      label="Fit/Inches"
                                                      className="form-heightgroup-control"
                                                    />
                                                    <Index.FormControlLabel
                                                      value="cms"
                                                      control={<Index.Radio />}
                                                      label="Centimeters"
                                                      className="form-heightgroup-control"
                                                    />
                                                  </Index.RadioGroup>
                                                </Index.Box>
                                              </Index.Box>
                                              {values?.height?.unit != "cms" ? (
                                                <Index.Box
                                                  display="grid"
                                                  gridTemplateColumns="repeat(12, 1fr)"
                                                  gap={{
                                                    xs: 0,
                                                    sm: 0,
                                                    md: 0,
                                                    lg: 0,
                                                  }}
                                                  className="stepper-signup-grid"
                                                  sx={{
                                                    margin: 0,
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Index.Box
                                                    gridColumn={{
                                                      xs: "span 12",
                                                      sm: "span 6",
                                                      md: "span 6",
                                                      lg: "span 6",
                                                    }}
                                                    className="grid-column stepper-signup-column "
                                                  >
                                                    <Index.Box className="edit_profile_field height-stepper-main">
                                                      <Index.Box className="form-group from_login_details">
                                                        <Index.Box className="form-flex-input">
                                                          <Index.Box className="dropdown-box">
                                                            <Index.FormControl className="form-control">
                                                              <Index.Select
                                                                className="dropdown-select "
                                                                value={
                                                                  values?.height
                                                                    ?.fit
                                                                }
                                                                defaultValue={
                                                                  values?.height
                                                                    ?.fit
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const formatedData =
                                                                    {
                                                                      unit: values
                                                                        ?.height
                                                                        ?.unit
                                                                        ? values
                                                                            ?.height
                                                                            ?.unit
                                                                        : heightUnit,
                                                                      value:
                                                                        values
                                                                          ?.height
                                                                          ?.value,
                                                                      fit: e
                                                                        .target
                                                                        .value,
                                                                      inch: values
                                                                        ?.height
                                                                        ?.inch,
                                                                      cm: values
                                                                        ?.height
                                                                        ?.cm,
                                                                      mm: values
                                                                        ?.height
                                                                        ?.mm,
                                                                    };
                                                                  setFieldValue(
                                                                    "height",
                                                                    formatedData
                                                                  );
                                                                }}
                                                                displayEmpty
                                                                inputProps={{
                                                                  "aria-label":
                                                                    "Without label",
                                                                }}
                                                              >
                                                                {fitOptions.map(
                                                                  (item) => (
                                                                    <Index.MenuItem
                                                                      value={
                                                                        item
                                                                      }
                                                                      className="menuitem"
                                                                    >
                                                                      {item}
                                                                    </Index.MenuItem>
                                                                  )
                                                                )}
                                                              </Index.Select>
                                                            </Index.FormControl>
                                                          </Index.Box>
                                                          <Index.Box className="text-height-drop">
                                                            <Index.Typography
                                                              component="p"
                                                              variant="p"
                                                              className="text-drop-list"
                                                            >
                                                              ft
                                                            </Index.Typography>
                                                          </Index.Box>
                                                        </Index.Box>
                                                      </Index.Box>
                                                    </Index.Box>
                                                    {errors?.height?.fit && (
                                                      <Index.FormHelperText
                                                        error
                                                      >
                                                        {errors?.height?.fit}
                                                      </Index.FormHelperText>
                                                    )}
                                                  </Index.Box>

                                                  <Index.Box
                                                    gridColumn={{
                                                      xs: "span 12",
                                                      sm: "span 6",
                                                      md: "span 6",
                                                      lg: "span 6",
                                                    }}
                                                    className="grid-column stepper-signup-column "
                                                  >
                                                    <Index.Box className="edit_profile_field height-stepper-main">
                                                      <Index.Box className="form-group from_login_details">
                                                        <Index.Box className="form-flex-input">
                                                          <Index.Box className="dropdown-box">
                                                            <Index.FormControl className="form-control">
                                                              <Index.Select
                                                                className="dropdown-select "
                                                                value={
                                                                  values?.height
                                                                    ?.inch
                                                                }
                                                                defaultValue={
                                                                  values?.height
                                                                    ?.inch
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const formatedData =
                                                                    {
                                                                      unit: values
                                                                        ?.height
                                                                        ?.unit
                                                                        ? values
                                                                            ?.height
                                                                            ?.unit
                                                                        : heightUnit,
                                                                      value:
                                                                        values
                                                                          ?.height
                                                                          ?.value,
                                                                      fit: values
                                                                        ?.height
                                                                        ?.fit,
                                                                      inch: e
                                                                        .target
                                                                        .value,
                                                                      cm: values
                                                                        ?.height
                                                                        ?.cm,
                                                                      mm: values
                                                                        ?.height
                                                                        ?.mm,
                                                                    };
                                                                  setFieldValue(
                                                                    "height",
                                                                    formatedData
                                                                  );
                                                                }}
                                                                displayEmpty
                                                                inputProps={{
                                                                  "aria-label":
                                                                    "Without label",
                                                                }}
                                                              >
                                                                {inchOptions.map(
                                                                  (item) => (
                                                                    <Index.MenuItem
                                                                      value={
                                                                        item
                                                                      }
                                                                      className="menuitem"
                                                                    >
                                                                      {item}
                                                                    </Index.MenuItem>
                                                                  )
                                                                )}
                                                              </Index.Select>
                                                            </Index.FormControl>
                                                          </Index.Box>
                                                          <Index.Box className="text-height-drop">
                                                            <Index.Typography
                                                              component="p"
                                                              variant="p"
                                                              className="text-drop-list"
                                                            >
                                                              Inches
                                                            </Index.Typography>
                                                          </Index.Box>
                                                        </Index.Box>
                                                      </Index.Box>
                                                    </Index.Box>
                                                    {errors?.height?.inch && (
                                                      <Index.FormHelperText
                                                        error
                                                      >
                                                        {errors?.height?.inch}
                                                      </Index.FormHelperText>
                                                    )}
                                                  </Index.Box>
                                                </Index.Box>
                                              ) : (
                                                <Index.Box
                                                  display="grid"
                                                  gridTemplateColumns="repeat(12, 1fr)"
                                                  gap={{
                                                    xs: 0,
                                                    sm: 0,
                                                    md: 0,
                                                    lg: 0,
                                                  }}
                                                  className="stepper-signup-grid"
                                                  sx={{
                                                    margin: 0,
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Index.Box
                                                    gridColumn={{
                                                      xs: "span 12",
                                                      sm: "span 6",
                                                      md: "span 6",
                                                      lg: "span 6",
                                                    }}
                                                    className="grid-column stepper-signup-column "
                                                  >
                                                    <Index.Box className="edit_profile_field  height-stepper-main">
                                                      <Index.Box className="form-group from_login_details">
                                                        <Index.Box className="form-flex-input">
                                                          <Index.Box className="dropdown-box">
                                                            <Index.FormControl className="form-control">
                                                              <Index.Select
                                                                className="dropdown-select "
                                                                defaultValue={
                                                                  values?.height
                                                                    ?.cm
                                                                }
                                                                value={
                                                                  values?.height
                                                                    ?.cm
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const formatedData =
                                                                    {
                                                                      unit: values
                                                                        ?.height
                                                                        ?.unit
                                                                        ? values
                                                                            ?.height
                                                                            ?.unit
                                                                        : heightUnit,
                                                                      value:
                                                                        values
                                                                          ?.height
                                                                          ?.value,
                                                                      fit: values
                                                                        ?.height
                                                                        ?.fit,
                                                                      inch: values
                                                                        ?.height
                                                                        ?.inch,
                                                                      cm: e
                                                                        .target
                                                                        .value,
                                                                      mm: values
                                                                        ?.height
                                                                        ?.mm,
                                                                    };
                                                                  setFieldValue(
                                                                    "height",
                                                                    formatedData
                                                                  );
                                                                }}
                                                                displayEmpty
                                                                inputProps={{
                                                                  "aria-label":
                                                                    "Without label",
                                                                }}
                                                              >
                                                                {cmOptions.map(
                                                                  (item) => (
                                                                    <Index.MenuItem
                                                                      value={
                                                                        item
                                                                      }
                                                                      className="menuitem"
                                                                    >
                                                                      {item}
                                                                    </Index.MenuItem>
                                                                  )
                                                                )}
                                                              </Index.Select>
                                                            </Index.FormControl>
                                                          </Index.Box>
                                                          <Index.Box className="text-height-drop">
                                                            <Index.Typography
                                                              component="p"
                                                              variant="p"
                                                              className="text-drop-list"
                                                            >
                                                              cm
                                                            </Index.Typography>
                                                          </Index.Box>
                                                        </Index.Box>
                                                      </Index.Box>
                                                    </Index.Box>
                                                    {errors?.height?.cm && (
                                                      <Index.FormHelperText
                                                        error
                                                      >
                                                        {errors?.height?.cm}
                                                      </Index.FormHelperText>
                                                    )}
                                                  </Index.Box>
                                                  <Index.Box
                                                    gridColumn={{
                                                      xs: "span 12",
                                                      sm: "span 6",
                                                      md: "span 6",
                                                      lg: "span 6",
                                                    }}
                                                    className="grid-column stepper-signup-column "
                                                  >
                                                    <Index.Box className="edit_profile_field height-stepper-main">
                                                      <Index.Box className="form-group from_login_details">
                                                        <Index.Box className="form-flex-input">
                                                          <Index.Box className="dropdown-box">
                                                            <Index.FormControl className="form-control">
                                                              <Index.Select
                                                                className="dropdown-select "
                                                                // name="routine"
                                                                value={
                                                                  values?.height
                                                                    ?.mm
                                                                }
                                                                defaultValue={
                                                                  values?.height
                                                                    ?.mm
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const formatedData =
                                                                    {
                                                                      unit: values
                                                                        ?.height
                                                                        ?.unit
                                                                        ? values
                                                                            ?.height
                                                                            ?.unit
                                                                        : heightUnit,
                                                                      value:
                                                                        values
                                                                          ?.height
                                                                          ?.value,
                                                                      fit: values
                                                                        ?.height
                                                                        ?.fit,
                                                                      inch: values
                                                                        ?.height
                                                                        ?.inch,
                                                                      cm: values
                                                                        ?.height
                                                                        ?.cm,
                                                                      mm: e
                                                                        .target
                                                                        .value,
                                                                    };
                                                                  setFieldValue(
                                                                    "height",
                                                                    formatedData
                                                                  );
                                                                }}
                                                                displayEmpty
                                                                inputProps={{
                                                                  "aria-label":
                                                                    "Without label",
                                                                }}
                                                              >
                                                                <Index.MenuItem
                                                                  value={0}
                                                                  className="menuitem"
                                                                >
                                                                  00
                                                                </Index.MenuItem>
                                                              </Index.Select>
                                                            </Index.FormControl>
                                                          </Index.Box>
                                                          <Index.Box className="text-height-drop">
                                                            <Index.Typography
                                                              component="p"
                                                              variant="p"
                                                              className="text-drop-list"
                                                            >
                                                              mm
                                                            </Index.Typography>
                                                          </Index.Box>
                                                        </Index.Box>
                                                      </Index.Box>
                                                      {errors?.height?.mm && (
                                                        <Index.FormHelperText
                                                          error
                                                        >
                                                          {errors?.height?.mm}
                                                        </Index.FormHelperText>
                                                      )}
                                                    </Index.Box>
                                                  </Index.Box>
                                                </Index.Box>
                                              )}
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                        {/* my */}

                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 5",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list step-height-text">
                                            <Index.Box className="form-group custom-group">
                                              <Index.Box className="flex-center-radio-hgt">
                                                <Index.FormHelperText className="form-label">
                                                  Weight
                                                </Index.FormHelperText>
                                                <Index.Box className="height-radio-main">
                                                  <Index.RadioGroup
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    className="form-heightgroup-control-main"
                                                    defaultValue={
                                                      values?.weight?.unit
                                                    }
                                                    onChange={(e, value) => {
                                                      const formatedData = {
                                                        unit: value,
                                                        value:
                                                          values?.weight?.value,
                                                        lbs: values?.weight
                                                          ?.lbs,
                                                        oz: values?.weight?.oz,
                                                        kilo: values?.weight
                                                          ?.kilo,
                                                        gram: values?.weight
                                                          ?.gram,
                                                      };
                                                      setFieldValue(
                                                        "weight",
                                                        formatedData
                                                      );
                                                    }}
                                                  >
                                                    <Index.FormControlLabel
                                                      value="lbs"
                                                      control={<Index.Radio />}
                                                      label="Lbs"
                                                      className="form-heightgroup-control"
                                                    />
                                                    <Index.FormControlLabel
                                                      value="kg"
                                                      control={<Index.Radio />}
                                                      label="Kgs"
                                                      className="form-heightgroup-control"
                                                    />
                                                  </Index.RadioGroup>
                                                </Index.Box>
                                              </Index.Box>
                                              {values?.weight?.unit != "lbs" ? (
                                                <Index.Box
                                                  display="grid"
                                                  gridTemplateColumns="repeat(12, 1fr)"
                                                  gap={{
                                                    xs: 0,
                                                    sm: 0,
                                                    md: 0,
                                                    lg: 0,
                                                  }}
                                                  className="stepper-signup-grid"
                                                  sx={{
                                                    margin: 0,
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Index.Box
                                                    gridColumn={{
                                                      xs: "span 12",
                                                      sm: "span 6",
                                                      md: "span 6",
                                                      lg: "span 6",
                                                    }}
                                                    className="grid-column stepper-signup-column "
                                                  >
                                                    <Index.Box className="edit_profile_field height-stepper-main">
                                                      <Index.Box className="form-group from_login_details">
                                                        <Index.Box className="form-flex-input">
                                                          <Index.Box className="dropdown-box">
                                                            <Index.FormControl className="form-control">
                                                              <Index.Select
                                                                className="dropdown-select "
                                                                value={
                                                                  values?.weight
                                                                    ?.kilo
                                                                }
                                                                defaultValue={
                                                                  values?.weight
                                                                    ?.kilo
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const formatedData =
                                                                    {
                                                                      unit: values
                                                                        ?.weight
                                                                        ?.unit
                                                                        ? values
                                                                            ?.weight
                                                                            ?.unit
                                                                        : weightUnit,
                                                                      value:
                                                                        values
                                                                          ?.weight
                                                                          ?.value,
                                                                      kilo: e
                                                                        .target
                                                                        .value,
                                                                      gram: values
                                                                        ?.weight
                                                                        ?.gram,
                                                                      lbs: values
                                                                        ?.weight
                                                                        ?.lbs,
                                                                      oz: values
                                                                        ?.weight
                                                                        ?.oz,
                                                                    };
                                                                  setFieldValue(
                                                                    "weight",
                                                                    formatedData
                                                                  );
                                                                }}
                                                                displayEmpty
                                                                inputProps={{
                                                                  "aria-label":
                                                                    "Without label",
                                                                }}
                                                              >
                                                                {kgOption.map(
                                                                  (item) => (
                                                                    <Index.MenuItem
                                                                      value={
                                                                        item
                                                                      }
                                                                      className="menuitem"
                                                                    >
                                                                      {item}
                                                                    </Index.MenuItem>
                                                                  )
                                                                )}
                                                              </Index.Select>
                                                            </Index.FormControl>
                                                          </Index.Box>
                                                          <Index.Box className="text-height-drop">
                                                            <Index.Typography
                                                              component="p"
                                                              variant="p"
                                                              className="text-drop-list"
                                                            >
                                                              Kilo
                                                            </Index.Typography>
                                                          </Index.Box>
                                                        </Index.Box>
                                                      </Index.Box>
                                                    </Index.Box>
                                                    {errors?.weight?.kilo && (
                                                      <Index.FormHelperText
                                                        error
                                                      >
                                                        {errors?.weight?.kilo}
                                                      </Index.FormHelperText>
                                                    )}
                                                  </Index.Box>
                                                  <Index.Box
                                                    gridColumn={{
                                                      xs: "span 12",
                                                      sm: "span 6",
                                                      md: "span 6",
                                                      lg: "span 6",
                                                    }}
                                                    className="grid-column stepper-signup-column "
                                                  >
                                                    <Index.Box className="edit_profile_field height-stepper-main">
                                                      <Index.Box className="form-group from_login_details">
                                                        <Index.Box className="form-flex-input">
                                                          <Index.Box className="dropdown-box">
                                                            <Index.FormControl className="form-control">
                                                              <Index.Select
                                                                className="dropdown-select "
                                                                value={
                                                                  values?.weight
                                                                    ?.gram
                                                                }
                                                                defaultValue={
                                                                  values?.weight
                                                                    ?.gram
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const formatedData =
                                                                    {
                                                                      unit: values
                                                                        ?.weight
                                                                        ?.unit
                                                                        ? values
                                                                            ?.weight
                                                                            ?.unit
                                                                        : weightUnit,
                                                                      value:
                                                                        values
                                                                          ?.weight
                                                                          ?.value,
                                                                      kilo: values
                                                                        ?.weight
                                                                        ?.kilo,
                                                                      gram: e
                                                                        .target
                                                                        .value,
                                                                      lbs: values
                                                                        ?.weight
                                                                        ?.lbs,
                                                                      oz: values
                                                                        ?.weight
                                                                        ?.oz,
                                                                    };
                                                                  setFieldValue(
                                                                    "weight",
                                                                    formatedData
                                                                  );
                                                                }}
                                                                displayEmpty
                                                                inputProps={{
                                                                  "aria-label":
                                                                    "Without label",
                                                                }}
                                                              >
                                                                {gramOption.map(
                                                                  (item) => (
                                                                    <Index.MenuItem
                                                                      value={
                                                                        item
                                                                      }
                                                                      className="menuitem"
                                                                    >
                                                                      {item}
                                                                    </Index.MenuItem>
                                                                  )
                                                                )}
                                                              </Index.Select>
                                                            </Index.FormControl>
                                                          </Index.Box>
                                                          <Index.Box className="text-height-drop">
                                                            <Index.Typography
                                                              component="p"
                                                              variant="p"
                                                              className="text-drop-list"
                                                            >
                                                              Gram
                                                            </Index.Typography>
                                                          </Index.Box>
                                                        </Index.Box>
                                                      </Index.Box>
                                                    </Index.Box>
                                                    {errors?.weight?.gram && (
                                                      <Index.FormHelperText
                                                        error
                                                      >
                                                        {errors?.weight?.gram}
                                                      </Index.FormHelperText>
                                                    )}
                                                  </Index.Box>
                                                </Index.Box>
                                              ) : (
                                                <Index.Box
                                                  display="grid"
                                                  gridTemplateColumns="repeat(12, 1fr)"
                                                  gap={{
                                                    xs: 0,
                                                    sm: 0,
                                                    md: 0,
                                                    lg: 0,
                                                  }}
                                                  className="stepper-signup-grid"
                                                  sx={{
                                                    margin: 0,
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Index.Box
                                                    gridColumn={{
                                                      xs: "span 12",
                                                      sm: "span 6",
                                                      md: "span 6",
                                                      lg: "span 6",
                                                    }}
                                                    className="grid-column stepper-signup-column "
                                                  >
                                                    <Index.Box className="edit_profile_field  height-stepper-main">
                                                      <Index.Box className="form-group from_login_details">
                                                        <Index.Box className="form-flex-input">
                                                          <Index.Box className="dropdown-box">
                                                            <Index.FormControl className="form-control">
                                                              <Index.Select
                                                                className="dropdown-select "
                                                                value={
                                                                  values?.weight
                                                                    ?.lbs
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const formatedData =
                                                                    {
                                                                      unit: values
                                                                        ?.weight
                                                                        ?.unit
                                                                        ? values
                                                                            ?.weight
                                                                            ?.unit
                                                                        : weightUnit,
                                                                      value:
                                                                        values
                                                                          ?.weight
                                                                          ?.value,
                                                                      kilo: values
                                                                        ?.weight
                                                                        ?.kilo,
                                                                      gram: values
                                                                        ?.weight
                                                                        ?.gram,
                                                                      lbs: e
                                                                        .target
                                                                        .value,
                                                                      oz: values
                                                                        ?.weight
                                                                        ?.oz,
                                                                    };
                                                                  setFieldValue(
                                                                    "weight",
                                                                    formatedData
                                                                  );
                                                                }}
                                                                displayEmpty
                                                                inputProps={{
                                                                  "aria-label":
                                                                    "Without label",
                                                                }}
                                                              >
                                                                {lbsOptions.map(
                                                                  (item) => (
                                                                    <Index.MenuItem
                                                                      value={
                                                                        item
                                                                      }
                                                                      className="menuitem"
                                                                    >
                                                                      {item}
                                                                    </Index.MenuItem>
                                                                  )
                                                                )}
                                                              </Index.Select>
                                                            </Index.FormControl>
                                                          </Index.Box>
                                                          <Index.Box className="text-height-drop">
                                                            <Index.Typography
                                                              component="p"
                                                              variant="p"
                                                              className="text-drop-list"
                                                            >
                                                              Lbs
                                                            </Index.Typography>
                                                          </Index.Box>
                                                        </Index.Box>
                                                      </Index.Box>
                                                    </Index.Box>
                                                    {errors?.weight?.lbs && (
                                                      <Index.FormHelperText
                                                        error
                                                      >
                                                        {errors?.weight?.lbs}
                                                      </Index.FormHelperText>
                                                    )}
                                                  </Index.Box>
                                                  <Index.Box
                                                    gridColumn={{
                                                      xs: "span 12",
                                                      sm: "span 6",
                                                      md: "span 6",
                                                      lg: "span 6",
                                                    }}
                                                    className="grid-column stepper-signup-column "
                                                  >
                                                    <Index.Box className="edit_profile_field height-stepper-main">
                                                      <Index.Box className="form-group from_login_details">
                                                        <Index.Box className="form-flex-input">
                                                          <Index.Box className="dropdown-box">
                                                            <Index.FormControl className="form-control">
                                                              <Index.Select
                                                                className="dropdown-select "
                                                                value={
                                                                  values?.weight
                                                                    ?.oz
                                                                }
                                                                defaultValue={
                                                                  values?.weight
                                                                    ?.oz
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  const formatedData =
                                                                    {
                                                                      unit: values
                                                                        ?.weight
                                                                        ?.unit
                                                                        ? values
                                                                            ?.weight
                                                                            ?.unit
                                                                        : weightUnit,
                                                                      value:
                                                                        values
                                                                          ?.weight
                                                                          ?.value,
                                                                      kilo: values
                                                                        ?.weight
                                                                        ?.kilo,
                                                                      gram: values
                                                                        ?.weight
                                                                        ?.gram,
                                                                      lbs: values
                                                                        ?.weight
                                                                        ?.lbs,
                                                                      oz: e
                                                                        .target
                                                                        .value,
                                                                    };
                                                                  setFieldValue(
                                                                    "weight",
                                                                    formatedData
                                                                  );
                                                                }}
                                                                displayEmpty
                                                                inputProps={{
                                                                  "aria-label":
                                                                    "Without label",
                                                                }}
                                                              >
                                                                {ozOptions.map(
                                                                  (item) => (
                                                                    <Index.MenuItem
                                                                      value={
                                                                        item
                                                                      }
                                                                      className="menuitem"
                                                                    >
                                                                      {item}
                                                                    </Index.MenuItem>
                                                                  )
                                                                )}
                                                              </Index.Select>
                                                            </Index.FormControl>
                                                          </Index.Box>
                                                          <Index.Box className="text-height-drop">
                                                            <Index.Typography
                                                              component="p"
                                                              variant="p"
                                                              className="text-drop-list"
                                                            >
                                                              OZ
                                                            </Index.Typography>
                                                          </Index.Box>
                                                        </Index.Box>
                                                      </Index.Box>
                                                    </Index.Box>
                                                    {errors?.weight?.oz && (
                                                      <Index.FormHelperText
                                                        error
                                                      >
                                                        {errors?.weight?.oz}
                                                      </Index.FormHelperText>
                                                    )}
                                                  </Index.Box>
                                                </Index.Box>
                                              )}
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 4",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Routine
                                              </Index.FormHelperText>
                                              <Index.Box className="edit_profile_field">
                                                <Index.Box className="form-group from_login_details">
                                                  <Index.Box className="dropdown-box">
                                                    {values?.routine != "" ? (
                                                      <Index.FormControl className="form-control">
                                                        <Index.Select
                                                          className="dropdown-select "
                                                          name="routine"
                                                          defaultValue={
                                                            values?.routine
                                                          }
                                                          value={
                                                            values?.routine
                                                          }
                                                          onChange={(e) => {
                                                            setFieldValue(
                                                              "routine",
                                                              e.target.value
                                                            );
                                                          }}
                                                          displayEmpty
                                                          inputProps={{
                                                            "aria-label":
                                                              "Without label",
                                                          }}
                                                        >
                                                          <Index.MenuItem
                                                            value="active"
                                                            className="menuitem"
                                                          >
                                                            Active
                                                          </Index.MenuItem>
                                                          <Index.MenuItem
                                                            value="normal"
                                                            className="menuitem"
                                                          >
                                                            Normal
                                                          </Index.MenuItem>
                                                          <Index.MenuItem
                                                            value="inactive"
                                                            className="menuitem"
                                                          >
                                                            Inactive
                                                          </Index.MenuItem>
                                                          <Index.MenuItem
                                                            value="sedentary"
                                                            className="menuitem"
                                                          >
                                                            Sedentary
                                                          </Index.MenuItem>
                                                        </Index.Select>
                                                      </Index.FormControl>
                                                    ) : (
                                                      <Index.FormControl className="form-control">
                                                        <Index.Select
                                                          className="dropdown-select "
                                                          name="routine"
                                                          value={
                                                            values?.routine
                                                          }
                                                          onChange={(e) => {
                                                            setFieldValue(
                                                              "routine",
                                                              e.target.value
                                                            );
                                                          }}
                                                          displayEmpty
                                                          inputProps={{
                                                            "aria-label":
                                                              "Without label",
                                                          }}
                                                        >
                                                          <Index.MenuItem
                                                            value="active"
                                                            className="menuitem"
                                                          >
                                                            Active
                                                          </Index.MenuItem>
                                                          <Index.MenuItem
                                                            value="inactive"
                                                            className="menuitem"
                                                          >
                                                            Inactive
                                                          </Index.MenuItem>
                                                          <Index.MenuItem
                                                            value="sedentary"
                                                            className="menuitem"
                                                          >
                                                            Sedentary
                                                          </Index.MenuItem>
                                                        </Index.Select>
                                                      </Index.FormControl>
                                                    )}
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                              {touched.routine &&
                                                errors.routine && (
                                                  <Index.FormHelperText error>
                                                    {errors.routine}
                                                  </Index.FormHelperText>
                                                )}
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>

                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 4",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Food Preference
                                              </Index.FormHelperText>
                                              <Index.Box className="edit_profile_field">
                                                <Index.Box className="form-group from_login_details">
                                                  <Index.Box className="dropdown-box">
                                                    <Index.FormControl className="form-control">
                                                      <Index.Select
                                                        className="dropdown-select"
                                                        name="foodPreference"
                                                        defaultValue={
                                                          values
                                                            ? values?.foodPreference
                                                            : ""
                                                        }
                                                        onChange={(e) => {
                                                          setFieldValue(
                                                            "foodPreference",
                                                            e.target.value
                                                          );
                                                        }}
                                                        displayEmpty
                                                        inputProps={{
                                                          "aria-label":
                                                            "Without label",
                                                        }}
                                                      >
                                                        <Index.MenuItem
                                                          value="Vegan"
                                                          className="menuitem"
                                                        >
                                                          Vegan
                                                        </Index.MenuItem>
                                                        <Index.MenuItem
                                                          value="Vegetarian"
                                                          className="menuitem"
                                                        >
                                                          Vegetarian
                                                        </Index.MenuItem>
                                                        <Index.MenuItem
                                                          value="Non-Vegetarian"
                                                          className="menuitem"
                                                        >
                                                          Non-Vegetarian
                                                        </Index.MenuItem>
                                                        <Index.MenuItem
                                                          value="Veg&Nonveg"
                                                          className="menuitem"
                                                        >
                                                          Veg&Nonveg
                                                        </Index.MenuItem>
                                                      </Index.Select>
                                                    </Index.FormControl>
                                                  </Index.Box>
                                                </Index.Box>
                                                {touched.foodPreference &&
                                                  errors.foodPreference && (
                                                    <Index.FormHelperText error>
                                                      {errors.foodPreference}
                                                    </Index.FormHelperText>
                                                  )}
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>

                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 4",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Diet
                                              </Index.FormHelperText>
                                              <Index.Box className="edit_profile_field">
                                                <Index.Box className="form-group from_login_details">
                                                  <Index.Box className="dropdown-box">
                                                    <Index.FormControl className="form-control">
                                                      <Index.Select
                                                        className="dropdown-select "
                                                        name="diet"
                                                        defaultValue={
                                                          values
                                                            ? values?.diet
                                                            : ""
                                                        }
                                                        onChange={(e) => {
                                                          setFieldValue(
                                                            "diet",
                                                            e.target.value
                                                          );
                                                        }}
                                                        displayEmpty
                                                        inputProps={{
                                                          "aria-label":
                                                            "Without label",
                                                        }}
                                                      >
                                                        <Index.MenuItem
                                                          value="Healthy"
                                                          className="menuitem"
                                                        >
                                                          Healthy
                                                        </Index.MenuItem>
                                                        <Index.MenuItem
                                                          value="Normal"
                                                          className="menuitem"
                                                        >
                                                          Normal
                                                        </Index.MenuItem>
                                                        <Index.MenuItem
                                                          value="Junky"
                                                          className="menuitem"
                                                        >
                                                          Junky
                                                        </Index.MenuItem>
                                                      </Index.Select>
                                                    </Index.FormControl>
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                              {touched.diet && errors.diet && (
                                                <Index.FormHelperText error>
                                                  {errors.diet}
                                                </Index.FormHelperText>
                                              )}
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box className="step-one-next-prev-btn">
                                    <Index.Box className="outline-prev-main">
                                      <Index.BlueBorderButton
                                        btnLabel="Back"
                                        className="blue-outline-btn"
                                        onClick={() => handleBackClick(1)}
                                      />
                                    </Index.Box>
                                    <Index.Box className="next-btn-step">
                                      <Index.PrimaryButton
                                        btnLabel="Next"
                                        className="comman-primary-btn"
                                        type="submit"
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </form>
                              )}
                            </Formik>
                          </Index.Box>

                          {/* step2 end */}

                          {/* step3 start */}

                          <Index.Box
                            className={`step-third-details-content ${
                              activeStep == 3
                                ? "step-block"
                                : "display-none-step"
                            }`}
                          >
                            <Formik
                              enableReinitialize
                              onSubmit={handleFormSubmitStep3}
                              initialValues={initialValuesStep3}
                              validationSchema={validationSchemaStep3}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                setFieldValue,
                                handleBlur,
                                handleSubmit,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  <Index.Box className="step-third-account-details">
                                    <Index.Typography
                                      className="title-health-challenges"
                                      component="h3"
                                      variant="h3"
                                    >
                                      Select your fitness goal
                                    </Index.Typography>

                                    <Index.Box className="fitness-list-content">
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.goal?.includes(
                                              "Lose Weight"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.goal?.includes(
                                                "Lose Weight"
                                              )
                                            ) {
                                              const newData =
                                                values?.goal?.filter(function (
                                                  item
                                                ) {
                                                  return item !== "Lose Weight";
                                                });
                                              setFieldValue("goal", newData);
                                            } else {
                                              const newData = [...values?.goal];
                                              newData.push("Lose Weight");
                                              setFieldValue("goal", newData);
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.gainweight}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Lose Weight
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.goal?.includes(
                                              "Maintain Weight"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.goal?.includes(
                                                "Maintain Weight"
                                              )
                                            ) {
                                              const newData =
                                                values?.goal?.filter(function (
                                                  item
                                                ) {
                                                  return (
                                                    item !== "Maintain Weight"
                                                  );
                                                });
                                              setFieldValue("goal", newData);
                                            } else {
                                              const newData = [...values?.goal];
                                              newData.push("Maintain Weight");
                                              setFieldValue("goal", newData);
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.maintainweight}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Maintain Weight
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.goal?.includes(
                                              "Gain Weight"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.goal?.includes(
                                                "Gain Weight"
                                              )
                                            ) {
                                              const newData =
                                                values?.goal?.filter(function (
                                                  item
                                                ) {
                                                  return item !== "Gain Weight";
                                                });
                                              setFieldValue("goal", newData);
                                            } else {
                                              const newData = [...values?.goal];
                                              newData.push("Gain Weight");
                                              setFieldValue("goal", newData);
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.gainweight}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Gain Weight
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.goal?.includes(
                                              "Gain Muscle"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.goal?.includes(
                                                "Gain Muscle"
                                              )
                                            ) {
                                              const newData =
                                                values?.goal?.filter(function (
                                                  item
                                                ) {
                                                  return item !== "Gain Muscle";
                                                });
                                              setFieldValue("goal", newData);
                                            } else {
                                              const newData = [...values?.goal];
                                              newData.push("Gain Muscle");
                                              setFieldValue("goal", newData);
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.gainmuscle}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Gain Muscle
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.goal?.includes(
                                              "Increase Energy"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.goal?.includes(
                                                "Increase Energy"
                                              )
                                            ) {
                                              const newData =
                                                values?.goal?.filter(function (
                                                  item
                                                ) {
                                                  return (
                                                    item !== "Increase Energy"
                                                  );
                                                });
                                              setFieldValue("goal", newData);
                                            } else {
                                              const newData = [...values?.goal];
                                              newData.push("Increase Energy");
                                              setFieldValue("goal", newData);
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.increaseenergy}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Increase Energy
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.goal?.includes(
                                              "Fit & Active"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.goal?.includes(
                                                "Fit & Active"
                                              )
                                            ) {
                                              const newData =
                                                values?.goal?.filter(function (
                                                  item
                                                ) {
                                                  return (
                                                    item !== "Fit & Active"
                                                  );
                                                });
                                              setFieldValue("goal", newData);
                                            } else {
                                              const newData = [...values?.goal];
                                              newData.push("Fit & Active");
                                              setFieldValue("goal", newData);
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.fitactive}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Fit & Active
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.goal?.includes(
                                              "Reduce Stress"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.goal?.includes(
                                                "Reduce Stress"
                                              )
                                            ) {
                                              const newData =
                                                values?.goal?.filter(function (
                                                  item
                                                ) {
                                                  return (
                                                    item !== "Reduce Stress"
                                                  );
                                                });
                                              setFieldValue("goal", newData);
                                            } else {
                                              const newData = [...values?.goal];
                                              newData.push("Reduce Stress");
                                              setFieldValue("goal", newData);
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.reducestress}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Reduce Stress
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                    {errors.goal && (
                                      <Index.FormHelperText error>
                                        {errors.goal}
                                      </Index.FormHelperText>
                                    )}
                                  </Index.Box>

                                  <Index.Box className="step-one-next-prev-btn">
                                    <Index.Box className="outline-prev-main">
                                      <Index.BlueBorderButton
                                        btnLabel="Back"
                                        className="blue-outline-btn"
                                        onClick={() => handleBackClick(2)}
                                        type="submit"
                                      />
                                    </Index.Box>
                                    <Index.Box className="next-btn-step">
                                      <Index.PrimaryButton
                                        btnLabel="Next"
                                        className="comman-primary-btn"
                                        type="submit"
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </form>
                              )}
                            </Formik>
                          </Index.Box>

                          {/* step3 end */}

                          {/* step4 start */}
                          <Index.Box
                            className={`step-four-details-content ${
                              activeStep == 4
                                ? "step-block"
                                : "display-none-step"
                            }`}
                          >
                            <Formik
                              enableReinitialize
                              onSubmit={handleFormSubmitStep4}
                              initialValues={initialValuesStep4}
                              validationSchema={validationSchemaStep4}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                setFieldValue,
                                handleBlur,
                                handleSubmit,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  <Index.Box className="step-four-account-details">
                                    <Index.Typography
                                      className="title-health-challenges"
                                      component="h3"
                                      variant="h3"
                                    >
                                      {" "}
                                      Do you have any health challenges?
                                    </Index.Typography>
                                    <Index.Box className="fitness-list-content">
                                      <Index.Box className="fitness-goal-flex ">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.healthChallanges?.includes(
                                              "Underweight"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.healthChallanges?.includes(
                                                "Underweight"
                                              )
                                            ) {
                                              const newData =
                                                values?.healthChallanges?.filter(
                                                  function (item) {
                                                    return (
                                                      item !== "Underweight"
                                                    );
                                                  }
                                                );
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            } else {
                                              const newData = [
                                                ...values?.healthChallanges,
                                              ];
                                              newData.push("Underweight");
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.anorexia}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Underweight
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>

                                      <Index.Box className="fitness-goal-flex ">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.healthChallanges?.includes(
                                              "Overweight"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.healthChallanges?.includes(
                                                "Overweight"
                                              )
                                            ) {
                                              const newData =
                                                values?.healthChallanges?.filter(
                                                  function (item) {
                                                    return (
                                                      item !== "Overweight"
                                                    );
                                                  }
                                                );
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            } else {
                                              const newData = [
                                                ...values?.healthChallanges,
                                              ];
                                              newData.push("Overweight");
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.gainweight}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Overweight
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.healthChallanges?.includes(
                                              "Diabetes"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.healthChallanges?.includes(
                                                "Diabetes"
                                              )
                                            ) {
                                              const newData =
                                                values?.healthChallanges?.filter(
                                                  function (item) {
                                                    return item !== "Diabetes";
                                                  }
                                                );
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            } else {
                                              const newData = [
                                                ...values?.healthChallanges,
                                              ];
                                              newData.push("Diabetes");
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.maintainweight}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Diabetes
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.healthChallanges?.includes(
                                              "Heart Issues"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.healthChallanges?.includes(
                                                "Heart Issues"
                                              )
                                            ) {
                                              const newData =
                                                values?.healthChallanges?.filter(
                                                  function (item) {
                                                    return (
                                                      item !== "Heart Issues"
                                                    );
                                                  }
                                                );
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            } else {
                                              const newData = [
                                                ...values?.healthChallanges,
                                              ];
                                              newData.push("Heart Issues");
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.gainweight}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Heart Issues
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.healthChallanges?.includes(
                                              "Blood Pressure"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.healthChallanges?.includes(
                                                "Blood Pressure"
                                              )
                                            ) {
                                              const newData =
                                                values?.healthChallanges?.filter(
                                                  function (item) {
                                                    return (
                                                      item !== "Blood Pressure"
                                                    );
                                                  }
                                                );
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            } else {
                                              const newData = [
                                                ...values?.healthChallanges,
                                              ];
                                              newData.push("Blood Pressure");
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.gainmuscle}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            {" "}
                                            Blood Pressure
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.healthChallanges?.includes(
                                              "Asthma"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.healthChallanges?.includes(
                                                "Asthma"
                                              )
                                            ) {
                                              const newData =
                                                values?.healthChallanges?.filter(
                                                  function (item) {
                                                    return item !== "Asthma";
                                                  }
                                                );
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            } else {
                                              const newData = [
                                                ...values?.healthChallanges,
                                              ];
                                              newData.push("Asthma");
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            }
                                          }}
                                        >
                                          <Index.Box
                                            className="fitness-goal-img-box"
                                            onClick={() =>
                                              setFieldValue(
                                                "healthChallanges",
                                                values?.healthChallanges?.push(
                                                  "Overweight"
                                                )
                                              )
                                            }
                                          >
                                            <img
                                              src={Index.Svg.increaseenergy}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Asthma
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          className={`fitness-goal-box ${
                                            values?.healthChallanges?.includes(
                                              "Joint Pain"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (
                                              values?.healthChallanges?.includes(
                                                "Joint Pain"
                                              )
                                            ) {
                                              const newData =
                                                values?.healthChallanges?.filter(
                                                  function (item) {
                                                    return (
                                                      item !== "Joint Pain"
                                                    );
                                                  }
                                                );
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            } else {
                                              const newData = [
                                                ...values?.healthChallanges,
                                              ];
                                              newData.push("Joint Pain");
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.fitactive}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Joint Pain
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="fitness-goal-flex">
                                        <Index.Box
                                          // className="fitness-goal-box"
                                          className={`fitness-goal-box ${
                                            values?.healthChallanges?.includes(
                                              "Low Energy"
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            // const newData = values?.healthChallanges?.push('Overweight')
                                            if (
                                              values?.healthChallanges?.includes(
                                                "Low Energy"
                                              )
                                            ) {
                                              const newData =
                                                values?.healthChallanges?.filter(
                                                  function (item) {
                                                    return (
                                                      item !== "Low Energy"
                                                    );
                                                  }
                                                );
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            } else {
                                              const newData = [
                                                ...values?.healthChallanges,
                                              ];
                                              newData.push("Low Energy");
                                              setFieldValue(
                                                "healthChallanges",
                                                newData
                                              );
                                            }
                                          }}
                                        >
                                          <Index.Box className="fitness-goal-img-box">
                                            <img
                                              src={Index.Svg.reducestress}
                                              className="fitness-img"
                                            ></img>
                                          </Index.Box>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="fitness-title-main"
                                          >
                                            Low Energy
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                    {errors.healthChallanges && (
                                      <Index.FormHelperText error>
                                        {errors.healthChallanges}
                                      </Index.FormHelperText>
                                    )}
                                  </Index.Box>

                                  <Index.Box className="step-one-next-prev-btn">
                                    <Index.Box className="outline-prev-main">
                                      <Index.BlueBorderButton
                                        btnLabel="Back"
                                        className="blue-outline-btn"
                                        onClick={() => handleBackClick(3)}
                                      />
                                    </Index.Box>
                                    <Index.Box className="next-btn-step">
                                      <Index.PrimaryButton
                                        btnLabel="Next"
                                        className="comman-primary-btn"
                                        // onClick={() => handleNextClick(5)}
                                        type="submit"
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </form>
                              )}
                            </Formik>
                          </Index.Box>

                          {/* step4 end */}

                          {/* step5 start */}

                          <Index.Box
                            className={`step-five-details-content ${
                              activeStep == 5
                                ? "step-block"
                                : "display-none-step"
                            }`}
                          >
                            <Formik
                              enableReinitialize
                              onSubmit={handleFormSubmitStep5}
                              initialValues={initialValuesStep5}
                              validationSchema={validationSchemaStep5}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                setFieldValue,
                                handleBlur,
                                handleSubmit,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  <Index.Box className="step-five-account-details">
                                    <Index.Box className="step-kyc-verification">
                                      <Index.Box
                                        display="grid"
                                        gridTemplateColumns="repeat(12, 1fr)"
                                        gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                                        className="stepper-signup-grid"
                                        sx={{ margin: 0, alignItems: "center" }}
                                      >
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Select one of the following
                                                identification documents:
                                              </Index.FormHelperText>
                                              <Index.Box className="edit_profile_field">
                                                <Index.Box className="form-group from_login_details">
                                                  <Index.Box className="dropdown-box">
                                                    <Index.FormControl className="form-control">
                                                      <Index.Select
                                                        className="dropdown-select "
                                                        displayEmpty
                                                        inputProps={{
                                                          "aria-label":
                                                            "Without label",
                                                        }}
                                                        defaultValue={
                                                          values?.kycType
                                                        }
                                                        value={values?.kycType}
                                                        onChange={(e) => {
                                                          setFieldValue(
                                                            "kycType",
                                                            e.target.value
                                                          );
                                                        }}
                                                      >
                                                        <Index.MenuItem
                                                          value="Passport"
                                                          className="menuitem"
                                                        >
                                                          Passport
                                                        </Index.MenuItem>
                                                        <Index.MenuItem
                                                          value="Driving License"
                                                          className="menuitem"
                                                        >
                                                          Driving License
                                                        </Index.MenuItem>
                                                        <Index.MenuItem
                                                          value="Government ID"
                                                          className="menuitem"
                                                        >
                                                          Government ID
                                                        </Index.MenuItem>
                                                      </Index.Select>
                                                    </Index.FormControl>
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                              {touched.kycType &&
                                                errors.kycType && (
                                                  <Index.FormHelperText error>
                                                    {errors.kycType}
                                                  </Index.FormHelperText>
                                                )}
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Upload Documnet
                                              </Index.FormHelperText>
                                              <Index.Box className="file-upload-btn-main-user">
                                                <Index.Button
                                                  variant="contained"
                                                  component="label"
                                                  className="file-upload-btn-user"
                                                >
                                                  <input
                                                    hidden
                                                    accept="image/*"
                                                    multiple
                                                    type="file"
                                                    name="kycDocument"
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        "kycDocument",
                                                        e.target.files[0]
                                                      );
                                                    }}
                                                  />
                                                </Index.Button>
                                                <Index.Box className="button-text-main">
                                                  <Index.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="title-upload-main"
                                                  >
                                                    <img
                                                      src={Index.Png.uploadfile}
                                                      className="upload-img"
                                                    />{" "}
                                                    {values?.kycDocument?.name
                                                      ? values?.kycDocument
                                                          ?.name
                                                      : profileData?.kyc
                                                          ?.kycDocument
                                                      ? profileData?.kyc
                                                          ?.kycDocument
                                                      : "Upload Document"}
                                                  </Index.Typography>
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                          {touched.kycDocument && errors.kycDocument && (
                                            <Index.FormHelperText error>
                                              {errors.kycDocument}
                                            </Index.FormHelperText>
                                          )}
                                        </Index.Box>

                                        <Index.Box
                                          gridColumn={{
                                            xs: "span 12",
                                            sm: "span 6",
                                            md: "span 6",
                                            lg: "span 6",
                                          }}
                                          className="grid-column stepper-signup-column mb-10px"
                                        >
                                          <Index.Box className="step-signup-list">
                                            <Index.Box className="form-group custom-group">
                                              <Index.FormHelperText className="form-label">
                                                Unique Id No.
                                              </Index.FormHelperText>
                                              <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                type="number"
                                                className="form-control custom-control"
                                                name="uniqueIdNo"
                                                onBlur={handleBlur}
                                                value={values?.uniqueIdNo}
                                                onChange={handleChange}
                                                helperText={
                                                  touched.uniqueIdNo &&
                                                  errors.uniqueIdNo
                                                }
                                                error={Boolean(
                                                  errors.uniqueIdNo &&
                                                    touched.uniqueIdNo
                                                )}
                                              />
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box className="step-one-next-prev-btn">
                                    <Index.Box className="outline-prev-main">
                                      <Index.BlueBorderButton
                                        btnLabel="Back"
                                        className="blue-outline-btn"
                                        onClick={() => handleBackClick(4)}
                                      />
                                    </Index.Box>

                                    <Index.Box className="next-btn-step">
                                      <Index.PrimaryButton
                                        btnLabel="Submit"
                                        className="comman-primary-btn"
                                        type="submit"
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                </form>
                              )}
                            </Formik>
                          </Index.Box>

                          {/* step5 end */}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={modalOpen}
            // onClose={props.handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete modal"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner congratulations-modal-last-step"
            >
              <Index.Box className="modal-circle-main"></Index.Box>
              <Index.Box className="cong-img-content">
                <img
                  src={Index.Svg.certificate}
                  className="certificate-img"
                ></img>
              </Index.Box>
              <Index.Typography
                className="delete-modal-title"
                component="h2"
                variant="h2"
              >
                Congratulations, you have successfully completed your profile
              </Index.Typography>
              <Index.Box className="delete-modal-btn-flex">
                <Index.Box className="modal-btn-ok ">
                  <Index.PrimaryButton
                    className="comman-primary-btn "
                    onClick={handleModalClose}
                    btnLabel="Ok"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={modalOpenProfileCompletion}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete modal"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner"
            >
              <Index.Box className="modal-circle-main"></Index.Box>
              <Index.Typography
                className="delete-modal-title"
                component="h2"
                variant="h2"
              >
                Please complete your profile and get 5 FTZR tokens
              </Index.Typography>
              <Index.Box className="delete-modal-btn-flex">
                <Index.BlueBorderButton
                  type="submit"
                  className="blue-outline-btn modal-cancel-btn"
                  btnLabel="Later"
                  onClick={() => navigate("/user/dashboard")}
                />
                <Index.Box className="modal-btn-ok ">
                  <Index.PrimaryButton
                    className="comman-primary-btn "
                    onClick={() => {
                      getUserProfileAPICall();
                    }}
                    btnLabel="Now"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        </Index.Box>
      </>
    );
  }
}
