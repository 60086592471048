import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Paper from "@mui/material/Paper";
import { Pagination } from "@mui/material";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import moment from "moment";
import { toast } from "react-toastify";

export default function WalletHistory() {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currentRows, setCurrentRows] = React.useState([]);
  const [walletRow, setWalletRow] = React.useState([]);
  // Pagination calculation start

  const PER_PAGE = 10;
  useEffect(() => {
    //Page count
    const count = Math.ceil(walletRow?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = walletRow?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
  }, [currentPage, walletRow]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // wallet history page

  const walletHistoryApi = async () => {
    try {
      const res = await DataService.get(API.User.WALLET_HISTORY);
      setWalletRow(res.data.data);
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    walletHistoryApi();
  }, []);

  return (
    <>
      <Index.Box className="userdashbord-main">
        <Index.UserHeader />
      </Index.Box>
      <Index.Box className="main-userdashboard-content">
        <Index.Box className="container">
          <Index.Box className="user-dashboard-row">
            <Index.Box className="userdash-col1">
              <Index.Box className="sidebar-main-user-component">
                <Index.UserSidebar />
              </Index.Box>
            </Index.Box>

            <Index.Box className="userdash-col2">
              <Index.Box className="dash-card-components">
                <Index.FitrizerCard />
              </Index.Box>
              <Index.Box className="refferal-program-component">
                <Index.Box className="refferal-main-table">
                  <Index.Box className="box-card-refferal">
                    <Index.Box className="box-pd-refferal">
                      <Index.Box className="flex-title-earn">
                        <Index.Box className="refferal-title-details">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="refferal-title-content"
                          >
                            Wallet <span>History</span>
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="table-refferal-main">
                        <Index.TableContainer
                          component={Paper}
                          className="refferal-main-paper-table"
                        >
                          <Index.Table
                            aria-label="simple table"
                            className="refferal-main-table"
                          >
                            <Index.TableHead className="refferal-head-table">
                              <Index.TableRow className="refferal-row-table">
                                <Index.TableCell className="refferal-cell-table">
                                  Token
                                </Index.TableCell>
                                <Index.TableCell className="refferal-cell-table">
                                  Type
                                </Index.TableCell>
                                <Index.TableCell className="refferal-cell-table">
                                  Transaction Type
                                </Index.TableCell>
                                <Index.TableCell className="refferal-cell-table">
                                  Create Date & Time
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="refferal-body-table">
                              {currentRows?.length ? (
                                currentRows?.map((row, index) => (
                                  <Index.TableRow
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                    className="refferal-datarow-table"
                                  >
                                    <Index.TableCell
                                      component="th"
                                      scope="row"
                                      className="refferal-datacell-table"
                                    >
                                      <Index.Box className="refferal-namelist">
                                        <Index.Typography
                                          className="table-refferal-name"
                                          component="p"
                                          variant="p"
                                        >
                                          {row?.TxnAmount}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.TableCell>
                                    <Index.TableCell className="refferal-datacell-table">
                                      <Index.Box className="email-refferal">
                                        {row?.TxnType}
                                      </Index.Box>
                                    </Index.TableCell>
                                    <Index.TableCell className="refferal-datacell-table">
                                      <Index.Box className="email-refferal">
                                        {row?.TxnFor == "SignupProfileCompletionToken"?"Profile Completion":(row?.TxnFor == "ReferralProfileCompletionToken"?"Refferal - Profile Completion":row?.TxnFor)}
                                      </Index.Box>
                                    </Index.TableCell>
                                    <Index.TableCell className="refferal-datacell-table">
                                      <Index.Box className="prices-refferal">
                                        {row?.TxnDateAndTime}
                                        {/* {moment(
                                          row?.createdAt? row?.createdAt: "").format("DD/MM/YYYY,   h:mm:ss a")
                                        }  */}
                                      </Index.Box>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : (
                                <Index.TableRow>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="no-data-in-list"
                                    colSpan={6}
                                    align="center"
                                  >
                                    No data found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                        {currentRows?.length > 0 ? (
                          <Index.Box className="pagination-main">
                            <Pagination
                              count={count}
                              page={currentPage}
                              onChange={handlePageChange}
                              variant="outlined"
                              shape="rounded"
                              className="pagination"
                            />
                          </Index.Box>
                        ) : (
                          <></>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
