import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Index from "../../../Index";

import { getUserProfile } from "../../../../redux/features/slices/User/UserService";
import ReactSpeedometer from "react-d3-speedometer";

import Loader from "../../../../component/common/Loader";
import { toast } from "react-toastify";

export default function BMIReport() {
  const [loading, setLoading] = useState(false);
  const [bmiScore, setBmiScore] = useState(0.0);
  const [chartScore, setChartScore] = useState(0.0);

  const dispatch = useDispatch();

  // Convert Foot/Inch to meter
  function convertFitInchToMeter(foot, inch) {
    var inch = inch * 2.54;
    var foot = foot * 30.48;
    if (inch) {
      var result = inch + foot;
    } else {
      var result = foot;
    }
    return result / 100;
  }

  // Convert Lbs/oz to KG
  function lbsAndOzToKg(lbs, oz) {
    // Convert lbs and oz to kg
    var totalOz = lbs * 16 + oz; // Convert lbs to oz and add the remaining oz
    var kg = totalOz * 0.0283495; // Convert oz to kg

    return kg.toFixed(2); // Return the result rounded to 2 decimal places
  }

  function kgAndGramToKg(kg, gram) {
    // Convert gram to kg and add to kg
    var totalKg = kg + gram / 1000;

    return totalKg.toFixed(2); // Return the result rounded to 2 decimal places
  }

  useEffect(() => {
    setLoading(true);
    dispatch(getUserProfile()).then((response) => {
      if (response?.payload?.data) {
        // setProfile(response?.payload?.data?.isProfileUpdated);
        if (response?.payload?.data?.height?.unit) {
          if (response?.payload?.data?.height?.unit == "ft") {
            const weight = response?.payload?.data?.weight?.value;
            const foot = response?.payload?.data?.height?.fit;
            const inch = response?.payload?.data?.height?.inch;

            let finalWeight = 0;
            if (response?.payload?.data?.weight?.unit == "lbs") {
              const lbs = response?.payload?.data?.weight?.lbs;
              const oz = response?.payload?.data?.weight?.oz;
              finalWeight = lbsAndOzToKg(lbs, oz);
            } else {
              const kg = response?.payload?.data?.weight?.kilo;
              const gram = response?.payload?.data?.weight?.gram;
              finalWeight = kgAndGramToKg(kg, gram);
            }

            // console.log(finalWeight,"finalWeight","heightcheck");
            if (foot && finalWeight) {
              const heightInMeter = convertFitInchToMeter(foot, inch);
              const BMIScoreValue =
                finalWeight / (heightInMeter * heightInMeter);
              // console.log(BMITScore,"BMITScore");
              if (BMIScoreValue) {
                // setBmiScore(BMIScoreValue.toFixed(2));
                setBmiScore(Math.round(BMIScoreValue));
              }
            }
          }

          if (response?.payload?.data?.height?.unit == "cms") {
            const weight = response?.payload?.data?.weight?.value;
            const cm = response?.payload?.data?.height?.cm;

            let finalWeight = 0;
            if (response?.payload?.data?.weight?.unit == "lbs") {
              const lbs = response?.payload?.data?.weight?.lbs;
              const oz = response?.payload?.data?.weight?.oz;
              finalWeight = lbsAndOzToKg(lbs, oz);
            } else {
              const kg = response?.payload?.data?.weight?.kilo;
              const gram = response?.payload?.data?.weight?.gram;
              finalWeight = kgAndGramToKg(kg, gram);
            }

            if (cm && finalWeight) {
              const heightInMeter = cm / 100;
              console.log(heightInMeter, "heightInMeter", "heightcheck");
              const BMIScoreValue =
                finalWeight / (heightInMeter * heightInMeter);
              if (BMIScoreValue) {
                setBmiScore(Math.round(BMIScoreValue));
              }
            }
          }
        }

        setLoading(false);
      } else {
        toast.error("Something went wrong");
      }
    });
  }, []);

  useEffect(() => {
    if (bmiScore < 18.5) {
      if (bmiScore > 0) {
        if (bmiScore > 0 && bmiScore <= 9) {
          setChartScore(7);
        } else {
          setChartScore(18);
        }
      } else {
        setChartScore(0);
      }

      // setChartScore(12.5);
    }
    if (bmiScore >= 18.5 && bmiScore <= 24.9) {
      if (bmiScore >= 18.5 && bmiScore <= 21) {
        setChartScore(32);
      } else {
        setChartScore(44);
      }
      // setChartScore(37.5);
    }
    if (bmiScore >= 25 && bmiScore <= 29.9) {
      if (bmiScore >= 25 && bmiScore <= 27) {
        setChartScore(56);
      } else {
        setChartScore(69);
      }
      // setChartScore(62.5);
    }
    if (bmiScore > 30) {
      setChartScore(87.5);
    }
  }, [bmiScore]);

  if (loading) {
    return <Loader></Loader>;
  } else {
    return (
      <>
        <Index.Box className="userdashbord-main">
          <Index.UserHeader />
        </Index.Box>

        <Index.Box className="main-userdashboard-content">
          <Index.Box className="container">
            <Index.Box className="user-dashboard-row">
              <Index.Box className="userdash-col1">
                <Index.Box className="sidebar-main-user-component">
                  <Index.UserSidebar />
                </Index.Box>
              </Index.Box>

              <Index.Box className="userdash-col2">
                <Index.Box className="dash-card-components">
                  <Index.FitrizerCard />
                </Index.Box>
                <Index.Box className="refferal-program-component">
                  <Index.Box className="refferal-main-table">
                    <Index.Box className="box-card-refferal">
                      <Index.Box className="box-pd-refferal">
                        <Index.Box className="flex-title-earn">
                          <Index.Box className="refferal-title-details flex-bmi-main">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="refferal-title-content"
                            >
                              Your BMI Report
                              {/* Your BMI Report is <span>0.00</span> */}
                            </Index.Typography>
                            <Index.Box className="refferal-score">
                              <span>{bmiScore}</span>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="report-flex">
                          <ReactSpeedometer
                            maxValue={100}
                            width={400}
                            customSegmentStops={[0, 25, 50, 75, 100]}
                            value={chartScore}
                            currentValueText="BMI Report"
                            customSegmentLabels={[
                              {
                                // text: "Underweight",
                                position: "INSIDE",
                                color: "#555",
                              },
                              {
                                // text: "Normal Weight",
                                position: "INSIDE",
                                color: "#555",
                              },
                              {
                                // text: "Overweight",
                                position: "INSIDE",
                                color: "#555",
                                // fontSize: "14px",
                              },
                              {
                                // text: "Obese",
                                position: "INSIDE",
                                color: "#555",
                              },
                            ]}
                          />
                          <Index.Box className="report-highlight-list">
                            <Index.List className="list-report-hgtlight">
                              <Index.ListItem className="listitem-report-hightlight">
                                <Index.Box className="box-report-main orange-bg"></Index.Box>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="text-report-content"
                                >
                                  Underweight
                                </Index.Typography>
                              </Index.ListItem>
                              <Index.ListItem className="listitem-report-hightlight">
                                <Index.Box className="box-report-main light-orange-bg"></Index.Box>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="text-report-content"
                                >
                                  Normal Weight
                                </Index.Typography>
                              </Index.ListItem>
                              <Index.ListItem className="listitem-report-hightlight">
                                <Index.Box className="box-report-main light-green-bg"></Index.Box>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="text-report-content"
                                >
                                  Overweight
                                </Index.Typography>
                              </Index.ListItem>
                              <Index.ListItem className="listitem-report-hightlight">
                                <Index.Box className="box-report-main green-bg"></Index.Box>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="text-report-content"
                                >
                                  Obese
                                </Index.Typography>
                              </Index.ListItem>
                            </Index.List>
                          </Index.Box>
                        </Index.Box>
                        {/* Bmi Report generate - keep commented for now, need later on */}
                        {/* <Index.Box className="bmi-report-main-content">
                          <Index.Box sx={{ width: 1 }} className="grid-main">
                            <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 0, lg: 0 }} className="stepper-signup-grid">
                              <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 3" }} className="grid-column  stepper-signup-column mb-10p">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Height (In Meters)
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    type="number"
                                    id="fullWidth"
                                    className="form-control custom-control"

                                  />
                                </Index.Box>
                              </Index.Box>
                              <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 3" }} className="grid-column  stepper-signup-column mb-10p">
                                <Index.Box className="form-group custom-group">
                                  <Index.FormHelperText className="form-label">
                                    Weight (In Kilograms)
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    type="number"
                                    id="fullWidth"
                                    className="form-control custom-control"

                                  />
                                </Index.Box>
                              </Index.Box>
                              <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 3" }} className="grid-column  stepper-signup-column mb-10p">
                                <Index.Box className="bms-btn-content">
                                  <Index.PrimaryButton
                                    btnLabel="Compute BMI"
                                    className="comman-primary-btn"
                                    type="submit"
                                  />
                                </Index.Box>

                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="bmi-repot-main-chart">
                           
                          </Index.Box>
                        </Index.Box> */}

                        {/* Bmi Report generate */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </>
    );
  }
}
