import React from 'react'
import Index from '../Index'

export default function BlueBorderButton(props) {
    return (
        <>
            <Index.Box className="blue-border-btn-main">
                <Index.Button className={props.className} onClick={props.onClick} disableRipple>{props.btnLabel}</Index.Button>
            </Index.Box>


        </>


    )
}
