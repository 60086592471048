import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { DataService } from "../config/DataService";

const PrivateRoutes = () => {
  // Check token expiry
  const isValidToken = (adminToken) => {
    if (!adminToken) return false;
    DataService.defaults.headers.common.auth = adminToken;
    return true;
  };

  const adminToken = useSelector((store) => store.admin.token);
  console.log(adminToken, "adminToken");
  return isValidToken(adminToken) ? <Outlet /> : <Navigate to="/admin/login" />;
};

export default PrivateRoutes;
