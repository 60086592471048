import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const Profile = () => {
  const [profileData, setProfileData] = useState();
  const token = useSelector((store) => store.admin.token);

  let initialValues = {
    adminName: profileData?.adminName,
    email: profileData?.email,
    phoneNumber: profileData?.phoneNumber,
  };

  // Validation schema
  const textAndSpaceOnly = (value) =>
    /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value) || value.length === 0;
  const validationSchema = Yup.object().shape({
    adminName: Yup.string()
      .required("Name is required")
      .test(
        "adminName",
        "Name allows only characters and space between",
        textAndSpaceOnly
      )
      .max(20, "Full Name allows maximum length 20"),
    email: Yup.string()
      .required("Email is required")
      .email("Email is not valid")
      .matches(
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
        "Email is not valid"
      ),
    phoneNumber: Yup.string()
      .required()
      .min(10, "Mobile Number minimum length must be 10"),
  });

  const handleFormSubmit = async (values) => {
    await DataService.post(API.Admin.EDIT_PROFILE, values, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          toast.success("Profile updated successffully");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  const getProfile = async () => {
    await DataService.get(API.Admin.PROFILE_DATA, {
      headers: {
        auth: token,
      },
    }).then((response) => {
      let res = response?.data?.data;
      setProfileData(response?.data?.data);
    });
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <Index.Box className="tabpanel-main">
      <Index.Box className="add-user-data-main">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          name="adminName"
                          value={values?.adminName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 20 }}
                          helperText={touched.adminName && errors.adminName}
                          error={Boolean(errors.adminName && touched.adminName)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Email
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          name="email"
                          value={values?.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          helperText={touched.email && errors.email}
                          error={Boolean(errors.email && touched.email)}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Phone Number
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          type="number"
                          className="form-control"
                          name="email"
                          value={values?.phoneNumber}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            if (e.target.value.toString().length <= 10) {
                              setFieldValue("phoneNumber", e.target.value);
                            }
                          }}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          error={Boolean(
                            errors.phoneNumber && touched.phoneNumber
                          )}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="user-btn-flex comman-btn-main">
                      <Index.Box className="save-btn-main ">
                        <Index.Button
                          className="save-user-btn primary-btn"
                          type="submit"
                        >
                          <img
                            src={Index.Svg.save}
                            alt="save"
                            className="user-save-icon"
                          ></img>
                          Save
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default Profile;
