import React, { useState } from "react";
import Index from "../../Index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminLogout } from "../../../redux/features/slices/Admin/AdminSlice";

export default function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const adminLogoutAction = () => {
    dispatch(adminLogout());
    navigate("/admin/login");
  };

  const openBurgerMenu = () => {
    var element = document.getElementById("admin-sidebar");
    element.classList.add("active");
    var body = document.getElementsByTagName("body");
    body[0].classList.add("body-overflow");
  };
  return (
    <>
      <Index.Box className="admin-header-main">
        <Index.Box className="admin-header-row">
          <Index.Button className="mobile-menu-btn" onClick={openBurgerMenu}>
            <img
              src={Index.Png.menuburger}
              className="admin-mobile-menu-icon"
              alt="logo"
            />
          </Index.Button>

          <Index.Box className="admin-header-profile-box">
            <Index.Button
              className="drop-header-btn"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              disableRipple
            >
              <Index.Box className="flex-drop-main">
                <img
                  src={Index.Png.usericon}
                  className="admin-header-profile-icon"
                  alt="dashboard bell icon"
                ></img>
                <Index.Box className="title-admin-drop">
                  <Index.Typography
                    variant="h5"
                    component="h5"
                    className="admin-header-drop"
                  >
                    Admin
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Button>
            <Index.Menu
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Index.Link to="/admin/edit-profile" className="text-decoration">
                <Index.MenuItem
                  onClick={handleClose}
                  className="drop-header-menuitem"
                >
                  {" "}
                  <img src={Index.Png.profile} className="drop-header" />
                  Edit Profile{" "}
                </Index.MenuItem>
              </Index.Link>

              {/* <Index.Link to="/admin/login" className="text-decoration"> */}
                {" "}
                <Index.MenuItem
                  onClick={adminLogoutAction}
                  className="drop-header-menuitem"
                >
                  {" "}
                  <img src={Index.Png.logout} className="drop-header" />
                  Log Out{" "}
                </Index.MenuItem>
              {/* </Index.Link> */}
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
