import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import { useNavigate, useParams } from "react-router-dom";
import { DataService } from "../../../../config/DataService";
import { toast } from "react-toastify";
import { API } from "../../../../config/Api";
import message from "../../../../ValidationMessage.json";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
} from "../../../../redux/features/slices/Admin/AdminSlice";
// editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    color: "rgba(0, 0, 0, 0.6)",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["Poppins-Regular"].join(","),
    "&:focus": {
      borderRadius: 3,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default function AddRoadMap() {
  const [editData, setEditData] = useState("");
  const [editorData, setEditorData] = useState("");

  const handelChange = (e, editor) => {
    setEditData(ReactHtmlParser(editor.getData()));
    let editorDataList = ReactHtmlParser(
      editor.getData()
    )[0].props.children.toString();
    setFields({ ...fields, ["description"]: editorDataList });
    if (editorDataList) {
      validateDescription(editorDataList);
    }
  };
  const [singleData, setSingleData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState({
    descError: "",
    yearError: "",
    quarterError: "",
  });
  const [fields, setFields] = useState({});
  const { editId } = useParams();
  const token = useSelector((store) => store.admin.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getSingleData = async () => {
    const formData = new URLSearchParams();
    formData.append("id", editId);
    await DataService.post(API.Admin.ROADMAP_LIST, formData, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        setSingleData(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/admin/login");
        }
      });
  };

  useEffect(() => {
    getSingleData();
  }, [editId]);

  useEffect(() => {
    const initialValues = {
      description: editId !== undefined ? singleData?.description : null,
      year: editId !== undefined ? singleData?.year : "",
      quarter: editId !== undefined ? singleData?.quarter : "1",
    };
    setFields(initialValues);
    setEditorData(ReactHtmlParser(initialValues.description)[0]);
  }, [singleData]);

  const validateDescription = (name) => {
    if (!name) {
      setError({
        ...error,
        descError: message.ROADMAP_DESC,
      });
      return true;
    } else {
      setError({
        ...error,
        descError: "",
      });
      return false;
    }
  };

  const validateYear = (year) => {
    const regex = /\b\d{4}\b/;
    if (!year) {
      setError({
        ...error,
        yearError: message.ROADMAP_YEAR,
      });
      return true;
    } else if (!regex.test(year)) {
      setError({
        ...error,
        yearError: message.VALID_YEAR,
      });
      return true;
    } else {
      setError({
        ...error,
        yearError: "",
      });
      return false;
    }
  };

  const validateQuarter = (quarter) => {
    const regex = /^([1-9]|1[012])$/.test(quarter);
    if (!quarter) {
      setError({
        ...error,
        quarterError: message.ROADMAP_QUATER,
      });
      return true;
    } else if (!regex) {
      setError({
        ...error,
        quarterError: "Please enter valid Quarter",
      });
      return true;
    } else {
      setError({
        ...error,
        quarterError: "",
      });
      return false;
    }
  };

  const onHandleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "year") {
      validateYear(value);
    }
    if (name === "quarter") {
      validateQuarter(value);
    }

    setFields({
      ...fields,
      [name]: value,
    });
  };

  const onAddEdit = async () => {
    setIsFetching(true);
    if (
      validateDescription(fields.description) ||
      validateYear(fields.year) ||
      validateQuarter(fields.quarter)
    ) {
      onEmpty();
    } else {
      const formData = new URLSearchParams();
      formData.append("description", fields.description);
      formData.append("year", fields.year);
      formData.append("quarter", "1");
      if (editId !== undefined) {
        formData.append("id", editId);
      }
      await DataService.post(API.Admin.ROAD_ADD_EDIT, formData, {
        headers: {
          auth: token,
        },
      })
        .then((res) => {
          setIsFetching(false);
          if (editId !== undefined) {
            toast.success(res.data.message);
          } else {
            toast.success(res.data.message);
          }
          setTimeout(() => {
            navigate("/admin/road-map");
          }, 1500);
        })
        .catch((error) => {
          setIsFetching(false);
          toast.error(error.response.data.message);
          if (error.response.data.message === "Token not authorized") {
            dispatch(getAdminToken(""));
            dispatch(getAdminType(""));
            dispatch(getAdminData({}));
            navigate("/admin/login");
          }
        });
    }
  };

  const onEmpty = () => {
    if (fields.year === "") {
      setError((prev) => ({ ...prev, yearError: "Please Enter year" }));
    }
    if (fields.quarter === "") {
      setError((prev) => ({
        ...prev,
        quarterError: "Please Enter Quarter",
      }));
    }
  };

  return (
    <div>
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
                style={{ marginBottom: 24 }}
              >
                {editId === undefined ? "Add" : "Edit"} Road Map
              </Index.Typography>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          Description
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          {editorData && (
                            <>
                              {" "}
                              <CKEditor
                                editor={ClassicEditor}
                                name="description"
                                data={editorData}
                                id="user"
                                autoFocus
                                onChange={(e, editor) => {
                                  handelChange(e, editor);
                                }}
                              />{" "}
                            </>
                          )}
                          {!editorData && (
                            <>
                              {" "}
                              <CKEditor
                                editor={ClassicEditor}
                                name="description"
                                id="user"
                                autoFocus
                                onChange={(e, editor) => {
                                  handelChange(e, editor);
                                }}
                              />{" "}
                            </>
                          )}
                        </Index.Box>
                        <p className="error-text">{error.descError}</p>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable" for="user">
                          Year
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            name="year"
                            id="user"
                            className="form-control"
                            value={fields.year}
                            inputProps={{ maxLength: 12 }}
                            onChange={(e) => onHandleInputChange(e)}
                            placeholder=""
                          />
                        </Index.Box>
                        <p className="error-text">{error.yearError}</p>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="submit-btn-main border-btn-main">
                        <Index.Button
                          className="submit-user-btn border-btn"
                          disableRipple
                          type="button"
                          onClick={onAddEdit}
                        >
                          {editorData ? <> Update </> : <>Submit</>}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
