import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import moment from "moment";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";
import {
  getAdminData,
  getAdminToken,
  getAdminType,
} from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;

const Wallet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState([]);
  const [wallet, setWallet] = useState([]);

  const token = useSelector((store) => store.admin.token);

  const getWallet = async () => {
    await DataService.get(API.Admin.GET_WALLET, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        const walletData = response.data.data.map((items) => {
          return {
            id: items?._id ? items?._id : null,
            totalTokens: items?.totalTokens ? items?.totalTokens : null,
          };
        });
        setWallet(walletData);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };
  useEffect(() => {
    getWallet();
  }, []);

  const getTransaction = async () => {
    await DataService.get(API.Admin.GET_ALL_TRANSACTION, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        const rows = response.data.data.map((items) => {
          return {
            id: items?._id ? items?._id : null,
            TxnAmount: items?.TxnAmount ? items?.TxnAmount : null,
            TxnType: items?.TxnType ? items?.TxnType : null,

            email:
              (items?.TxnFor == "SignUp" ||
                items?.TxnFor == "Refferal" ||
                items?.TxnFor == "ReferralProfileCompletionToken" ||
                items?.TxnFor == "SignupProfileCompletionToken") &&
              items.TxnType == "Credit"
                ? items?.TO?.email
                : items?.From?.email,

            createdAt: moment(
              items?.createdAt ? items?.createdAt : null
            ).format("DD/MM/YYYY,   h:mm:ss a"),
          };
        });
        setTransactionData(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };
  useEffect(() => {
    getTransaction();
  }, []);

  let initialValues = {
    totalTokens: "",
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    totalTokens: Yup.number().required("Please enter token amount"),
  });

  const handleFormSubmit = async (values, { resetForm }) => {
    await DataService.post(API.Admin.ADD_EDIT_USER_WALLET, values, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        toast.success(response.data.message);
        getTransaction();
        getWallet();
        resetForm();
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/");
        }
      });
  };
  const columns = [
    {
      field: "TxnAmount",
      headerName: "Token",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "TxnType",
      headerName: "Type",
    },
    {
      field: "createdAt",
      headerName: "Create Date",
    },
  ];
  return (
    <>
      <Index.Box className="dashboard-containt-main">
        <Index.Box className="dashboard-main">
          <Index.Box className="dashboard-left-main">
            <Index.Sidebar />
          </Index.Box>
          <Index.Box className="dashboard-right-main">
            <Index.Header />
            <Index.Typography
              className="admin-page-title setting-title"
              component="h4"
              variant="h4"
            >
              Transaction History
            </Index.Typography>
            <Index.Box className="tabpanel-main">
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Formik
                    enableReinitialize
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      setFieldValue,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Index.Box
                          display="grid"
                          className="display-row"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Wallet Token
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  type="number"
                                  className="form-control"
                                  name="totalTokens"
                                  onBlur={handleBlur}
                                  placeholder="Please enter token"
                                  value={values?.totalTokens}
                                  onChange={handleChange}
                                  helperText={
                                    touched.totalTokens
                                      ? errors.totalTokens
                                      : undefined
                                  }
                                  error={Boolean(
                                    errors.totalTokens
                                      ? touched.totalTokens
                                      : undefined
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Index.Box className="comman-btn-main">
                              <Index.Box className="save-btn-main ">
                                <Index.Button
                                  className="save-user-btn primary-btn"
                                  type="submit"
                                >
                                  <img
                                    src={Index.Svg.plus}
                                    alt="save"
                                    className="user-save-icon"
                                  ></img>
                                  Add
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="form-group">
                              <Index.Typography>
                                Total Tokens : {wallet[0]?.totalTokens}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </form>
                    )}
                  </Formik>
                </Index.Box>

                <Index.Box sx={{ width: "100%" }} className="add-user-height">
                  <Index.DataGrid
                    className="user-data-head  wallet-table-list"
                    rows={transactionData}
                    columns={columns}
                    getRowId={(transactionData) => transactionData?.id}
                    pageSize={10}
                    autoHeight={true}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Wallet;
