import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "../../../../component/Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import UserDetails from "./UserDetails";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";

const ITEM_HEIGHT = 48;
export default function View() {
  const {editId} = useParams()
  console.log(editId,'editId16')
  const token = useSelector((store) => store.admin.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  console.log(userInfo,'userInfo23')
  const [refferalId, setRefferalId] = useState("");
  const location = useLocation();
  const [users1, setUsers1] = useState([]);
  const [users2, setUsers2] = useState([]);
  const [users3, setUsers3] = useState([]);
  const [users5, setUsers5] = useState([]);
  const [users4, setUsers4] = useState([]);
  const [getBalance, setGetBalance] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const getbalance = async () => {
    setIsFetching(true);
    await DataService.post(
      API.Admin.GET_BALANCE_ADMIN,
      { id: location?.state?.id },
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((response) => {
        console.log(response,'response52')
        setIsFetching(false);
        setGetBalance(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };

  const checklevel = (item) => {
    let res = "";

    if (item === 0) {
      res = "Pay With USDT";
      return res;
    } else if (item === 1) {
      res = "Pay with any Tokens";
      return res;
    } else {
      res = "-";
      return res;
    }
  };

  const tranxType = (item) => {
    let res = "";

    if (item === 0) {
      res = "Purchase";
      return res;
    } else if (item === 1) {
      res = "Withdraw";
      return res;
    } else {
      res = "-";
      return res;
    }
  };

  const fetchData1 = async () => {
    setIsFetching(true);
    await DataService.post(
      API.Admin.PURCHASE_HISTORY,
      { UserId: location?.state?.id },
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((res) => {
        console.log(res,108)
        setIsFetching(false);
        const rows = res.data.data.map((item) => {
          let re = checklevel(item.paymentType);
          return {
            tranxId: item?.tranxId,
            userName: item?.UserId.userName,
            clientId: item?.UserId?.ncxUserId,
            email: item.UserId.email,
            roundId: item?.roundId?.phaseName,
            ncxtCreditedToken: item?.ncxtCreditedToken,
            usdtAmount: item.requestUsdt,
            requestedToken: item?.requestToken,
            bonus: item?.bonusToken,
            paymentType: re,
            total: item.totalToken,
            status: item.status,
            tranxIdNcx: item.tranxIdOnNcx,
            usdtTaken: item.requestUsdt,
            status: item.status,
            proceedBy: item.processedBy?.adminName,
            updatedAt: item?.updatedAt,
            createdAt: item?.createdAt,
            id: item._id,
          };
        });
        setUsers1(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };

  const fetchData2 = async () => {
    setIsFetching(true);
    await DataService.post(
      API.Admin.REFFERAL,
      { refferalId: location?.state?.id },
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((res) => {
        setIsFetching(false);
        const rows = res.data.data.map((item) => {
          return {
            name: item?.userName ? item?.userName : "-",
            ncxUserId: item?.ncxUserId ? item?.ncxUserId : "-",
            id: item._id,
            email: item?.email ? item?.email : "-",
            countryId: item?.countryId?.countryName,
            purchasedOnNcx: item?.purchasedOnNcx === true ? "Purchase" : "Not Purchase",
            // rewardOnKyc: item?.rewardOnKyc,
            Register: item?.registeredOnNcx === true ? "Yes" : "No",
            twoFactor: item?.twoFactor === true ? "Yes" : "No",
            countryIds: item?.countryId?._id,
            refferalId: item?._id,
          };
        });
        setUsers2(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };

  const withdrawData = async () => {
    setIsFetching(true);
    await DataService.post(
      API.Admin.WITHDRAWLE_HISTORY,
      { UserId: location?.state?.id },
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((res) => {
        setIsFetching(false);
        const rows5 = res.data.data.map((item) => {
          let re = checklevel(item?.paymentType);
          return {
            tranxId: item?.tranxId ? item?.tranxId : "-",
            ncxUserId: item?.UserId?.ncxUserId ? item?.UserId?.ncxUserId : "-",
            tranxIdOnNcx: item?.tranxIdOnNcx ? item?.tranxIdOnNcx : "-",
            phaseName: item?.roundId?.phaseName
              ? item?.roundId?.phaseName
              : "-",
            requestToken: item?.requestToken ? item?.requestToken : "-",
            monthlyReleaseAmount: item?.monthlyReleaseAmount
              ? item?.monthlyReleaseAmount
              : "-",
            receivedToken: item?.receivedToken ? item?.receivedToken : "-",
            purchasedNcxToken: item?.token,
            requestUsdt: item?.requestUsdt ? item?.requestUsdt : "-",
            receivedUsdt: item?.receivedUsdt ? item?.receivedUsdt : "-",
            paymentType: re,
            locked: item?.locked ? item?.lockedAmount : "-",
            totalToken: item?.totalToken ? item?.totalToken : "-",
            processedBy: item?.processedBy?.adminName
              ? item?.processedBy?.adminName
              : "-",
            withdraw: item?.withdraw ? item?.withdraw : "-",
            id: item._id,
          };
        });
        setUsers5(rows5);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };

  const roundInfoData = async () => {
    setIsFetching(true);
    await DataService.post(
      API.Admin.PURCHASE_HISTORY,
      { UserId: location?.state?.id },
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((res) => {
        setIsFetching(false);
        const rows3 = res.data.data.map((item) => {
          let re = checklevel(item?.paymentType);
          return {
            tranxId: item?.tranxId ? item?.tranxId : "-",
            ncxUserId: item?.UserId?.ncxUserId ? item?.UserId?.ncxUserId : "-",
            tranxIdOnNcx: item?.tranxIdOnNcx ? item?.tranxIdOnNcx : "-",
            phaseName: item?.roundId?.phaseName,
            receivedToken: item?.receivedToken,
            purchasedNcxToken: item?.token,
            requestUsdt: item?.requestUsdt,
            receivedUsdt: item?.receivedUsdt,
            paymentType: re,
            locked: item?.locked,
            totalToken: item?.totalToken,
            processedBy: item?.processedBy?.adminName
              ? item?.processedBy?.adminName
              : "-",
            withdraw: item?.withdraw ? item?.withdraw : "-",
            id: item._id,
          };
        });
        setUsers3(rows3);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };

  const fetchTransactionHistoryData = async () => {
    setIsFetching(true);
    await DataService.post(
      API.Admin.TRANSACTION_HISTORY,
      { UserId: location?.state?.id },
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((res) => {
        setIsFetching(false);
        const rows = res.data.data.map((item) => {
          let tranxTyp = tranxType(item?.txnType);
          return {
            tranxId: item?.tranxId ? item?.tranxId : "-",
            ncxUserId: item?.UserId?.ncxUserId ? item?.UserId?.ncxUserId : "-",
            tranxIdOnNcx: item?.tranxIdOnNcx ? item?.tranxIdOnNcx : "-",
            round: item?.roundId?.phaseName ? item?.roundId?.phaseName : "-",
            txnType: tranxTyp,
            requestUsdt: item?.requestUsdt ? item?.requestUsdt : "-",
            receivedUsdt: item?.receivedUsdt ? item?.receivedUsdt : "-",
            receivedToken: item?.receivedToken ? item?.receivedToken : "-",
            bonusToken: item?.bonusToken ? item?.bonusToken : "-",
            totalToken: item?.UserId?.reward ? item?.UserId?.reward : "-",
            monthlyReleaseAmount: item?.monthlyReleaseAmount
              ? item?.monthlyReleaseAmount
              : "-",
            unlockDate: item?.unlockDate ? item?.unlockDate : "-",
            locked: item?.lockedAmount
              ? item?.lockedAmount
              : "-",
            withdrawn: item?.UserId?.withdraw,
            id: item._id,
          };
        });
        setUsers4(rows);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };

  const getSingleInfo = async () => {
    setIsFetching(true);
    await DataService.get(
      // API.Admin.GET_SINGLE_USER_INFO,
      API.Admin.GET_USER_LIST,
      // { id: location?.state?.id },
      {
        headers: {
          auth: token,
        },
      }
    )
      .then((res) => {
        console.log(res.data.data,346)
        const viewSingleUser = res.data.data.filter((e)=>{return e._id == editId})
        console.log(viewSingleUser,348)
        setIsFetching(false);
        // setUserInfo(res?.data?.data);
        setUserInfo(viewSingleUser[0]);
      })
      .catch((error) => {
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  };

  const options = [
    <Index.Button
      variant="contained"
      onClick={() => {
        navigate(`/admin/referrals`, { state: { id: refferalId } });
      }}
    >
      Referrals
    </Index.Button>,
  ];

  const columns = [
    {
      field: "tranxId",
      headerName: "TranxId",
      width: "150",
    },
    {
      field: "phaseName",
      headerName: "Round Name",
      width: "150",
    },
    {
      field: "ncxUserId",
      headerName: "Client Id",
      width: "150",
    },
    {
      field: "userName",
      headerName: "Name Surname",
      width: "150",
    },
    {
      field: "email",
      headerName: "Email",
      width: "150",
    },
    {
      field: "requestToken",
      headerName: "Request NCXT",
      width: "150",
    },
    {
      field: "status",
      headerName: "Status",
      width: "150",
    },
    {
      field: "tranxIdOnNcx",
      headerName: "Tranx ID NCX",
      width: "150",
    },
    {
      field: "receivedToken",
      headerName: "NCXT Recieved",
      width: "150",
    },
    {
      field: "processedBy",
      headerName: "Processed By",
      width: "110",
    },
    {
      field: "updatedAt",
      headerName: "Processed Date",
      width: "150",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "createdAt",
      headerName: " Created Date",
      width: "150",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
  ];
  const columns1 = [
    {
      field: "tranxId",
      headerName: "TranxId",
      width: "110",
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: "110",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "clientId",
      headerName: "Client ID",
      width: "110",
    },
    {
      field: "userName",
      headerName: "Name",
      width: "130",
    },
    {
      field: "email",
      headerName: "Email",
      width: "150",
    },
    {
      field: "roundId",
      headerName: "Round Name",
      width: "120",
    },
    {
      field: "usdtAmount",
      headerName: "USDT Amount",
      width: "100",
    },
    {
      field: "requestedToken",
      headerName: "Requested Token",
      width: "100",
    },

    {
      field: "bonus",
      headerName: "Bonus",
      width: "100",
    },
    {
      field: "paymentType",
      headerName: "Payment Type",
      width: "100",
    },
    {
      field: "total",
      headerName: "Total",
      width: "100",
    },
    {
      field: "status",
      headerName: "Status",
      width: "150",
    },
    {
      field: "tranxIdNcx",
      headerName: "Tranx ID NCX",
      width: "150",
    },
    {
      field: "usdtTaken",
      headerName: "USDT Taken",
      width: "150",
    },
    {
      field: "updatedAt",
      headerName: "Processed Date",
      width: "150",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "ncxtCreditedToken",
      headerName: "NCXT Credited",
      width: "100",
    },
    {
      field: "proceedBy",
      headerName: "Proceed By",
      width: "100",
    },
  ];
  const columns2 = [
    {
      field: "ncxUserId",
      headerName: "Client ID",
      width: "130",
    },
    {
      field: "name",
      headerName: "User Name",
      width: "130",
    },
    {
      field: "email",
      headerName: "Email",
      width: "180",
    },
    {
      field: "countryId",
      headerName: "Country",
      width: "100",
    },
    {
      field: "purchasedOnNcx",
      headerName: "Purchased On Ncx",
      width: "120",
    },
    {
      field: "Register",
      headerName: "Register On Ncx",
      width: "150",
    },
    {
      field: "twoFactor",
      headerName: "2FA",
      width: "80",
    },
    {
      field: "actions",
      headerName: "Action",
      width: "70",
      renderCell: (params) => {
        return (
          <>
            <Index.IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                handleClick1(event);
                setRefferalId(params?.row?.refferalId);
              }}
            >
              <Index.MoreVertIcon />
            </Index.IconButton>
            <Index.Menu
              className=""
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open1}
              onClose={handleClose1}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "100px",
                  boxShadow: "none",
                  border: "1px solid rgba(224, 224, 224, 1)",
                  marginTop: "6px",
                },
              }}
            >
              {options.map((option) => (
                <Index.MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={handleClose1}
                  className="menu-option-li"
                >
                  {option}
                </Index.MenuItem>
              ))}
            </Index.Menu>
          </>
        );
      },
    },
  ];
  const columns3 = [
    {
      field: "tranxId",
      headerName: "Tranx Id",
      width: "110",
    },
    {
      field: "ncxUserId",
      headerName: "Client ID",
      width: "100",
    },
    {
      field: "tranxIdOnNcx",
      headerName: "Tranx ID NCX",
      width: "100",
    },
    {
      field: "phaseName",
      headerName: "Round Name",
      width: "100",
    },
    // {
    //   field: "requestToken",
    //   headerName: "Requested Token",
    //   width: "150",
    // },
    {
      field: "receivedToken",
      headerName: "Received Token",
      width: "110",
    },
    {
      field: "requestUsdt",
      headerName: "Requested USDT",
      width: "110",
    },
    {
      field: "receivedUsdt",
      headerName: "Received USDT",
      width: "110",
    },
    {
      field: "paymentType",
      headerName: "Payment type",
      width: "90",
    },
    {
      field: "totalToken",
      headerName: "Total",
      width: "100",
    },
    {
      field: "processedBy",
      headerName: "Processed By",
      width: "120",
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: "120",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "updatedAt",
      headerName: "Processed Date",
      width: "120",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
  ];
  const columns4 = [
    {
      field: "tranxId",
      headerName: "Tranx ID",
      width: "130",
    },
    {
      field: "ncxUserId",
      headerName: "Client ID",
      width: "100",
    },
    {
      field: "tranxIdOnNcx",
      headerName: "Tranx ID NCX",
      width: "100",
    },
    {
      field: "round",
      headerName: "Round Name",
      width: "90",
    },
    {
      field: "requestUsdt",
      headerName: "Request Usdt",
      width: "100",
    },
    {
      field: "receivedUsdt",
      headerName: "Receive Usdt",
      width: "100",
    },
    {
      field: "receivedToken",
      headerName: "Receive Token",
      width: "100",
    },
    {
      field: "bonusToken",
      headerName: "Bonus Token",
      width: "120",
    },
    {
      field: "totalToken",
      headerName: "Total Token",
      width: "110",
    },
    {
      field: "monthlyReleaseAmount",
      headerName: "Monthly Token",
      width: "130",
    },
    {
      field: "unlockDate",
      headerName: "Unlock Date",
      width: "120",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "locked",
      headerName: "Locked",
      width: "90",
    },
    {
      field: "withdrawn",
      headerName: "Withdrawn",
      width: "110",
    },
    {
      field: "txnType",
      headerName: "Transaction Type",
      width: "110",
    },
  ];
  const columns5 = [
    {
      field: "tranxId",
      headerName: "Tranx ID",
      width: "120",
    },
    {
      field: "ncxUserId",
      headerName: "Client ID",
      width: "100",
    },
    {
      field: "tranxIdOnNcx",
      headerName: "Tranx ID NCX",
      width: "100",
    },
    {
      field: "phaseName",
      headerName: "Round Name",
      width: "90",
    },
    {
      field: "receivedUsdt",
      headerName: "Received Usdt",
      width: "100",
    },
    {
      field: "requestToken",
      headerName: "Request Token",
      width: "100",
    },
    {
      field: "receivedToken",
      headerName: "Receive Token",
      width: "100",
    },
    {
      field: "monthlyReleaseAmount",
      headerName: "Monthly Token",
      width: "100",
    },
    {
      field: "processedBy",
      headerName: "Processed By",
      width: "120",
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: "120",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    {
      field: "updatedAt",
      headerName: "Processed Date",
      width: "120",
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
  ];

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      <Navigate to="/"></Navigate>;
    }
  });

  useEffect(() => {
    fetchTransactionHistoryData();
    fetchData1();
    fetchData2();
    getbalance();
    getSingleInfo();
    roundInfoData();
    withdrawData();
  }, []);

  return (
    <div>
      <Index.Box className="dashboard-main">
        <ToastContainer />
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />

          <Index.Box className="dashboard-containt-main">
            <Index.Box className="dashboard-content add-user-containt">
              <UserDetails userInfo={userInfo} />

              {/* <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Round Information
                </Index.Typography>
              </Index.Box>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ height: 530, width: "100%" }}>
                  <Index.DataGrid
                    className="user-data-head"
                    rows={users3}
                    columns={columns3}
                    pageSize={5}
                    getRowId={(row) => row?.id}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: true }}
                    loading={isFetching}
                  />
                </Index.Box>
              </Index.Box>

              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Purchase History
                </Index.Typography>
              </Index.Box>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ height: 530, width: "100%" }}>
                  <Index.DataGrid
                    className="user-data-head"
                    rows={users1}
                    columns={columns1}
                    pageSize={5}
                    getRowId={(row) => row?.id}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: true }}
                    loading={isFetching}
                  />
                </Index.Box>
              </Index.Box>

              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Airdrop And Referrals
                </Index.Typography>
              </Index.Box>

              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ height: 530, width: "100%" }}>
                  <Index.DataGrid
                    className="user-data-head"
                    rows={users2}
                    columns={columns2}
                    pageSize={5}
                    getRowId={(row) => row?.id}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: true }}
                    loading={isFetching}
                  />
                </Index.Box>
              </Index.Box>

              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Withdrawal History
                </Index.Typography>
              </Index.Box>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ height: 530, width: "100%" }}>
                  <Index.DataGrid
                    className="user-data-head"
                    rows={users5}
                    columns={columns5}
                    pageSize={5}
                    getRowId={(row) => row?.id}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: true }}
                    loading={isFetching}
                  />
                </Index.Box>
              </Index.Box>

              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Transaction History
                </Index.Typography>
              </Index.Box>
              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ height: 530, width: "100%" }}>
                  <Index.DataGrid
                    className="user-data-head"
                    rows={users4}
                    columns={columns4}
                    pageSize={5}
                    getRowId={(row) => row?.id}
                    rowsPerPageOptions={[5]}
                    experimentalFeatures={{ newEditingApi: true }}
                    loading={isFetching}
                  />
                </Index.Box>
              </Index.Box>

              <Index.Box className="user-head-title">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  Tokens
                </Index.Typography>
              </Index.Box>

              <Index.Box className="token-view-box">
                <Index.Box className="token-view-box-left token-box-flex">
                  <Index.Typography className="token-view-lables">
                  Total Airdrop Token
                  </Index.Typography>
                  <Index.Typography className="token-view-data">
                    {getBalance?.welcomeReward}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="token-view-box-left token-box-flex">
                  <Index.Typography className="token-view-lables">
                    Total Purchase Token
                  </Index.Typography>
                  <Index.Typography className="token-view-data">
                    {getBalance.purchaseToken}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="token-view-box-right token-box-flex">
                  <Index.Typography className="token-view-lables">
                    Total Refferal Reward
                  </Index.Typography>
                  <Index.Typography className="token-view-data">
                    {getBalance.refferalReward}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="token-view-box-right token-box-flex">
                  <Index.Typography className="token-view-lables">
                    Total Reward
                  </Index.Typography>
                  <Index.Typography className="token-view-data">
                    {getBalance.reward}
                  </Index.Typography>
                </Index.Box>
              </Index.Box> */}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
