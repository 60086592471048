import React from 'react'
import Index from '../../../Index'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


export default function JoyfulInvestment() {
    return (
        <>

            <Index.Box className="jyoful-main-content" id="joyful-investment">
                <Index.Box className="bg-joyful">
                    <Index.Box className="pd-joyful-investment">
                        <Index.Box className="title-details-joyful">
                            <Index.Box className="container">
                                <Index.Typography component='h5' variant='h5' className="joyful-spirit-title">Join us on our mission to Construct a World Class health and Fitness Network. 	</Index.Typography>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="pos-relative-joyful">
                            <Index.Box className="container">
                                <Index.Box className='grid-row joyful-row'>
                                    <Index.Box sx={{ width: 1 }} className="grid-main joyful-main">
                                        <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 0, sm: 0, md: 0, lg: 0 }} className="joyful-grid">
                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 4", lg: "span 4" }} className="grid-column joyful-column">
                                                <Index.Box className="virtual-joyful-race-main">
                                                    <Index.List className='virtual-joyful-list'>
                                                        <Index.ListItem className='virtual-joyful-listitem customize-joyful-details'>
                                                            <Index.Typography className='floating customize-joyful-details' component='p' variant='p'>
                                                                <Index.Box className="bg-virtual-icon-main">
                                                                    <img src={Index.Svg.earning} className='icon-joy-main'></img>
                                                                </Index.Box>
                                                                <span>WALK & EARN </span>
                                                                Turn steps into rewards. Burn Calories Earn FTRZ Token's and Get Rewarded for Your Fitness Journey
                                                            </Index.Typography>

                                                        </Index.ListItem>

                                                        <Index.ListItem className='virtual-joyful-listitem customize-joyful-details'>
                                                            <Index.Typography className='floating customize-joyful-details' component='p' variant='p'>
                                                                <Index.Box className="bg-virtual-icon-main">
                                                                    <img src={Index.Svg.treadmill} className='icon-joy-main'></img>
                                                                </Index.Box>
                                                                <span>Step into WALK & EARN NFT Game</span> Discover,  Collect, Buy, Sell & Trade Unique. Sneakers. Health and Fitness NFT's. Participate in Thrilling. Challenges and Compete Against Real Opponent's To Earn Exclusive Reward's.
                                                            </Index.Typography>

                                                        </Index.ListItem>
                                                        <Index.ListItem className='virtual-joyful-listitem customize-joyful-details'>
                                                            <Index.Typography className='floating customize-joyful-details' component='p' variant='p'>
                                                                <Index.Box className="bg-virtual-icon-main">
                                                                    <img src={Index.Svg.coach} className='icon-joy-main'></img>
                                                                </Index.Box>
                                                                <span>Connect with Health & Fitness Expert</span> Search, Consult, Join, Train, an Establish Contact. with the finest Health Trainer's Coaches
                                                                Academies and Gym's Near you for an Enhanced Health and Wellness. Experience.
                                                            </Index.Typography>
                                                        </Index.ListItem>
                                                        <Index.ListItem className='virtual-joyful-listitem customize-joyful-details'>
                                                            <Index.Typography className='floating customize-joyful-details' component='p' variant='p'>
                                                                <Index.Box className="bg-virtual-icon-main">
                                                                    <img src={Index.Svg.saving} className='icon-joy-main'></img>
                                                                </Index.Box>
                                                                <span>Empower Your Health Management</span> Unlock the Potential of Storing and Accessing Your Health Data in one Secure place Seamlessly Manage Your Past Health Record's Treatment History and Test Report's, Allowing for Easy Retrieval and Sharing. Maximize Efficiency SAVE. TIME and Money, and Prioritize the Preservation of Your Priceless Life Saving Health & Fitness DATA.
                                                            </Index.Typography>
                                                        </Index.ListItem>

                                                    </Index.List>
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 4", lg: "span 4" }} className="grid-column joyful-column img-column-bg">

                                            </Index.Box>
                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 4", lg: "span 4" }} className="grid-column joyful-column">
                                                <Index.Box className="virtual-joyful-race-main">
                                                    <Index.List className='virtual-joyful-list'>
                                                        <Index.ListItem className='virtual-joyful-listitem customize-joyful-details'>
                                                            <Index.Typography className='floating customize-joyful-details' component='p' variant='p'>
                                                                <Index.Box className="bg-virtual-icon-main">
                                                                    <img src={Index.Svg.shield} className='icon-joy-main'></img>
                                                                </Index.Box>
                                                                <span>Empower Your Wellness Journey</span> Seamlessly Sync Your Health Devices with The FitRiser App to Monitor and Enhance Your Personal and Family Health. Receive Timely Alert's for Potential Risk. Empowering You To Take Preventive Measure's for a Safer and Healthier Life.
                                                            </Index.Typography>
                                                        </Index.ListItem>
                                                        <Index.ListItem className='virtual-joyful-listitem customize-joyful-details'>
                                                            <Index.Typography className='floating customize-joyful-details' component='p' variant='p'>
                                                                <Index.Box className="bg-virtual-icon-main">
                                                                    <img src={Index.Svg.healthcare} className='icon-joy-main'></img>
                                                                </Index.Box>
                                                                <span>Online Health Center</span> Your Online Fitness, Destination Discover a Universe of Health and Fitness Wisdom at your Fingertips. Interact with Leading. Fitness & Health Expert's Through Online Training Session's. and Expert Guidance. to UpLift, Your Health & Fitness, Journey.
                                                            </Index.Typography>
                                                        </Index.ListItem>
                                                        <Index.ListItem className='virtual-joyful-listitem customize-joyful-details'>
                                                            <Index.Typography className='floating customize-joyful-details' component='p' variant='p'>
                                                                <Index.Box className="bg-virtual-icon-main">
                                                                    <img src={Index.Svg.customerjourney} className='icon-joy-main'></img>
                                                                </Index.Box>
                                                                <span>Empower Your Health Journey with our Online Health STORE</span> Discover an Abundance of High-Quality Health & Fitness product's at Your Fingertips. Utilize Your Hand , Earned FTRZ Token's to Make Purchases and Upgrade Your Wellness Journey.
                                                            </Index.Typography>
                                                        </Index.ListItem>
                                                        <Index.ListItem className='virtual-joyful-listitem customize-joyful-details'>
                                                            <Index.Typography className='floating customize-joyful-details' component='p' variant='p'>
                                                                <Index.Box className="bg-virtual-icon-main">
                                                                    <img src={Index.Svg.friends} className='icon-joy-main'></img>
                                                                </Index.Box>
                                                                <span>Engage in Exciting</span> Event's and Challenges Participate in Community Event's and Create Private Challenges to Compete with Friend's and Family.
                                                            </Index.Typography>

                                                        </Index.ListItem>

                                                    </Index.List>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="joyful-bg-top">
                                <Index.Box className='grid-row joyful-row'>
                                    <Index.Box sx={{ width: 1 }} className="grid-main joyful-main">
                                        <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 0, lg: 0 }} className="joyful-grid">
                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 4", lg: "span 3" }} className="grid-column joyful-column">

                                            </Index.Box>
                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 4", lg: "span 6" }} className="grid-column joyful-column img-column-bg">
                                                <Index.Box className="joyful-race-main-img">
                                                    <img src={Index.Png.joyfulbg} className="joyful-race-content"></img>
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 4", lg: "span 3" }} className="grid-column joyful-column">

                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>


        </>
    )
}
