import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/app/store';
import { PersistGate } from 'redux-persist/integration/react'

const root = ReactDOM.createRoot(document.getElementById('root'));

{
  // console.log = () => {};
  // console.error = () => {};
  // console.debug = () => {};
  // console.warn = () => {};
}

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />       
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);