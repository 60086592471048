import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Index from "../../../Index";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";

export default function Roadmap(props) {
  const [roadMapdata, setRoadMapdata] = useState([]);
  const userToken = useSelector((store) => store.admin.userToken);
  useEffect(() => {
    fetchRoadMap();
  }, []);

  const fetchRoadMap = async () => {
    // setIsFetching(true);
    DataService.defaults.headers.common["auth"] = userToken;
    await DataService.post(API.Admin.ROADMAP_LIST, {
      headers: {
        auth: userToken,
      },
    })
      .then((res) => {
        // setIsFetching(false);
        setRoadMapdata(res?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.data.message === "Token not authorized") {
        }
      });
  };
  return (
    <>
      <Index.Box className="roadmapbg-main-contant" id="section-roadmap">
        <Index.Box className="roadmap-pd-list">
          <Index.Box className="container">
            <Index.Box className="roadmap-title-content">
              <Index.Typography
                component="h5"
                variant="h5"
                className="shadow-contnet-title-comman"
              >
                Road map
              </Index.Typography>
              <Index.Typography
                component="p"
                variant="p"
                className="title-roadmap-details"
              >
                Road map
              </Index.Typography>
            </Index.Box>

            <Index.Box className="max-content-roadmap">
              <Index.List className="list-roadmap-details">
                {/* { roadMapdata?.map((row,index) => { */}
                {/* {roadMapdata?.length ? (roadMapdata?.map((row, index) => (
                        <Index.ListItem className="listitem-roadmap-details" key={row?._id}>
                            <Index.Box className="road-content-max-list">
                                <Index.Box className="rounded-road-year-flex">
                                    <Index.Box className="year-text-contnet">
                                        <Index.Box className="bg-year-rounded">
                                            <img src={Index.Svg.roadrounded} className="rounded-img"></img>
                                        </Index.Box>
                                        <Index.Typography className="year-title-details" component='p' variant='p'>{row?.year}</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="qtitle-roadmap">
                                        Q{row?.quarter}
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="roadmap-details-content">
                                    <Index.Typography component='p' variant='p' className="roadmap-desc">{row?.description}</Index.Typography>
                                </Index.Box>
                            </Index.Box>
                        </Index.ListItem>
                    ))) : (<></>)} */}

                <Index.ListItem className="listitem-roadmap-details">
                  <Index.Box className="road-content-max-list">
                    <Index.Box className="rounded-road-year-flex">
                      <Index.Box className="year-text-contnet">
                        <Index.Box className="bg-year-rounded">
                          <img
                            src={Index.Svg.roadrounded}
                            className="rounded-img"
                          ></img>
                        </Index.Box>
                        <Index.Typography
                          className="year-title-details"
                          component="p"
                          variant="p"
                        >
                          2023
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="qtitle-roadmap">Q1</Index.Box>
                    </Index.Box>
                    <Index.Box className="roadmap-details-content">
                      <Index.Typography className="phase-title-main">
                        {" "}
                        MVP Startup Module for Walk & Earn Concept (Expected
                        completion: End of May 2023
                      </Index.Typography>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Features:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> User
                            registration and KYC verification.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Social
                            media integration for seamless login and sharing.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 3.</span> User
                            dashboard with reports on BMI, daily walking
                            progress, earned tokens, and burned calories.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 4.</span> Referral
                            module with referral link sharing and tracking.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 5.</span> Step count
                            module with a daily cap limit.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 6.</span> Signup and
                            referral bonus module.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 7.</span>{" "}
                            Integration of social media sharing options.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 8.</span> White
                            paper and roadmap outlining the project.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Benefits:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Users can
                            start registering and tracking their health &s
                            fitness progress.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Referral
                            system encourages user growth.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 3.</span> Initial
                            features provide a foundation for further
                            development.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem className="listitem-roadmap-details">
                  <Index.Box className="road-content-max-list">
                    <Index.Box className="rounded-road-year-flex">
                      <Index.Box className="year-text-contnet">
                        <Index.Box className="bg-year-rounded">
                          <img
                            src={Index.Svg.roadrounded}
                            className="rounded-img"
                          ></img>
                        </Index.Box>
                        <Index.Typography
                          className="year-title-details"
                          component="p"
                          variant="p"
                        >
                          2023
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="qtitle-roadmap">Q2</Index.Box>
                    </Index.Box>
                    <Index.Box className="roadmap-details-content">
                      <Index.Typography className="phase-title-main">
                        {" "}
                        Walk & Earn Concept Update (Expected start: October
                        2023)
                      </Index.Typography>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Features:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Event
                            module with task-based challenges and contests.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Private
                            challenge module for users to create and participate
                            in private challenges.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Benefits:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Increased
                            user engagement through events and challenges.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Enhanced
                            competitiveness and community interaction.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem className="listitem-roadmap-details">
                  <Index.Box className="road-content-max-list">
                    <Index.Box className="rounded-road-year-flex">
                      <Index.Box className="year-text-contnet">
                        <Index.Box className="bg-year-rounded">
                          <img
                            src={Index.Svg.roadrounded}
                            className="rounded-img"
                          ></img>
                        </Index.Box>
                        <Index.Typography
                          className="year-title-details"
                          component="p"
                          variant="p"
                        >
                          2024
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="qtitle-roadmap">Q3</Index.Box>
                    </Index.Box>
                    <Index.Box className="roadmap-details-content">
                      <Index.Typography className="phase-title-main">
                        Affiliate Partners and Health Ambassadors (Expected
                        start: January 2024)
                      </Index.Typography>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Features:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span>{" "}
                            Collaboration & Recruitment of fitness celebrities,
                            influencers, and experts as ambassadors.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Ambassador
                            dashboard for tracking community activities.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 3.</span> Online
                            health and fitness sessions by ambassadors.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 4.</span> Health
                            ambassador search and profile pages.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Benefits:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span>{" "}
                            Collaboration with influential figures to promote
                            the platform.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Access to
                            expert knowledge and training sessions for users.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 3.</span> Increased
                            visibility and reputation for ambassadors.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem className="listitem-roadmap-details">
                  <Index.Box className="road-content-max-list">
                    <Index.Box className="rounded-road-year-flex">
                      <Index.Box className="year-text-contnet">
                        <Index.Box className="bg-year-rounded">
                          <img
                            src={Index.Svg.roadrounded}
                            className="rounded-img"
                          ></img>
                        </Index.Box>
                        <Index.Typography
                          className="year-title-details"
                          component="p"
                          variant="p"
                        >
                          2024
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="qtitle-roadmap">Q4</Index.Box>
                    </Index.Box>
                    <Index.Box className="roadmap-details-content">
                      <Index.Typography className="phase-title-main">
                        Health Cart (Expected start: September 2024)
                      </Index.Typography>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Features:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Health and
                            fitness data, test and report storage for users
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Health
                            Network, Analysis & Research Module:
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Benefits:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Convenient
                            access to professional health consulting.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Users can
                            upload and store health data, reports, treatment
                            history, and physical activity. Health and fitness
                            experts can access the data.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 3.</span> Easy
                            retrieval of health reports for health improvement,
                            analysis, prevention and treatment purposes.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem className="listitem-roadmap-details">
                  <Index.Box className="road-content-max-list">
                    <Index.Box className="rounded-road-year-flex">
                      <Index.Box className="year-text-contnet">
                        <Index.Box className="bg-year-rounded">
                          <img
                            src={Index.Svg.roadrounded}
                            className="rounded-img"
                          ></img>
                        </Index.Box>
                        <Index.Typography
                          className="year-title-details"
                          component="p"
                          variant="p"
                        >
                          2025
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="qtitle-roadmap">Q5</Index.Box>
                    </Index.Box>
                    <Index.Box className="roadmap-details-content">
                      <Index.Typography className="phase-title-main">
                        Expert Consulting and Online Health Center(Expected
                        start: January 2025)
                      </Index.Typography>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Features:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Online
                            consulting services from certified health & Fitness
                            experts.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Online
                            Sessions and Training:
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Benefits:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Convenient
                            access to professional health consulting.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Online
                            health and fitness sessions. Videos and podcasts can
                            also be shared.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem className="listitem-roadmap-details">
                  <Index.Box className="road-content-max-list">
                    <Index.Box className="rounded-road-year-flex">
                      <Index.Box className="year-text-contnet">
                        <Index.Box className="bg-year-rounded">
                          <img
                            src={Index.Svg.roadrounded}
                            className="rounded-img"
                          ></img>
                        </Index.Box>
                        <Index.Typography
                          className="year-title-details"
                          component="p"
                          variant="p"
                        >
                          2025
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="qtitle-roadmap">Q6</Index.Box>
                    </Index.Box>
                    <Index.Box className="roadmap-details-content">
                      <Index.Typography className="phase-title-main">
                        Online Health Stores and Centers (Expected start: April
                        2025)
                      </Index.Typography>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Features:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Launch of
                            physical health stores.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Online
                            health store for health and fitness product
                            purchases.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 3.</span>{" "}
                            Establishment of health training centres.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 4.</span> In-person
                            consultation, training, and product purchases.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Benefits:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Expanded
                            reach and accessibility through physical locations.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Online
                            Marketplace One-stop shop for all health &
                            fitness-related products.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 3.</span>{" "}
                            Personalised and hands-on health services for users.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem className="listitem-roadmap-details">
                  <Index.Box className="road-content-max-list">
                    <Index.Box className="rounded-road-year-flex">
                      <Index.Box className="year-text-contnet">
                        <Index.Box className="bg-year-rounded">
                          <img
                            src={Index.Svg.roadrounded}
                            className="rounded-img"
                          ></img>
                        </Index.Box>
                        <Index.Typography
                          className="year-title-details"
                          component="p"
                          variant="p"
                        >
                          2025
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="qtitle-roadmap">Q7</Index.Box>
                    </Index.Box>
                    <Index.Box className="roadmap-details-content">
                      <Index.Typography className="phase-title-main">
                        Certified Trainers Program and Health Network Module
                        (Expected start: December 2025)
                      </Index.Typography>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Features:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Certified
                            trainers program to onboard partners.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Enhanced
                            health network, analysis, and research module.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="roadmap-feature-benifitis-title">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="roadmap-title-main-list"
                        >
                          Benefits:
                        </Index.Typography>
                        <Index.Box className="list-feature-main">
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 1.</span> Growth of
                            the Fit-riser network through partnerships.
                          </Index.Typography>
                          <Index.Typography
                            className="feature-listitem-main"
                            component="p"
                            variant="p"
                          >
                            <span className="mr-span-road"> 2.</span> Advanced
                            health data storage, analysis and one touch sharing
                            capabilities.
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.ListItem>
              </Index.List>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="bottom-arrow-roadmap">
          <img
            src={Index.Svg.roadmapbottom}
            className="roadmap-arrow-bottom"
          ></img>
        </Index.Box>
      </Index.Box>
    </>
  );
}
