import React, { useState } from "react";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Index from "../../../Index";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

export default function DataPicker() {

    const [dateValue,setDateValue] = useState("")

    return (
        <>
            <Index.Box className="datepicker-wrap">
                <LocalizationProvider dateAdapter={AdapterMoment} className="datepicker-wrap-inner">
                    <DatePicker

                        value={dateValue}
                        onChange={(val) => {
                            setDateValue(moment(val._d))
                         }}
                        renderInput={(props) => <TextField  {...props} size='small' helperText={null} className="date-text-list"/>}
                        className="mob-date-picker date-picker-details" />
                </LocalizationProvider>
            </Index.Box>
        </>
    )
}

