import React, { useEffect, useState } from "react";
import Index from "../../Index";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  registerUserData,
  getCountryList,
} from "../../../redux/features/slices/User/UserService";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { setCountryList } from "../../../redux/features/slices/Admin/AdminSlice";

import { API } from "../../../config/Api";
import { DataService } from "../../../config/DataService";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [selectedCountry, setSelectedCountry] = useState();
  const [userNameError, setUserNameError] = useState();
  // for auto suggest message
  const [userNameMessage, setUserNameMessage] = useState();
  const [isLoading,setIsLoading]=useState(false)

  const query = new URLSearchParams(useLocation().search);
  const referCode = query.get("referCode");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [confirmshowPassword, setShowconfirmPassword] = useState(false);

  const handleClickShowconfirmPassword = () =>
    setShowconfirmPassword((show) => !show);

  const handleMouseDownconfirmPassword = (event) => {
    event.preventDefault();
  };

  const countryList = useSelector((store) => store.admin.countryList);
  

  useEffect(() => {
    const filteredCountry = countryList?.filter(
      (ele) => ele?._id == selectedCountry
    );
    if (filteredCountry[0]) {
      setCountryCode(filteredCountry[0]?.dial_code);
    }
  }, [selectedCountry]);

  useEffect(() => {
    try {
      dispatch(getCountryList()).then((response) => {
        if (response.payload) {
          dispatch(setCountryList(response?.payload?.data));
        } else {
          // toast.error("Something went wrong");
        }
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }, []);
  let initialValues = {
    firstName: "",
    userName: "",
    email: "",
    phoneNumber: "",
    country: "647ed3f7fca806287ecc7037",
    password: "",
    password: "",
    confirmPassword: "",
    registerType: 0,
  };

  // Validation schema
  const textAndSpaceOnly = (value) =>
    /^[a-zA-Z]+(\s[a-zA-Z]*){0,2}$/.test(value) || value.length === 0;
  const charSpaceNumber = (value) => /^[a-zA-Z0-9_.-]*$/.test(value);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Full Name is required")
      .test(
        "firstName",
        "Full Name allows only characters and space between",
        textAndSpaceOnly
      )
      .max(20, "Full Name allows maximum length 20"),
    userName: Yup.string()
      .required("Username is required")
      .test(
        "userName",
        "Username allows only characters and number",
        charSpaceNumber
      )
      .max(20, "Username allows maximum length 20"),
    email: Yup.string()
      .email("Email is not valid")
      .matches(
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
        "Email is not valid"
      )
      .required("Email is required"),
    phoneNumber: Yup.string()
      .nullable()
      .min(10, "Mobile Number should be 10 digits")
      .max(10, "Mobile Number should be 10 digits"),
    country: Yup.string().required("Country is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with password"
      ),
  });

  //Check username exis

  const checkUsernameExist = async (userName) => {
    // setIsFetching(true);
    const data = { userName: userName };
    await DataService.post(API.User.CHECK_USERNAME_EXIST, data)
      .then((res) => {
        setUserNameError("");
      })
      .catch((error) => {
        setUserNameMessage();
        setUserNameError("Username already taken");
      });
  };

  // Register
  const handleFormSubmit = async (values) => {
    const data = {
      firstName: values.firstName,
      userName: values.userName,
      email: values.email,
      countryCode: countryCode,
      phoneNumber: values.phoneNumber,
      password: values.password,
      registerType: values.registerType,
      country: values.country,
      referCode: referCode ? referCode : "",
    };

    if (
      values.firstName != "" &&
      values.userName != "" &&
      values.email != "" &&
      values.phoneNumber != "" &&
      values.password != "" &&
      values.country != ""
    ) {
      data.stepCompleted = 1;
    }

    if (userNameError == "") {
      setIsLoading(true)
      dispatch(registerUserData(data)).then((response) => {
        console.log(response,"response")
        if(response.payload.status == 201){
          setIsLoading(false)
          if (response?.payload?.data?._id) {
            toast.success("Please verify your email using OTP");
              navigate(`/user/otp-verify/${response?.payload.data?._id}`);
          } 
        }
      }).catch((error)=>{
        setIsLoading(false)
      })
    }
  };

  return (
    <>
      <Index.Box className="login-main-content">
        <Index.Box className="grid-row login-row">
          <Index.Box sx={{ width: 1 }} className="grid-main login-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              className="login-grid"
              sx={{ margin: 0, alignItems: "center" }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="signup-bg-content">
                  <Index.Box className="login-logo-details signup-logo-details">
                    <Index.Link to="/">
                      {" "}
                      <img
                        src={Index.Svg.logo}
                        className="logo-login"
                      ></img>{" "}
                    </Index.Link>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-form-details signup-form-details">
                  <Index.Box className="login-pd-main">
                    <Index.Box className="signup-max-details">
                      <Index.Box className="login-details-title">
                        <Index.Typography
                          className="login-title"
                          component="h5"
                          variant="h5"
                        >
                          Create Account
                        </Index.Typography>
                        <Index.Typography
                          className="login-desc"
                          component="p"
                          variant="p"
                        >
                          Enter all fields to complete register your account
                        </Index.Typography>
                      </Index.Box>
                      <Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                          setFieldTouched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Index.Box className="mr-hgt-scroll">
                              <Index.Box className="signup-scroll-form">
                                <Index.Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                                  className="login-grid-inner"
                                >
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column login-column mb-20px"
                                  >
                                    <Index.Box className="input-box custom-form-details login-input">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Full Name
                                        </Index.FormHelperText>
                                        <Index.TextField
                                          fullWidth
                                          id="fullWidth"
                                          className="form-control custom-control"
                                          name="firstName"
                                          // onBlur={handleBlur}
                                          value={values.firstName}
                                          // onChange={handleChange}
                                          onChange={(e) => {
                                            setFieldValue(
                                              "firstName",
                                              e.target.value
                                            );
                                          }}
                                          onBlur={() => {
                                            let name = values?.firstName.trim();
                                            name = name.replace(" ", "");
                                            name = name.trim();

                                            if (name != "") {
                                              const timestamp =
                                                new Date().valueOf();
                                              let randoNumber = Math.floor(
                                                Math.random() * timestamp
                                              );
                                              randoNumber = randoNumber
                                                .toString()
                                                .substr(0, 4);
                                              const autoUserName =
                                                name.toLowerCase() +
                                                randoNumber;
                                              setFieldValue(
                                                "userName",
                                                autoUserName
                                              );
                                              setUserNameMessage(
                                                "Username auto suggested it is editable"
                                              );
                                              checkUsernameExist(autoUserName);
                                            } else {
                                              setFieldValue("userName", "");
                                            }
                                          }}
                                          helperText={
                                            touched.firstName &&
                                            errors.firstName
                                          }
                                          error={Boolean(
                                            errors.firstName &&
                                              touched.firstName
                                          )}
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column login-column mb-20px"
                                  >
                                    <Index.Box className="input-box custom-form-details login-input">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Username
                                        </Index.FormHelperText>
                                        <Index.TextField
                                          fullWidth
                                          id="fullWidth"
                                          className="form-control custom-control"
                                          name="userName"
                                          // onBlur={handleBlur}
                                          value={values.userName}
                                          onChange={handleChange}
                                          onBlur={(e) => {
                                            setFieldTouched("userName");
                                            checkUsernameExist(e.target.value);
                                          }}
                                          helperText={
                                            touched.userName && errors.userName
                                          }
                                          error={Boolean(
                                            errors.userName && touched.userName
                                          )}
                                        />
                                        {userNameError != "" && (
                                          <Index.FormHelperText error>
                                            {userNameError}
                                          </Index.FormHelperText>
                                        )}
                                        {userNameMessage != "" && (
                                          <Index.FormHelperText className="username-text-orange">
                                            {userNameMessage}
                                          </Index.FormHelperText>
                                        )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column login-column mb-20px"
                                  >
                                    <Index.Box className="input-box custom-form-details login-input">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Email
                                        </Index.FormHelperText>
                                        <Index.TextField
                                          fullWidth
                                          id="fullWidth"
                                          className="form-control custom-control"
                                          name="email"
                                          onBlur={handleBlur}
                                          value={values.email}
                                          onChange={handleChange}
                                          helperText={
                                            touched.email && errors.email
                                          }
                                          error={Boolean(
                                            errors.email && touched.email
                                          )}
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column login-column mb-20px"
                                  >
                                    <Index.Box className="input-box custom-form-details login-input">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Country
                                        </Index.FormHelperText>
                                        <Index.Box className="drop-main-comman">
                                          <Index.Select
                                            className="form-control dropdown-content"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            defaultValue={
                                              values ? values?.country : ""
                                            }
                                            value={values.country}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "country",
                                                e.target.value
                                              );
                                              setSelectedCountry(
                                                e.target.value
                                              );
                                            }}
                                          >
                                            {countryList?.map(() => {
                                              <Index.MenuItem value={10}>
                                                Ten
                                              </Index.MenuItem>;
                                            })}
                                            {countryList?.map((item, index) => (
                                              <Index.MenuItem
                                                value={item?._id}
                                                key={item?._id}
                                              >
                                                {item?.name}
                                              </Index.MenuItem>
                                            ))}
                                          </Index.Select>
                                        </Index.Box>
                                      </Index.Box>
                                      {touched.country && errors.country && (
                                        <Index.FormHelperText error>
                                          {errors.country}
                                        </Index.FormHelperText>
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column login-column mb-20px"
                                  >
                                    <Index.Box className="input-box custom-form-details login-input">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Mobile Number
                                        </Index.FormHelperText>
                                        <Index.Box className="input-details-main num-main-input">
                                          <Index.Box className="form-group pass_group_main pass-num-main">
                                            <OutlinedInput
                                              fullWidth
                                              id="fullWidth"
                                              type="number"
                                              className="form-control custom-control"
                                              name="phoneNumber"
                                              onBlur={handleBlur}
                                              value={values.phoneNumber}
                                              // onChange={handleChange}
                                              onChange={(e) => {
                                                if (
                                                  e.target.value.toString()
                                                    .length <= 10
                                                ) {
                                                  setFieldValue(
                                                    "phoneNumber",
                                                    e.target.value
                                                  );
                                                }
                                              }}
                                              startAdornment={
                                                <InputAdornment
                                                  position="start"
                                                  className="pass_position num-position"
                                                >
                                                  {countryCode}
                                                </InputAdornment>
                                              }
                                              helperText={
                                                touched.phoneNumber &&
                                                errors.phoneNumber
                                              }
                                              error={Boolean(
                                                errors.phoneNumber &&
                                                  touched.phoneNumber
                                              )}
                                            />

                                            {touched.phoneNumber &&
                                              errors.phoneNumber && (
                                                <Index.FormHelperText error>
                                                  {errors.phoneNumber}
                                                </Index.FormHelperText>
                                              )}
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column login-column mb-20px"
                                  >
                                    <Index.Box className="input-box custom-form-details login-input">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Password
                                        </Index.FormHelperText>
                                        <Index.Box className="input-details-main">
                                          <Index.Box className="form-group pass_group_main">
                                            <OutlinedInput
                                              className="form-control custom_form_control"
                                              id="outlined-adornment-password"
                                              name="password"
                                              onBlur={handleBlur}
                                              value={values.password}
                                              onChange={handleChange}
                                              helperText={
                                                touched.password &&
                                                errors.password
                                              }
                                              error={Boolean(
                                                errors.password &&
                                                  touched.password
                                              )}
                                              type={
                                                showPassword
                                                  ? "text"
                                                  : "password"
                                              }
                                              endAdornment={
                                                <InputAdornment
                                                  position="end"
                                                  className="pass_position"
                                                >
                                                  <IconButton
                                                    className="icon_end_icon"
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                      handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                      handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                  >
                                                    {showPassword ? (
                                                      <VisibilityOff />
                                                    ) : (
                                                      <Visibility />
                                                    )}
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                              label="Password"
                                            />
                                          </Index.Box>
                                          {touched.password &&
                                            errors.password && (
                                              <Index.FormHelperText error>
                                                {errors.password}
                                              </Index.FormHelperText>
                                            )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column login-column mb-20px"
                                  >
                                    <Index.Box className="input-box custom-form-details login-input">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Confirm Password
                                        </Index.FormHelperText>
                                        <Index.Box className="input-details-main">
                                          <Index.Box className="form-group pass_group_main">
                                            <OutlinedInput
                                              className="form-control custom_form_control"
                                              id="outlined-adornment-password"
                                              name="confirmPassword"
                                              onBlur={handleBlur}
                                              value={values.confirmPassword}
                                              onChange={handleChange}
                                              helperText={
                                                touched.confirmPassword &&
                                                errors.confirmPassword
                                              }
                                              error={Boolean(
                                                errors.confirmPassword &&
                                                  touched.confirmPassword
                                              )}
                                              type={
                                                confirmshowPassword
                                                  ? "text"
                                                  : "password"
                                              }
                                              endAdornment={
                                                <InputAdornment
                                                  position="end"
                                                  className="pass_position"
                                                >
                                                  <IconButton
                                                    className="icon_end_icon"
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                      handleClickShowconfirmPassword
                                                    }
                                                    onMouseDown={
                                                      handleMouseDownconfirmPassword
                                                    }
                                                    edge="end"
                                                  >
                                                    {confirmshowPassword ? (
                                                      <VisibilityOff />
                                                    ) : (
                                                      <Visibility />
                                                    )}
                                                  </IconButton>
                                                </InputAdornment>
                                              }
                                              label="Password"
                                            />
                                          </Index.Box>
                                          {touched.confirmPassword &&
                                            errors.confirmPassword && (
                                              <Index.FormHelperText error>
                                                {errors.confirmPassword}
                                              </Index.FormHelperText>
                                            )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              display="grid"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                              className="login-grid-inner"
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column"
                              >
                                <Index.Box className="signin-btn-main">
                                {isLoading ? <><Index.Box className="comman-loader-button"><CircularProgress /></Index.Box></>:
                                  <Index.PrimaryButton
                                    btnLabel="Register"
                                    type="submit"
                                    className="comman-primary-btn"
                                  />}
                                </Index.Box>
                               
                               
                                <Index.Box className="account-link-details">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="already-account-title"
                                  >
                                    Already have an account?
                                    <Index.Link
                                      to="/user/login"
                                      className="redirect-account-link"
                                    >
                                      Sign in
                                    </Index.Link>
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column"
                              >
                                {/* <Index.Box className="auth-footer-component">
                                    <Index.AuthFooter/>
                                </Index.Box> */}
                              </Index.Box>
                            </Index.Box>
                          </form>
                        )}
                      </Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
