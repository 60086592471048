import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";

import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DataService } from "../../../../config/DataService";
import { API } from "../../../../config/Api";
import { toast } from "react-toastify";
import { getAdminData, getAdminToken, getAdminType } from "../../../../redux/features/slices/Admin/AdminSlice";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [settingData, setSettingData] = useState({});
  const token = useSelector((store) => store.admin.token);
  console.log(token,"admin token");

  const getSettingData = async() => {
    await DataService.get(API.Admin.GET_GENERAL_SETTING, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {
        setSettingData(response?.data?.data?.[0]);
      })
      .catch((error) => {
        // setIsFetching(false);
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });
  }
  useEffect(() => {
    getSettingData();
  },[])

  let initialValues = {
    ReferralToken: settingData?.ReferralToken,
    SignupToken: settingData?.SignupToken,
    SignupProfileCompletionToken : settingData?.SignupProfileCompletionToken,
    referralProfileCompletionToken : settingData?.referralProfileCompletionToken,
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    ReferralToken: Yup.string().required("Refferral token is required"),
    SignupToken: Yup.string().required("Signup token is required"),
    SignupProfileCompletionToken: Yup.string().required("Signup profile completion token is required"),
    referralProfileCompletionToken: Yup.string().required("Referral profile completion token is required"),
  });

  const handleFormSubmit = async (values) => {
    // console.log(values, "setting data api");

    await DataService.post(API.Admin.ADD_EDIT_GENERAL_SETTING, values, {
      headers: {
        auth: token,
      },
    })
      .then((response) => {

        if(response?.data?.status == 200)
        {
          // toast.success(response?.data?.message);
          toast.success("General settings updated successfully");
          getSettingData();
        }
        // setIsFetching(false);
        // toast("User Deleted");
        // fetchData();
      })
      .catch((error) => {
        // setIsFetching(false);
        if (error.response.data.message === "Token not authorized") {
          dispatch(getAdminToken(""));
          dispatch(getAdminType(""));
          dispatch(getAdminData({}));
          navigate("/")
        }
      });

  };

  return (
    <Index.Box className="dashboard-containt-main">
      <Index.Box className="dashboard-main">
        <Index.Box className="dashboard-left-main">
          <Index.Sidebar />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <Index.Header />
          <Index.Typography
            className="admin-page-title setting-title"
            component="h4"
            variant="h4"
          >
            General Settings
          </Index.Typography>
          <Index.Box className="tabpanel-main">
            <Index.Box className="add-user-data-main">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Formik
                  enableReinitialize
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Index.Box
                        display="grid"
                        className="display-row"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Refferral Token
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                type="number"
                                className="form-control"
                                name="ReferralToken"
                                onBlur={handleBlur}
                                value={values?.ReferralToken}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  if (e.target.value.toString().length <= 5) {
                                    setFieldValue("ReferralToken",e.target.value);
                                  }
                                }}
                                helperText={touched.ReferralToken && errors.ReferralToken}
                                error={Boolean(errors.ReferralToken && touched.ReferralToken)}
                              />
                            </Index.Box>
                            {/* <p className="error-text">{error.name}</p> */}
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Signup Token
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                // value={email}
                                type="number"
                                name="SignupToken"

                                onBlur={handleBlur}
                                value={values?.SignupToken}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  if (e.target.value.toString().length <= 5) {
                                    setFieldValue("SignupToken",e.target.value);
                                  }
                                }}
                                helperText={touched.SignupToken && errors.SignupToken}
                                error={Boolean(errors.SignupToken && touched.SignupToken)}
                                // onChange={onHandleemailChange}
                              />
                            </Index.Box>
                            {/* <p className="error-text">{error.email}</p> */}
                          </Index.Box>
                        </Index.Box>
                        
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                            Signup Profile Completion Token
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                // value={email}
                                type="number"
                                name="SignupProfileCompletionToken"

                                onBlur={handleBlur}
                                value={values?.SignupProfileCompletionToken}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  if (e.target.value.toString().length <= 5) {
                                    setFieldValue("SignupProfileCompletionToken",e.target.value);
                                  }
                                }}
                                helperText={touched.SignupProfileCompletionToken && errors.SignupProfileCompletionToken}
                                error={Boolean(errors.SignupProfileCompletionToken && touched.SignupProfileCompletionToken)}
                                // onChange={onHandleemailChange}
                              />
                            </Index.Box>
                            {/* <p className="error-text">{error.email}</p> */}
                          </Index.Box>
                        </Index.Box>
                        

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                            Referral Profile Completion Token
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                // value={email}
                                type="number"
                                name="referralProfileCompletionToken"

                                onBlur={handleBlur}
                                value={values?.referralProfileCompletionToken}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  if (e.target.value.toString().length <= 5) {
                                    setFieldValue("referralProfileCompletionToken",e.target.value);
                                  }
                                }}
                                helperText={touched.referralProfileCompletionToken && errors.referralProfileCompletionToken}
                                error={Boolean(errors.referralProfileCompletionToken && touched.referralProfileCompletionToken)}
                                // onChange={onHandleemailChange}
                              />
                            </Index.Box>
                            {/* <p className="error-text">{error.email}</p> */}
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-btn-flex comman-btn-main">
                            {/* <Index.Box className="discard-btn-main border-btn-main">
                              <Index.Button
                                className="discard-user-btn border-btn"
                              >
                                Discard
                              </Index.Button>
                            </Index.Box> */}
                            <Index.Box className="save-btn-main ">
                              <Index.Button
                                className="save-user-btn primary-btn"
                                // onClick={onChange}
                                type="submit"
                              >
                                <img
                                  src={Index.Svg.save}
                                  alt="save"
                                  className="user-save-icon"
                                ></img>
                                Save
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </form>
                  )}
                </Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default Setting;
