import React, { useState } from "react";
import Index from "../../Index";

import * as Yup from "yup";
import { Formik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { loginUserData } from "../../../redux/features/slices/User/UserService";
import {
  getUserData,
  getUserToken,
} from "../../../redux/features/slices/Admin/AdminSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ffffff",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#032058",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 13,
    height: 13,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#032058",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading,setIsLoading]=useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let initialValues = {
    email: "",
    password: "",
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email is not valid")
      .matches(
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
        "Email is not valid"
      )
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // Login
  const handleFormSubmit = async (values) => {
    // try {
      setIsLoading(true)
    dispatch(loginUserData(values)).then((response) => {
      if(response.payload.status == 200){
        setIsLoading(false)
        console.log(response.payload,"inside login page");
        if (response?.payload) {
          dispatch(getUserToken(response?.payload?.data?.token));
          dispatch(getUserData(response?.payload?.data));
          navigate("/user/dashboard");
        } 
      }
     
    }).catch((error)=>{
      setIsLoading(false)
    })
  };
  return (
    <>
      <Index.Box className="login-main-content login-user-main">
        <Index.Box className="grid-row login-row">
          <Index.Box sx={{ width: 1 }} className="grid-main login-main">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
              className="login-grid"
              sx={{ margin: 0, alignItems: "center" }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-bg-content">
                  <Index.Box className="login-logo-details">
                      <Index.Link to="/">  <img src={Index.Svg.logo} className="logo-login"></img></Index.Link>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column login-column"
              >
                <Index.Box className="login-form-details">
                  <Index.Box className="login-pd-main">
                    <Index.Box className="login-max-content">
                      <Index.Box className="login-details-title">
                        <Index.Typography
                          className="login-title"
                          component="h5"
                          variant="h5"
                        >
                          Login
                        </Index.Typography>
                        <Index.Typography
                          className="login-desc"
                          component="p"
                          variant="p"
                        >
                          Enter your email and password
                        </Index.Typography>
                      </Index.Box>
                      <Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Index.Box
                              display="grid"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                              className="login-grid-inner"
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column mb-20px"
                              >
                                <Index.Box className="input-box custom-form-details login-input">
                                  <Index.Box className="form-group custom-group">
                                    <Index.FormHelperText className="form-label">
                                      Enter Email
                                    </Index.FormHelperText>
                                    <Index.TextField
                                      fullWidth
                                      id="fullWidth"
                                      placeholder="Email"
                                      className="form-control custom-control"
                                      name="email"
                                      onBlur={handleBlur}
                                      value={values.email}
                                      onChange={handleChange}
                                      helperText={touched.email && errors.email}
                                      error={Boolean(
                                        errors.email && touched.email
                                      )}
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column mb-20px"
                              >
                                <Index.Box className="input-box custom-form-details login-input">
                                  <Index.Box className="form-group custom-group">
                                    <Index.FormHelperText
                                      className="form-label"
                                      fullWidth
                                      id="fullWidth"
                                    >
                                      Enter Password
                                    </Index.FormHelperText>
                                    <Index.Box className="input-details-main">
                                      <Index.Box className="form-group pass_group_main">
                                        <OutlinedInput
                                          className="form-control custom_form_control"
                                          id="outlined-adornment-password"
                                          name="password"
                                          placeholder="Password"
                                          onBlur={handleBlur}
                                          value={values.password}
                                          onChange={handleChange}
                                          helperText={
                                            touched.password && errors.password
                                          }
                                          error={Boolean(
                                            errors.password && touched.password
                                          )}
                                          type={
                                            showPassword ? "text" : "password"
                                          }
                                          endAdornment={
                                            <InputAdornment
                                              position="end"
                                              className="pass_position"
                                            >
                                              <IconButton
                                                className="icon_end_icon"
                                                aria-label="toggle password visibility"
                                                onClick={
                                                  handleClickShowPassword
                                                }
                                                onMouseDown={
                                                  handleMouseDownPassword
                                                }
                                                edge="end"
                                              >
                                                {showPassword ? (
                                                  <VisibilityOff />
                                                ) : (
                                                  <Visibility />
                                                )}
                                              </IconButton>
                                            </InputAdornment>
                                          }
                                          label="Password"
                                        />
                                        {touched.password &&
                                          errors.password && (
                                            <Index.FormHelperText error>
                                              {errors.password}
                                            </Index.FormHelperText>
                                          )}
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column"
                              >
                                <Index.Box className="flex-check-login comman-flex-justify">
                                  <Index.Box className="check-remeber-main">
                                    <Index.Box className="checkbox-main">
                                      <BpCheckbox className="custom-check" />{" "}
                                      <span>Remember me</span>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box className="flex-forgot-password comman-flex-justify">
                                  <Index.Box className="check-remeber-main">
                                    <Index.Box className="checkbox-main">
                                      {/* <BpCheckbox className="custom-check" />{" "}
                                      <span>Remember me</span> */}
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box className="create-account-text">
                                    <Index.Link
                                      className="signup-redirect"
                                      to="/user/forgot-password"
                                    >
                                      Forgot Password ?
                                    </Index.Link>
                                  </Index.Box>
                                </Index.Box>
                                </Index.Box>
                   
                                
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column"
                              >
                                <Index.Box className="signin-btn-main">
                                {isLoading ? <><Index.Box className="comman-loader-button"><CircularProgress /></Index.Box></>:
                                  <Index.PrimaryButton btnLabel="Sign in"   type="submit" className="comman-primary-btn"/>}
                                  {/* <Index.Button
                                    variant="contained"
                                    className="comman-primary-btn"
                                   
                                  >
                                    {" "}
                                    Sign in
                                  </Index.Button> */}
                                </Index.Box>
                          
                              </Index.Box>
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 12",
                                  md: "span 12",
                                  lg: "span 12",
                                }}
                                className="grid-column login-column"
                              >
                                <Index.Box className="auth-footer-component">
                                    <Index.AuthFooter/>
                                </Index.Box>
                                <Index.Box className="create-account-text create-bottom-account">
                                  Don't have an account ?
                                    <Index.Link
                                      className="signup-redirect"
                                      to="/user/signup"
                                    >
                                      Sign up
                                    </Index.Link>
                                  </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </form>
                        )}
                      </Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
