import React from "react";
import Index from "../../Index";

import { DataService } from "../../../config/DataService";
import { API } from "../../../config/Api";
import { toast } from "react-toastify";

import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector } from "react-redux";

export default function Footer() {
  const userToken = useSelector((store) => store.admin.userToken);

  let initialValues = {
    name: "",
    email: "",
    message: "",
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Email is not valid")
      .matches(
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
        "Email is not valid"
      )
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  return (
    <>
      <Index.Box className="footer-main-content">
        <Index.Box className="pd-footer-details">
          <Index.Box className="container">
            <Index.Box className="contact-us-details">
              <Index.Typography
                className="contactus-title-shadow"
                variant="h4"
                component="h4"
              >
                Contact us
              </Index.Typography>
              <Index.Typography
                className="contactus-title"
                variant="h4"
                component="h4"
              >
                Contact us
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="footer-pos-main">
            <Index.Box className="container">
              <Index.Box className="grid-row footer-row">
                <Index.Box sx={{ width: 1 }} className="grid-main footer-main">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    className="footer-grid"
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column footer-column"
                    >
                      <Index.Box className="footer-left-content">
                        <Index.Box className="footer-bg-rounded">
                          <Index.Box className="rounde-footer-inner-details">
                            <Index.Box className="footer-logo">
                              <Index.Link className="redirect-footer-logo">
                                <img
                                  alt="footer-logo"
                                  className="footer-logo"
                                  src={Index.Svg.logo}
                                ></img>
                              </Index.Link>
                            </Index.Box>
                            <Index.Box className="address-footer-main">
                              <Index.List className="footer-add-ul">
                                <Index.ListItem className="footer-add-li">
                                  <Index.Box className="add-icon-main-footer">
                                    <img
                                      src={Index.Svg.call}
                                      className="footer-add-icons"
                                    ></img>
                                  </Index.Box>
                                  <Index.Typography
                                    className="add-footer-title"
                                    component="p"
                                    variant="p"
                                  >
                                    {/* 309-798-4145 */}
                                    Coming soon...
                                  </Index.Typography>
                                </Index.ListItem>
                                <Index.ListItem className="footer-add-li">
                                  <Index.Box className="add-icon-main-footer">
                                    <img
                                      src={Index.Svg.pin}
                                      className="footer-add-icons"
                                    ></img>
                                  </Index.Box>
                                  <Index.Typography
                                    className="add-footer-title"
                                    component="p"
                                    variant="p"
                                  >
                                    {/* 1377 Simpson Street Edgington, IL 61284 */}
                                    Coming soon...
                                  </Index.Typography>
                                </Index.ListItem>
                              </Index.List>
                            </Index.Box>
                            <Index.Box className="footer-social-icon-main">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="visit-social-title"
                              >
                                Visit Us
                              </Index.Typography>
                              <Index.List className="footer-social-icon-ul">
                                <Index.ListItem className="footer-social-icon-li">
                                  <Index.Link className="social-redirect-link" to="https://www.facebook.com/profile.php?id=100093036447824" target="_blank">
                                    <img
                                      src={Index.Svg.facebook}
                                      className="social-icon-img"
                                    ></img>
                                  </Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className="footer-social-icon-li">
                                  <Index.Link className="social-redirect-link" to="https://www.instagram.com/fitrizerhealth/" target="_blank">
                                    <img
                                      src={Index.Svg.instagram}
                                      className="social-icon-img"
                                    ></img>
                                  </Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className="footer-social-icon-li">
                                  <Index.Link className="social-redirect-link" to="https://t.me/Fitrizer" target="_blank">
                                    <img
                                      src={Index.Svg.telegram}
                                      className="social-icon-img"
                                    ></img>
                                  </Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className="footer-social-icon-li">
                                  <Index.Link className="social-redirect-link" to="https://twitter.com/FitrizerHealth" target="_blank">
                                    <img
                                      src={Index.Svg.twitter}
                                      className="social-icon-img"
                                    ></img>
                                  </Index.Link>
                                </Index.ListItem>
                              </Index.List>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="svg-rounded-border">
                            <img
                              src={Index.Svg.footerline}
                              className="footer-line-bg"
                            ></img>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column footer-column"
                    >
                      <Index.Box className="footer-right-content">
                        <Index.Box className="footer-details-main">
                          {/* <Index.Typography
                            className="footer-title"
                            variant="h5"
                            component="h5"
                          >
                            Lorem Ipsum dolor..
                          </Index.Typography> */}
                          {/* <Index.Typography className="footer-desc" variant='h5' component='h5'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</Index.Typography> */}
                        </Index.Box>
                        <Index.Box className="footer-form-details">
                          <Formik
                            enableReinitialize
                            // onSubmit={handleFormSubmit(resetForm)}
                            onSubmit={async (values, { resetForm }) => {
                              DataService.defaults.headers.common["auth"] =
                                userToken;
                              await DataService.post(
                                API.User.CONTACT_US,
                                values,
                                {
                                  headers: {
                                    auth: userToken,
                                  },
                                }
                              )
                                .then((res) => {
                                  if (res.data.status == 201) {
                                    toast.success("Request sent successfully");
                                    resetForm({ values: "" })
                                  }
                                })
                                .catch((error) => {
                                  toast.error(error.response.data.message);
                                });
                            }}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <Index.Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                                  className="footer-grid"
                                >
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 12",
                                      md: "span 6",
                                      lg: "span 6",
                                    }}
                                    className="grid-column footer-column"
                                  >
                                    <Index.Box className="input-box custom-form-details">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Name
                                        </Index.FormHelperText>
                                        <Index.TextField
                                          fullWidth
                                          id="fullWidth"
                                          className="form-control custom-control"
                                          name="name"
                                          value={values.name}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          helperText={
                                            touched.name && errors.name
                                          }
                                          error={Boolean(
                                            errors.name && touched.name
                                          )}
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 12",
                                      md: "span 6",
                                      lg: "span 6",
                                    }}
                                    className="grid-column footer-column"
                                  >
                                    <Index.Box className="input-box custom-form-details">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Email
                                        </Index.FormHelperText>
                                        <Index.TextField
                                          fullWidth
                                          id="fullWidth"
                                          className="form-control custom-control"
                                          name="email"
                                          value={values.email}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          helperText={
                                            touched.email && errors.email
                                          }
                                          error={Boolean(
                                            errors.email && touched.email
                                          )}
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 12",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column footer-column"
                                  >
                                    <Index.Box className="input-box custom-form-details">
                                      <Index.Box className="form-group custom-group">
                                        <Index.FormHelperText className="form-label">
                                          Write Message
                                        </Index.FormHelperText>
                                        <Index.TextareaAutosize
                                          as="textarea"
                                          rows={4}
                                          onBlur={handleBlur}
                                          name="message"
                                          value={values.message}
                                          onChange={handleChange}
                                          helperText={
                                            touched.email && errors.email
                                          }
                                          error={Boolean(
                                            errors.email && touched.email
                                          )}
                                          className="form-control text-custom-control"
                                        ></Index.TextareaAutosize>
                                      </Index.Box>
                                      {touched.message && errors.message && (
                                        <Index.FormHelperText error>
                                          {errors.message}
                                        </Index.FormHelperText>
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 12",
                                      md: "span 12",
                                      lg: "span 12",
                                    }}
                                    className="grid-column footer-column"
                                  >
                                    <Index.Box className="send-footer-btn">
                                      {/* <Index.PrimaryButton btnLabel="Send" type="submit" className="comman-primary-btn" /> */}
                                      <Index.PrimaryButton type="submit" className="comman-primary-btn" btnLabel="Send" />
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </form>
                            )}
                          </Formik>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {/* <Index.Box className="footer-top-minus">
              <Index.Box className='grid-row footer-row'>
                <Index.Box sx={{ width: 1 }} className="grid-main footer-main">
                  <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 0, lg: 0 }} className="footer-grid" sx={{ margin: 0 }}>
                    <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 6" }} className="grid-column footer-column">
                      <Index.Box className="footer-left-content">
                        <Index.Box className="footer-bg-rounded">
                          <Index.Box className="rounde-footer-inner-details">
                            <Index.Box className="footer-logo">
                              <Index.Link className="redirect-footer-logo">
                                <img alt="footer-logo" className="footer-logo" src={Index.Svg.logo}></img>
                              </Index.Link>
                            </Index.Box>
                            <Index.Box className="address-footer-main">
                              <Index.List className="footer-add-ul">
                                <Index.ListItem className="footer-add-li">
                                  <Index.Box className="add-icon-main-footer">
                                    <img src={Index.Svg.call} className='footer-add-icons'></img>
                                  </Index.Box>
                                  <Index.Typography className="add-footer-title" component='p' variant='p'>309-798-4145</Index.Typography>
                                </Index.ListItem>
                                <Index.ListItem className="footer-add-li">
                                  <Index.Box className="add-icon-main-footer">
                                    <img src={Index.Svg.pin} className='footer-add-icons'></img>
                                  </Index.Box>
                                  <Index.Typography className="add-footer-title" component='p' variant='p'>1377 Simpson Street Edgington, IL 61284</Index.Typography>
                                </Index.ListItem>
                              </Index.List>
                            </Index.Box>
                            <Index.Box className="footer-social-icon-main">
                              <Index.Typography component='p' variant='p' className="visit-social-title">Visit Us</Index.Typography>
                              <Index.List className="footer-social-icon-ul">
                                <Index.ListItem className="footer-social-icon-li">
                                  <Index.Link className="social-redirect-link">
                                    <img src={Index.Svg.facebook} className='social-icon-img'></img>
                                  </Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className="footer-social-icon-li">
                                  <Index.Link className="social-redirect-link">
                                    <img src={Index.Svg.instagram} className='social-icon-img'></img>
                                  </Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className="footer-social-icon-li">
                                  <Index.Link className="social-redirect-link">
                                    <img src={Index.Svg.linkedin} className='social-icon-img'></img>
                                  </Index.Link>
                                </Index.ListItem>
                                <Index.ListItem className="footer-social-icon-li">
                                  <Index.Link className="social-redirect-link">
                                    <img src={Index.Svg.twitter} className='social-icon-img'></img>
                                  </Index.Link>
                                </Index.ListItem>
                              </Index.List>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 8", lg: "span 6" }} className="grid-column footer-column">

                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="bottom-bg-footer">
        <Index.Box className="container">
          <Index.Box className="pd-footer-copy">
            <Index.Typography
              component="p"
              variant="p"
              className="copyright-title"
            >
              All Rights Reserved ©2023 Fitrizer
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
